import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import _ from 'lodash';

import { GET_LINKS } from '../gql';

class LinkChooser extends PureComponent {
  constructor() {
    super();
    this.state = {
      searchInput: '',
      filteredLinks: []
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event, links) {
    const value = event.target.value; 
    const filteredLinks = links.filter(link => _.startsWith(link.contentTitle.toLowerCase(), value.toLowerCase()));

    this.setState({
      searchInput: value,
      filteredLinks
    })
  }

  renderListItems(links, onClickArticleLink) {
    return links.length > 0 ? (
      links.sort((a, b) => {
        if (a.contentTitle < b.contentTitle) return -1;
        if (a.contentTitle > b.contentTitle) return 1;
        return 0;
      })
        .map(link => (
          <li className='overlay__list-item' key={link.contentID}>
            <button
              className='button button--transparent left-align no-margin'
              onClick={() =>
                onClickArticleLink(
                  link.contentID,
                  link.contentTitle,
                  link.contentType
                )
              }
            >
              {link.contentTitle}
              <small>
                <em className="u-capitalize u-margin--left-xsmall">
                  ({_.lowerCase(link.contentTypeNew)})
                </em>
              </small>
            </button>
          </li>
        ))
    ) : (
      <p className="center-align">
        <em>No links found</em>
      </p>
    )
  }

  render() {
    const {
      searchInput,
      filteredLinks
    } = this.state;
    const {
      languageCode,
      app,
      onClickArticleLink
    } = this.props;

    return (
      <Fragment>
        <Query
          notifyOnNetworkStatusChange
          query={GET_LINKS}
          variables={{
            languageCode,
            app,
            limit: 1000,
            cursor: '1'
          }}
        >
          {({ data, loading, error, networkStatus }) => {
            if (loading && networkStatus !== 3)
              return <p className='full-width center-align'>Loading articles...</p>;        
            if (error)
              return <p className='full-width center-align'>{error.message}</p>;
            const { links = { edges: [] }} = data;
            return (
              <div className='overlay__dialog-wrapper overlay__dialog-wrapper--bottom-medium'>
                <p className='center-align'>Choose a link from the list below.</p>
                <ul className='overlay__list'>
                  {searchInput.length === 0 ? (
                    this.renderListItems(links.edges, onClickArticleLink)
                  ) : (
                    this.renderListItems(filteredLinks, onClickArticleLink)
                  )}
                </ul>
                <div className="form form--no-maxwidth u-margin--top-small">
                  <div className="form__group">
                    <div className="form__flex-col">
                      <label htmlFor="search" className="form__label form__label--icon-before">
                        <input
                          type="text"
                          name="search"
                          placeholder="Search"
                          onChange={event => this.onInputChange(event, links.edges)}
                          className="form__input-field form__input-field--small"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

LinkChooser.propTypes = {
  app: PropTypes.string,
  languageCode: PropTypes.string,
  onClickArticleLink: PropTypes.func
};

export default LinkChooser;
