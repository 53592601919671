import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    const { children } = this.props;
    const {
      error,
      errorInfo
    } = this.state;

    if (errorInfo) {
      return (
        <div className="full-width center-align">
          <p>
            <b>An unexcepted error occurred.</b>
          </p>
          {error &&
            <p>{error.toString()}</p>
          }
          <p>{errorInfo.componentStack}</p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
}

export default ErrorBoundary;