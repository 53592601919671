import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import _ from 'lodash';

import PagePagination from '../components/PagePagination';
import Throbber from '../components/Throbber';

import {
  GET_USERS,
  DELETE_USER,
  DELETE_BABY,
  EXPORT_JOURNAL_TO_PDF
} from './../gql';

import babyIcon from './../../images/icons/baby.svg';

class Users extends Component {
  constructor() {
    super();
    this.state = {
      searchInput: '',
      dateSorting: 'LAST_ACTIVE_ASCENDING',
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    const value = event.target.value;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.setState({ searchInput: value }), 500);
  }

  deleteUser(id, username, deleteUser) {
    if (confirm(`Are you sure you want to delete user: ${username} ?`)) {
      deleteUser({
        variables: {
          id
        }
      });
    }
  }

  deleteBaby(id, baby, username, deleteBaby) {
    if (confirm(`Are you sure you want to delete baby ${baby} who belongs to user: ${username} ?`)) {
      deleteBaby({
        variables: {
          id
        }
      });
    }
  }

  downloadDiaryFile(url, baby) {
    const babyName = _.kebabCase(baby);

    fetch(url)
      .then(response => response.blob())
      .then(data => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = `${babyName}-diary-export.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  formatActivityDate(date) {
    const dateObject = new Date(date);
    const formatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const formattedDate = new Date(
      Date.UTC(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate()
      )
    );
    return formattedDate.toLocaleDateString('da-DK', formatOptions);
  }

  sortDirection(a, b, direction) {
    const aTime = new Date(a).getTime();
    const bTime = new Date(b).getTime();

    if (direction === 'LAST_ACTIVE_ASCENDING') {
      return aTime - bTime;
    }
    return bTime - aTime;
  }

  toggleSortDirection() {
    const { dateSorting } = this.state;
    this.setState({
      dateSorting: dateSorting === 'LAST_ACTIVE_ASCENDING'
        ? 'LAST_ACTIVE_DESCENDING'
        : 'LAST_ACTIVE_ASCENDING'
    });
  }

  render() {
    const { app, languageCode } = this.props;
    const { searchInput, dateSorting } = this.state;

    return (
      <div>
        <div className="deck__view-wrapper">
          <div className="form form--no-maxwidth">
            <div className="form__group">
              <div className="form__flex-col">
                <label htmlFor="search" className="form__label form__label--icon-before">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={this.onInputChange}
                    className="form__input-field form__input-field--small"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="list">
          <div>
            <button className="button button--smallest no-margin" onClick={() => this.toggleSortDirection()}>
              {`Sort last activity: ${dateSorting === 'LAST_ACTIVE_ASCENDING' ? 'Ascending' : 'Descending'}`}
            </button>
          </div>
          <ul className="list__group">
            <li className="list__item">
              <div className="list__block list__block--highlight">
                <div className="list__details">
                  <p className="list__text list__text--username">Email</p>
                  <p className="list__text">Last activity</p>
                </div>
                <div className="list__actions" />
              </div>
            </li>
            <Query
              query={GET_USERS}
              variables={{
                emailSearch: searchInput,
                orderBy: dateSorting,
                limit: 10
              }}>
              {({ data, loading, error, fetchMore }) => {
                if (loading)
                  return <p className="full-width center-align">Loading...</p>;
                if (error)
                  return <p className="full-width center-align">{error.message}</p>;
                const { users } = data;

                return [
                  users.edges.length > 0 ? (
                    <div key="users">
                      {users.edges
                        .sort((a, b) => this.sortDirection(a.lastActivity, b.lastActivity, dateSorting))
                        .map(user =>
                          <li className="list__item" key={user.id}>
                            <Mutation
                              mutation={DELETE_USER}
                              refetchQueries={[{
                                query: GET_USERS,
                                variables: {
                                  emailSearch: searchInput,
                                  orderBy: dateSorting,
                                  limit: 10
                                }
                              }]}
                            >
                              {(deleteUser, { loading, error }) => (
                                <div className="list__block list__block--highlight">
                                  <div className="list__details">
                                    <p className="list__text list__text--username">
                                      {user.email}
                                    </p>
                                    <p className="list__text">
                                      {this.formatActivityDate(user.lastActivity)}
                                    </p>
                                  </div>
                                  <div className="list__actions">
                                    <div className="list__inline">
                                      {error &&
                                        <p className="full-width center-align">{error.message}</p>
                                      }
                                    </div>
                                    <button
                                      className="button button--transparent button--smallest button--delete"
                                      onClick={() => this.deleteUser(user.id, user.email, deleteUser)}
                                    >
                                      Delete user
                                    </button>
                                  </div>
                                  <Throbber 
                                    hidden={!loading}
                                    loadingText="Deleting user..."
                                  />
                                </div>
                              )}
                            </Mutation>
                            {user.babies.length > 0 &&
                              user.babies.map(baby =>
                                <Mutation
                                  key={baby.id}
                                  mutation={DELETE_BABY}
                                  refetchQueries={[{
                                    query: GET_USERS,
                                    variables: {
                                      emailSearch: searchInput,
                                      orderBy: dateSorting,
                                      limit: 10
                                    }
                                  }]}
                                >
                                  {(deleteBaby, { loading, error }) => (
                                    <div className="list__block list__block--highlight">
                                      <div className="list__details">
                                        <img src={babyIcon} alt="Baby" className="list__details-icon" />
                                        <p className="list__text">
                                          {baby.name}
                                        </p>
                                        {error &&
                                          <p className="full-width center-align no-margin">{error.message}</p>
                                        }
                                      </div>
                                      <Mutation
                                        update={(cache, { data }) => {
                                          this.downloadDiaryFile(data.exportJournalToPDF, baby.name)                                       
                                        }}
                                        mutation={EXPORT_JOURNAL_TO_PDF}>
                                        {(exportJournalToPDF, { data, loading, error }) => (
                                          <div className="list__actions list__actions--indent">
                                            <div className="list__inline">
                                              {data === null &&
                                                <p className="full-width center-align no-margin">
                                                  No entries found in {baby.name}&#39;s journal.
                                                </p>
                                              }
                                              {error &&
                                                <p className="full-width center-align no-margin">
                                                  {error.message}
                                                </p>
                                              }
                                            </div>
                                            <button
                                              className="button button--transparent button--smallest button--info"
                                              onClick={() => exportJournalToPDF({
                                                variables: {
                                                  id: baby.journal.id,
                                                  languageCode,
                                                  app,
                                                }
                                              })}
                                            >
                                              Export diary
                                            </button>
                                            <Throbber 
                                              hidden={!loading}
                                              loadingText="Exporting diary..."
                                            />
                                            <button
                                              className="button button--transparent button--smallest button--delete"
                                              onClick={() => this.deleteBaby(baby.id, baby.name, user.email, deleteBaby)}
                                            >
                                              Delete baby
                                            </button>
                                          </div>
                                        )}
                                      </Mutation>
                                      <Throbber 
                                        hidden={!loading}
                                        loadingText="Deleting baby..."
                                      />
                                    </div>
                                  )}
                                </Mutation>
                              )
                            }
                          </li>
                        )}
                    </div>
                  ) : (
                    <li className="list__item" key="no-users">
                      <p className="full-width center-align">No users found.</p>
                    </li>
                  ),
                  <PagePagination
                    key="pagination"
                    pageInfo={users.pageInfo}
                    onPageClick={(endCursor) =>
                      fetchMore({
                        query: GET_USERS,
                        variables: {
                          emailSearch: searchInput,
                          orderBy: dateSorting,
                          cursor: endCursor,
                          limit: 10
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          const previous = previousResult.users
                          const newUsers = fetchMoreResult.users.edges

                          const newCursor = fetchMoreResult.users.pageInfo.endCursor

                          // this is the updated data, and it must keep the same structure as the original data
                          return {
                            cursor: newCursor,
                            users: {
                              edges: [...newUsers],
                              __typename: previous.__typename,
                              pageInfo: {
                                endCursor: newCursor,
                                ...fetchMoreResult.users.pageInfo,
                                __typename: previous.pageInfo.__typename
                              }
                            },
                          }
                        }
                      })
                    } 
                  />
                ]
              }}
            </Query>
          </ul>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  app: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
}

export default Users;