import gql from 'graphql-tag';

const LOGIN = gql`
  mutation Signin(
    $login: String!, 
    $password: String!
    $checkAdmin: Boolean
  ) {
    signIn(
      login: $login, 
      password: $password,
      checkAdmin: $checkAdmin
    ) {
      token
    }
  }
`;

// this query is getting local data
// and not querying the server
const GET_AUTH_TOKEN = gql`
  query getLoggedIn {
    authToken @client
  }
`;

export { LOGIN, GET_AUTH_TOKEN };
