import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import { LOGIN } from './../gql';

class Login extends PureComponent {
  constructor(){
    super();
    this.state = {
      email: null,
      password: null,
    };
  }

  handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value
    this.setState({
      [id]: value,
    });
  };

  setAuthToken = (
    cache,
    {
      data: {
        signIn: { token },
      },
    },
  ) => {
    cache.writeData({
      data: { authToken: token },
    });
    const { onLoggedIn } = this.props;
    localStorage.setItem('auth_token', token);
    onLoggedIn && onLoggedIn();
  };

  render() {
    const { password, email } = this.state;

    return (
      <Mutation
        variables={{ password, login: email, checkAdmin: true }}
        mutation={LOGIN}
        update={this.setAuthToken}>
        {(signIn, { loading, error }) => {
          return (
            <form
              className="form"
              onSubmit={e => {
                e.preventDefault();
                signIn();
              }}
              noValidate>
              <h1 className="form__title">
                CMS LOGIN
              </h1>
              <label className="form__label" htmlFor="email">
                <span className="sr-only">
                  Email
                </span>
                <input
                  className="form__input-field"
                  type="email"
                  id="email"
                  placeholder="Email"
                  onChange={event => this.handleChange(event)}
                  required
                />
              </label>
              <label className="form__label" htmlFor="password">
                <span className="sr-only">
                  Password
                </span>
                <input
                  className="form__input-field"
                  type="password"
                  id="password"
                  placeholder="Password"
                  onChange={event => this.handleChange(event)}
                  required
                />
              </label>
              <input
                className="form__submit button full-width"
                type="submit"
                value="Login"
                disabled={loading}
              />
              {loading &&
                <div className="u-loader__rotate u-loader__rotate--blue">
                  <span className="sr-only">
                    Loading
                  </span>
                </div>
              }
              {error &&
                <p className="center-align">
                  {error.message}
                </p>
              }
            </form>
          );
        }}
      </Mutation>
    );
  }
}

Login.propTypes = {
  onLoggedIn: PropTypes.any
}

export default Login;