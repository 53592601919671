import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

class VisibilityToggle extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentSelection: null
    }
  }

  componentDidMount() {
    const { options } = this.props;
    for (let i = 0; i < options.length; i++) {
      if (options[i].checked) {
        this.setState({
          currentSelection: options[i].id
        });
      }
    }
  }

  onVisibilityChange(event) {
    const { onChange } = this.props;
    const value = event.target.value;

    this.setState({
      currentSelection: value
    });
    onChange(value);
  }

  render() {
    const { currentSelection } = this.state;
    const { options, parentId } = this.props;

    return (
      <form className="radio-group radio-group--eye" noValidate>
        {options.map(option =>
          <label
            className={cx('radio-group__label', {
              'radio-group__label--selected': option.id === currentSelection
            })}
            htmlFor={`${option.id}-${parentId}`}
            key={option.id}>
            <input
              className="radio-group__input"
              id={`${option.id}-${parentId}`}
              type="radio"
              name="radio-group"
              value={option.id}
              checked={option.id === currentSelection}
              onChange={event => this.onVisibilityChange(event)}
            />
            <span className="radio-group__eye-label">
              {_.lowerCase(option.id)}
            </span>
          </label>
        )}
      </form>
    );
  }
}

VisibilityToggle.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired
}

export default VisibilityToggle;