import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export class PagePagination extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(pageNumber) {
    const cursorString = pageNumber.toString();
    this.props.onPageClick(cursorString);
  }

  render() {
    const { pageInfo } = this.props;
    return (
      <div className="pagination">
        <DirectionButton
          variant="Prev"
          isDisabled={!pageInfo.hasPrevPage}
          goToPage={pageInfo.prevPage}
          onClick={this.onClick}
        />
        {pageInfo.prevPage &&
          <PaginationButton
            pageNumber={pageInfo.prevPage}
            isActive={false}
            onClick={this.onClick}
          />
        }
        <PaginationButton
          pageNumber={pageInfo.page}
          isActive={true}
        />
        {pageInfo.nextPage &&
          <PaginationButton
            pageNumber={pageInfo.nextPage}
            isActive={false}
            onClick={this.onClick}
          />
        }
        <DirectionButton
          variant="Next"
          isDisabled={!pageInfo.hasNextPage}
          goToPage={pageInfo.nextPage}
          onClick={this.onClick}
        />
      </div>
    );
  }
}

const DirectionButton = ({ variant, isDisabled, goToPage, onClick }) => {
  return (
    <button
      className={cx('pagination__button', {
        'pagination__button--disabled': isDisabled
      })}
      disabled={isDisabled}
      onClick={() => onClick(goToPage)}
    >
      {variant}
    </button>
  );
};

const PaginationButton = ({ pageNumber, isActive, onClick }) => {
  return (
    <button
      className={cx('pagination__button', {
        'pagination__button--active': isActive
      })}
      onClick={() => onClick(pageNumber)}
      disabled={isActive}
    >
      {pageNumber}
    </button>
  );
};

PaginationButton.propTypes = {
  pageNumber: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

DirectionButton.propTypes = {
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  goToPage: PropTypes.number,
  onClick: PropTypes.func
};

PagePagination.propTypes = {
  pageInfo: PropTypes.object,
  onPageClick: PropTypes.func
};

export default PagePagination;
