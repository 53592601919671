import gql from 'graphql-tag';

const GET_STATIC_CONTENT = gql`
  query StaticContent(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $limit: Int
  ) {
    staticContent(
      filter: {
        languageCode: $languageCode,
        app: $app,
        published: $published
      }
      pagination: {
        limit: $limit
      }
    ) {
      edges {
        id,
        title,
        content
        isPrivacyPolicy
        availability {
          published
        }
        meta {
          displayOrder
        }
      }
    }
  }
`;

const UPDATE_STATIC_CONTENT = gql`
  mutation UpdateStaticPage(
    $id: ID!
    $title: String
    $content: JSON
    $languageCode: String!
    $app: App!
    $published: Boolean
    $isPrivacyPolicy: Boolean
    $displayOrder: Int
  ) {
    updateStaticPage(
      id: $id,
      title: $title,
      content: $content,
      isPrivacyPolicy: $isPrivacyPolicy
      availability: {
        languageCode: $languageCode,
        app: $app,
        published: $published
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const CREATE_STATIC_CONTENT = gql`
  mutation CreateStaticPage(
    $title: String!
    $content: JSON
    $languageCode: String!
    $app: App!
    $published: Boolean
    $displayOrder: Int
  ) {
    createStaticPage(
      title: $title
      content: $content
      availability: {
        published: $published
        languageCode: $languageCode
        app: $app
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const DELETE_STATIC_CONTENT = gql`
  mutation DeleteStaticPage(
    $id: ID!
  ) {
    deleteStaticPage(
      id: $id
    )
  }
`;

export {
  GET_STATIC_CONTENT,
  UPDATE_STATIC_CONTENT,
  CREATE_STATIC_CONTENT,
  DELETE_STATIC_CONTENT
};