import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';

import { GET_IMAGES, SINGLE_UPLOAD, DELETE_IMAGE } from '../gql';

import Pagination from './Pagination';
import Throbber from './Throbber';

import closeIcon from '../../images/icons/close-white.svg';

class ImageChooser extends PureComponent {
  constructor() {
    super();
    this.state = {
      uploadButtonDisabled: true,
      image: null
    };
    this.paginationLimit = 10;
  }

  deleteImage = (id, deleteImage) => {
    if (confirm('Are you sure you want to delete this image?')) {
      deleteImage({
        variables: { id }
      });
    }
  };

  imageUploadHandle = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    this.setState({
      uploadButtonDisabled: !validity.valid,
      image: validity.valid ? file : null
    });
  };

  submitImage = (event, singleUpload) => {
    event.preventDefault();
    const { image } = this.state;
    singleUpload({
      variables: {
        image
      }
    });
  };

  render() {
    const { uploadButtonDisabled } = this.state;
    const { onClickImage } = this.props;

    return (
      <Fragment>
        <Query
          query={GET_IMAGES}
          variables={{
            limit: this.paginationLimit,
            cursor: '1'
          }}
        >
          {({ data, loading, error, fetchMore }) => {
            if (loading)
              return (
                <p className="full-width center-align">Loading images...</p>
              );
            if (error)
              return <p className="full-width center-align">{error.message}</p>;
            const { images } = data;

            return (
              <div className="overlay__image-wrapper">
                {images.edges.length > 0 ? (
                  images.edges.map(image => (
                    <div className="relative-block" key={image.id}>
                      <Mutation
                        mutation={DELETE_IMAGE}
                        refetchQueries={[{
                          query: GET_IMAGES,
                          variables: {
                            limit: this.paginationLimit,
                            cursor: '1'
                          }
                        }]}
                      >
                        {(deleteImage, { loading }) => [
                          <Throbber
                            key="throbber"
                            hidden={!loading}
                            loadingText="Deleting image..."
                          />,
                          <button
                            key="select-button"
                            className="overlay__select-image"
                            onClick={() => onClickImage(image.path, image.id)}
                          >
                            <img src={image.path} alt={image.name} />
                          </button>,
                          <button
                            key="delete-button"
                            title="Delete"
                            onClick={() =>
                              this.deleteImage(image.id, deleteImage)
                            }
                            className="button button--round-icon button--delete overlay__delete-image"
                          >
                            <span className="sr-only">Delete</span>
                            <img
                              src={closeIcon}
                              alt="Delete"
                              className="button__icon-small"
                            />
                          </button>
                        ]}
                      </Mutation>
                    </div>
                  ))
                ) : (
                  <p className="full-width center-align">No images found.</p>
                )}
                <div className="full-width">
                  <Pagination
                    disableLoadMore={!images.pageInfo.hasNextPage}
                    onLoadMore={() =>
                      fetchMore({
                        query: GET_IMAGES,
                        variables: {
                          limit: this.paginationLimit,
                          cursor: images.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          const previous = previousResult.images;
                          const newImages = fetchMoreResult.images.edges;

                          const newCursor =
                            fetchMoreResult.images.pageInfo.endCursor;

                          const hasNextPage =
                            fetchMoreResult.images.pageInfo.hasNextPage;

                          // this is the updated data, and it must keep the same structure as the original data
                          return {
                            cursor: newCursor,
                            images: {
                              edges: [...previous.edges, ...newImages],
                              __typename: previous.__typename,
                              pageInfo: {
                                endCursor: newCursor,
                                hasNextPage,
                                __typename: previous.pageInfo.__typename
                              }
                            }
                          };
                        }
                      })
                    }
                  />
                </div>
              </div>
            );
          }}
        </Query>
        <Mutation
          mutation={SINGLE_UPLOAD}
          refetchQueries={[{
            query: GET_IMAGES,
            variables: {
              limit: this.paginationLimit,
              cursor: '1'
            }
          }]}
        >
          {(singleUpload, { loading, error }) => (
            <form
              className="form center-align u-margin--top-medium"
              onSubmit={event => this.submitImage(event, singleUpload)}
            >
              <label>
                <input
                  name="image"
                  type="file"
                  accept=".png, .jpeg"
                  onChange={this.imageUploadHandle}
                />
              </label>
              <input
                type="submit"
                value="Upload image"
                disabled={uploadButtonDisabled || loading}
                className="form__submit button button--small"
              />
              {loading && (
                <div className="u-loader__rotate u-loader__rotate--blue">
                  <span className="sr-only">Uploading image...</span>
                </div>
              )}
              {error && (
                <p className="full-width center-align">{error.message}</p>
              )}
            </form>
          )}
        </Mutation>
        <p className="small-text center-align">
          NB! The uploaded image has to be either in PNG or JPEG format and
          below 1 MB.
        </p>
      </Fragment>
    );
  }
}

ImageChooser.propTypes = {
  onClickImage: PropTypes.func
};

export default ImageChooser;
