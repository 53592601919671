import gql from 'graphql-tag';

const GET_CONTENT_PAGE = gql`
  query ContentPage(
    $type: ContentType!
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    contentPage(
      type: $type
      filter: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
    ) {
      id
      type
      title
      subtitle
      icon {
        id
        name
        base64
        mimetype
      }
      availability {
        published
      }
      meta {
        displayOrder
      }
    }
  }
`;

const UPDATE_CONTENT_PAGE = gql`
  mutation UpdateContentPage(
    $id: ID!
    $title: String
    $icon: ID
    $subtitle: String
    $type: ContentType!
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    updateContentPage(
      id: $id
      title: $title
      icon: $icon
      subtitle: $subtitle
      type: $type
      availability: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    ) {
      id
    }
  }
`;

const CREATE_CONTENT_PAGE = gql`
  mutation CreateContentPage(
    $title: String!
    $subtitle: String
    $icon: ID
    $type: ContentType!
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    createContentPage(
      title: $title
      subtitle: $subtitle
      icon: $icon
      type: $type
      availability: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    ) {
      id
    }
  }
`;

export {
  GET_CONTENT_PAGE,
  CREATE_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE
};