import React, { Component } from "react";
import PropTypes from "prop-types";

class Video23 extends Component {
  render() {
    return <div {...this.props.attributes}>{this.renderVideo()}</div>;
  }

  renderVideo = () => {
    const { isFocused, id } = this.props;

    const wrapperStyle = {
      position: "relative",
      outline: isFocused ? "2px solid blue" : "none",
    };

    const maskStyle = {
      display: isFocused ? "none" : "block",
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      cursor: "cell",
      zIndex: 1,
    };

    const iframeStyle = {
      display: 'block',
    }


    return (
      <div style={wrapperStyle}>
        <div style={maskStyle} />
          <iframe
            src={`//region-hovedstaden-ekstern.23video.com/v.ihtml/player.html?source=embed&photo%5fid=${id}&autoPlay=0`}
            width="426"
            height="240"
            frameBorder="0"
            border="0"
            scrolling="no"
            mozallowfullscreen="1"
            webkitallowfullscreen="1"
            allowFullScreen="1"
            allow="autoplay; fullscreen"
            style={iframeStyle}

          ></iframe>
        
      </div>
    );
  };
}

Video23.propTypes = {
  node: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  attributes: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Video23;
