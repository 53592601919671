import gql from 'graphql-tag';

const GET_CHECKLISTS = gql`
  query Checklists(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    checklists(
      filter: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
      pagination: {
        orderBy: $orderBy
        cursor: $cursor
        limit: $limit
      }
    ) {
      edges {
        id
        title
        subtitle
        headline
        content
        availability {
          published
          fromWeek
          toWeek
          hiddenFromDashboard
          shownRandomly
          isBorn
        }
        meta {
          displayOrder
        }
        subChecklists {
          id
          content
          items
          availability {
            published
          }
          meta {
            displayOrder
          }
        }
      }
    }
  }
`;

const CREATE_CHECKLIST = gql`
  mutation createChecklist(
    $title: String!
    $subtitle: String
    $languageCode: String!
    $app: App!
    $published: Boolean! = false	
    $displayOrder: Int
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
  ) {
    createChecklist(
      title: $title
      subtitle: $subtitle
      displayOrder: $displayOrder
      availability: {
        app: $app
        published: $published
        languageCode: $languageCode
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
      }
    ) {
      id
    }
  }
`;

const DELETE_CHECKLIST = gql`
  mutation DeleteChecklist(
    $id: ID!
  ) {
    deleteChecklist(
      id: $id
    )
  }
`;

const UPDATE_CHECKLIST = gql`
  mutation UpdateChecklist(
    $id: ID!
    $title: String
    $subtitle: String
    $headline: String
    $displayOrder: Int
    $languageCode: String!
    $app: App!
    $published: Boolean
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $isBorn: Boolean
  ) {
    updateChecklist(
      id: $id
      title: $title
      subtitle: $subtitle
      headline: $headline
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
        isBorn: $isBorn
      }
    ) {
      id
    }
  }
`;

const CREATE_SUBCHECKLIST = gql`
  mutation CreateSubChecklist(
    $checklist: ID!
    $items: [String!]
    $displayOrder: Int
    $content: JSON
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    createSubChecklist(
      checklist: $checklist
      content: $content
      items: $items
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const UPDATE_SUBCHECKLIST = gql`
  mutation UpdateSubChecklist(
    $id: ID!
    $content: JSON
    $items: [String!]
    $app: App!
    $languageCode: String!
    $published: Boolean
    $displayOrder: Int
  ) {
    updateSubChecklist(
      id: $id
      content: $content
      items: $items
      availability: {
        app: $app
        languageCode: $languageCode
        published: $published
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const DELETE_SUBCHECKLIST = gql`
  mutation DeleteSubChecklist(
    $id: ID!
  ) {
    deleteSubChecklist(
      id: $id 
    )
  }
`;

export {
  GET_CHECKLISTS,
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  UPDATE_CHECKLIST,
  CREATE_SUBCHECKLIST,
  UPDATE_SUBCHECKLIST,
  DELETE_SUBCHECKLIST,
};