import gql from 'graphql-tag';

const GET_ONBOARDING_STEPS = gql`
  query OnboardingSteps(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    onboardingSteps(
      filter: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
      pagination: {
        orderBy: $orderBy
        cursor: $cursor
        limit: $limit
      }
    ) {
      edges {
        id
        title
        content
        availability {
          published
          fromWeek
          toWeek
          hiddenFromDashboard
          shownRandomly
        }
        meta {
          displayOrder
        }
      }
    }
  }
`;

const CREATE_ONBOARDING_STEP = gql`
  mutation CreateOnboardingStep(
    $title: String!
    $content: JSON
    $languageCode: String!
    $app: App!
    $published: Boolean!
    $displayOrder: Int
  ) {
    createOnboardingStep(
      title: $title
      content: $content
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    ) {
      id
      title
      content
      meta {
        displayOrder
      }
    }
  }
`;

const DELETE_ONBOARDING_STEP = gql`
  mutation DeleteOnboardingStep(
    $id: ID!
  ) {
    deleteOnboardingStep(
      id: $id
    )
  }
`;

const UPDATE_ONBOARDING_STEP = gql`
  mutation UpdateOnboardingStep(
    $id: ID!
    $title: String
    $content: JSON
    $languageCode: String!
    $app: App!
    $published: Boolean!
    $displayOrder: Int
  ) {
    updateOnboardingStep(
      id: $id
      title: $title
      content: $content
      availability: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

export {
  GET_ONBOARDING_STEPS,
  CREATE_ONBOARDING_STEP,
  DELETE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP
};