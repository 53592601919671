import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Throbber = ({
  hidden,
  loadingText,
  alignRight,
  alignLeft,
  relative
}) => (
  <div className={cx('u-loader__fluid', {
    'sr-only': hidden,
    'u-loader__fluid--right': alignRight,
    'u-loader__fluid--left': alignLeft,
    'u-loader__fluid--relative': relative
  })}>
    <div>
      <p className="full-width center-align">
        {loadingText}
      </p>
      <div className="u-loader__rotate u-loader__rotate--blue" />
    </div>
  </div>
);

Throbber.propTypes = {
  hidden: PropTypes.bool,
  loadingText: PropTypes.string.isRequired,
  alignRight: PropTypes.bool,
  alignLeft: PropTypes.bool,
  relative: PropTypes.bool
}

export default Throbber;