import { LOGIN,GET_AUTH_TOKEN } from './login';
import {
  GET_SUPPORTED_LANGUAGES,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  GET_SINGLE_LANGUAGE,
  DUPLICATE_LANGUAGE
} from './languages';
import {
  GET_USERS,
  DELETE_USER,
  DELETE_BABY,
  EXPORT_JOURNAL_TO_PDF,
  UPDATE_LAST_ACTIVITY,
  USER_ID,
} from './users';
import {
  GET_ENCYCLOPEDIA,
  DELETE_ARTICLE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  CREATE_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION,
  CREATE_SUBSECTION,
  DELETE_SUBSECTION,
  UPDATE_SUBSECTION,
  EXPORT_ENCYCLOPEDIA
} from './encyclopedia';
import {
  GET_STATIC_CONTENT,
  UPDATE_STATIC_CONTENT,
  CREATE_STATIC_CONTENT,
  DELETE_STATIC_CONTENT
} from './staticContent';
import {
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION
} from './notifications';
import {
  GET_LINKS
} from './links';
import {
  GET_SETTINGS,
  PUBLISH,
  RESET_USER_PASSWORD
} from './settings';
import {
  GET_CHECKLISTS,
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  UPDATE_CHECKLIST,
  CREATE_SUBCHECKLIST,
  UPDATE_SUBCHECKLIST,
  DELETE_SUBCHECKLIST,
} from './checklists';
import {
  GET_ONBOARDING_STEPS,
  CREATE_ONBOARDING_STEP,
  DELETE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP
} from './onboarding';
import {
  GET_VIDEO_TUTORIALS,
  CREATE_VIDEO_TUTORIAL,
  DELETE_VIDEO_TUTORIAL,
  UPDATE_VIDEO_TUTORIAL,
  CREATE_VIDEO_SUBSECTION,
  DELETE_VIDEO_SUBSECTION,
  UPDATE_VIDEO_SUBSECTION
} from './videoTutorials';
import {
  GET_IMAGES,
  SINGLE_UPLOAD,
  DELETE_IMAGE
} from './images';
import {
  GET_APPOINTMENTS,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS_LINK,
  SET_APPOINTMENT_TYPE_LINK
} from './appointments';
import {
  GET_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE,
  CREATE_CONTENT_PAGE
} from './contentPage';
import {
  GET_DOCTOR_TIPS,
  CREATE_DOCTOR_TIP,
  DELETE_DOCTOR_TIP,
  UPDATE_DOCTOR_TIP,
  CREATE_DOCTOR_TIP_SUBSECTION,
  DELETE_DOCTOR_TIP_SUBSECTION,
  UPDATE_DOCTOR_TIP_SUBSECTION
} from './doctorTips';

export { 
  LOGIN, 
  GET_SUPPORTED_LANGUAGES,
  GET_USERS,
  DELETE_USER,
  DELETE_BABY,
  EXPORT_JOURNAL_TO_PDF,
  GET_AUTH_TOKEN,
  GET_ENCYCLOPEDIA,
  DELETE_ARTICLE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  CREATE_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION,
  CREATE_SUBSECTION,
  DELETE_SUBSECTION,
  UPDATE_SUBSECTION,
  GET_STATIC_CONTENT,
  UPDATE_STATIC_CONTENT,
  CREATE_STATIC_CONTENT,
  DELETE_STATIC_CONTENT,
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_LINKS,
  GET_SETTINGS,
  GET_CHECKLISTS,
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  UPDATE_CHECKLIST,
  GET_ONBOARDING_STEPS,
  CREATE_ONBOARDING_STEP,
  DELETE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP,
  GET_VIDEO_TUTORIALS,
  CREATE_VIDEO_TUTORIAL,
  DELETE_VIDEO_TUTORIAL,
  UPDATE_VIDEO_TUTORIAL,
  EXPORT_ENCYCLOPEDIA,
  GET_IMAGES,
  SINGLE_UPLOAD,
  DELETE_IMAGE,
  GET_APPOINTMENTS,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE,
  CREATE_CONTENT_PAGE,
  PUBLISH,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  GET_SINGLE_LANGUAGE,
  DUPLICATE_LANGUAGE,
  RESET_USER_PASSWORD,
  GET_APPOINTMENTS_LINK,
  SET_APPOINTMENT_TYPE_LINK,
  CREATE_VIDEO_SUBSECTION,
  DELETE_VIDEO_SUBSECTION,
  UPDATE_VIDEO_SUBSECTION,
  CREATE_SUBCHECKLIST,
  UPDATE_SUBCHECKLIST,
  DELETE_SUBCHECKLIST,
  UPDATE_LAST_ACTIVITY,
  USER_ID,
  GET_DOCTOR_TIPS,
  CREATE_DOCTOR_TIP,
  DELETE_DOCTOR_TIP,
  UPDATE_DOCTOR_TIP,
  CREATE_DOCTOR_TIP_SUBSECTION,
  DELETE_DOCTOR_TIP_SUBSECTION,
  UPDATE_DOCTOR_TIP_SUBSECTION
};