import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  GET_CHECKLISTS,
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  UPDATE_CHECKLIST,
  GET_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE,
  CREATE_CONTENT_PAGE
} from './../../gql';

import Subchecklists from './../../views/Checklists/Subchecklist';
import Accordion from './../../components/Accordion';
import Throbber from './../../components/Throbber';
import ContentPanel from './../../components/ContentPanel';
import VisibilityToggle from './../../components/VisibilityToggle';

import bridgeIcon from './../../../images/icons/bridge.svg';
import roundPlus from './../../../images/icons/round-plus-white.svg'; 

class Checklists extends Component {
  constructor() {
    super();
    this.state = {
      checklistUpdate: {},
      contenPageUpdate: {},
      publishIsUpToDate: null
    }
    this.submitDelay = 500;
    this.activatePublishButton = this.activatePublishButton.bind(this);
    this.activateUnpublishedAlert = this.activateUnpublishedAlert.bind(this);
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'CHECKLIST'
      }
    });
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_CHECKLISTS', 'GET_CONTENT_PAGE'],
        queryRefetchContentType: 'CHECKLIST',
      }
    });
  }

  getEmptyContentObject() {
    return {
      document: {
        nodes: [
          {
            object: 'block',
            type: 'paragraph',
          },
        ],
      },
    };
  }

  createContentPage(createContentPage) {
    const {
      app,
      languageCode,
    } = this.props;

    createContentPage({
      variables: {
        title: '',
        type: 'CHECKLIST',
        languageCode,
        app,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitContentUpdate(updateContentPage) {
    const { contenPageUpdate } = this.state;
    updateContentPage({
      variables: contenPageUpdate
    });
    this.setState({
      contenPageUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitChecklistUpdate(updateChecklist) {
    const { checklistUpdate } = this.state;
    updateChecklist({
      variables: checklistUpdate
    });
    this.setState({
      checklistUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onIsBornChange(id, value, updateFunc) {
    const {
      app,
      languageCode,
    } = this.props;
    const isBorn = value ? false : true;

    updateFunc({
      variables: {
        app,
        languageCode,
        id,
        isBorn,
        published: false,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onPageInputChanges(event, id, updateContentPage) {
    const {
      languageCode,
      app
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    clearTimeout(this.contentTimer);
    this.contentTimer = setTimeout(() => this.submitContentUpdate(updateContentPage), this.submitDelay);

    this.setState(prevState => {
      if (prevState.contenPageUpdate && prevState.contenPageUpdate.id === id) {
        return {
          contenPageUpdate: {
            ...this.state.contenPageUpdate,
            [name]: value
          }
        }
      }
      return {
        contenPageUpdate: {
          [name]: value,
          type: 'CHECKLIST',
          published: false,
          languageCode,
          app,
          id
        }
      }
    });
  }

  onVisibilityChange(event, id, updateChecklist) {
    const {
      app,
      languageCode,
    } = this.props;
    updateChecklist({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        hiddenFromDashboard: event === 'hiddenFromDashboard'
          ? true
          : false,
        shownRandomly: event === 'hiddenFromDashboard'
          ? false
          : true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onChecklistInputChanges(event, id, updateChecklist) {
    const {
      app,
      languageCode,
    } = this.props;
    const name = event.target.name;
    const value = isNaN(event.target.value)
      ? event.target.value
      : Number(event.target.value);

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitChecklistUpdate(updateChecklist), this.submitDelay);

    this.setState(prevState => {
      if (prevState.checklistUpdate && prevState.checklistUpdate.id === id) {
        return {
          checklistUpdate: {
            ...this.state.checklistUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        checklistUpdate: {
          id,
          app,
          languageCode,
          published: false,
          [name]: value
        }
      }
    });
  }

  createChecklist(createChecklist, checklists) {
    const {
      languageCode,
      app
    } = this.props;

    const orderIndex = checklists.length > 0
      ? checklists[checklists.length - 1].meta.displayOrder + 1
      : 0;

    createChecklist({
      variables: {
        title: '',
        languageCode: languageCode,
        app: app,
        displayOrder: orderIndex,
        hiddenFromDashboard: false,
        shownRandomly: true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteChecklist(id, deleteChecklist) {
    if (confirm('Are you sure you want to delete this checklist?')) {
      deleteChecklist({
        variables: {
          id
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning" key="notification">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      app,
      languageCode,
      published
    } = this.props;

    return (
      <Query
        query={GET_CONTENT_PAGE}
        variables={{
          type: 'CHECKLIST',
          languageCode,
          app
        }}>
        {({ data, loading, error }) => {
          if (loading)
            return <p className="full-width center-align">Loading...</p>;
          if (error)
            return <p className="full-width center-align">{error.message}</p>;
          const { contentPage } = data;
          
          return (
            contentPage !== null ? (
              <Mutation
                mutation={UPDATE_CONTENT_PAGE}
                refetchQueries={[{
                  query: GET_CONTENT_PAGE,
                  variables: {
                    type: 'CHECKLIST',
                    languageCode,
                    app
                  }
                }]}
              >
                {updateContentPage =>
                  <Query
                    query={GET_CHECKLISTS}
                    variables={{
                      languageCode: languageCode,
                      app: app,
                      limit: 1000
                    }}>
                    {({ data, loading, error }) => {
                      if (loading)
                        return <p className="full-width center-align">Loading...</p>;
                      if (error)
                        return <p className="full-width center-align">{error.message}</p>;
                      const { checklists } = data;

                      return [
                        !contentPage.availability.published &&
                          this.publishNotification(),
                        <Accordion
                          key="accordion"
                          id={contentPage.id}
                          title={contentPage.title}
                          subtitle={contentPage.subtitle}
                          twoHeaderInputs
                          headerIcon={bridgeIcon}
                          onChanges={event => this.onPageInputChanges(event, contentPage.id, updateContentPage)}>
                          {checklists.edges.length > 0 ? (
                            checklists.edges
                              .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                              .map(checklist =>
                                <Mutation
                                  key={checklist.id}
                                  mutation={DELETE_CHECKLIST}
                                  refetchQueries={[{
                                    query: GET_CHECKLISTS,
                                    variables: {
                                      languageCode: languageCode,
                                      app: app,
                                      limit: 1000
                                    }
                                  }]}
                                >
                                  {(deleteChecklist, { loading, error })  => (
                                    <div className="relative-block">
                                      {error &&
                                        <p className="full-width center-align">{error.message}</p>
                                      }
                                      {!checklist.availability.published &&
                                        this.publishNotification()
                                      }
                                      <Mutation mutation={UPDATE_CHECKLIST}
                                        refetchQueries={[{
                                          query: GET_CHECKLISTS,
                                          variables: {
                                            languageCode: languageCode,
                                            app: app,
                                            limit: 1000
                                          }
                                        }]}
                                      >
                                        {updateChecklist => (
                                          <Accordion
                                            id={checklist.id}
                                            title={checklist.title}
                                            subtitle={checklist.subtitle}
                                            displayOrder={checklist.meta.displayOrder}
                                            siblingAccordions={checklists.edges}
                                            updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateChecklist)}
                                            twoHeaderInputs
                                            enableOrdering
                                            deleteAccordion={() => this.deleteChecklist(checklist.id, deleteChecklist)}
                                            onChanges={event => this.onChecklistInputChanges(event, checklist.id, updateChecklist)}>
                                            <ContentPanel
                                              id={checklist.id}
                                              enableWeekRange
                                              onIsBornChange={(id, value) => this.onIsBornChange(id, value, updateChecklist)}
                                              title={checklist.headline}
                                              titleInputName="headline"
                                              isBorn={checklist.availability.isBorn}
                                              rangeFrom={checklist.availability.fromWeek}
                                              rangeTo={checklist.availability.toWeek}
                                              onChanges={event => this.onChecklistInputChanges(event, checklist.id, updateChecklist)}>
                                              <Subchecklists
                                                checklistId={checklist.id}
                                                subChecklists={checklist.subChecklists}
                                                languageCode={languageCode}
                                                published={published}
                                                app={app}
                                                activatePublishButton={this.activatePublishButton}
                                                activateUnpublishedAlert={this.activateUnpublishedAlert}
                                              />
                                              <div className="content-panel__footer">
                                                <div className="content-panel__actions">
                                                  <VisibilityToggle
                                                    parentId={checklist.id}
                                                    onChange={event => this.onVisibilityChange(event, checklist.id, updateChecklist)}
                                                    options={[
                                                      {id: 'shownRandomly', checked: checklist.availability.shownRandomly},
                                                      {id: 'hiddenFromDashboard', checked: checklist.availability.hiddenFromDashboard}
                                                    ]}
                                                  />
                                                </div>
                                              </div>
                                            </ContentPanel>
                                          </Accordion>
                                        )}
                                      </Mutation>
                                      <Throbber
                                        hidden={!loading}
                                        loadingText="Deleting checklist..."
                                      />
                                    </div>
                                  )}
                                </Mutation>
                              )
                          ) : (
                            <p className="full-width center-align">No checklist sections found.</p>
                          )}
                          <Mutation
                            mutation={CREATE_CHECKLIST}
                            refetchQueries={[{
                              query: GET_CHECKLISTS,
                              variables: {
                                languageCode: languageCode,
                                app: app,
                                limit: 1000
                              }
                            }]}>
                            {(createChecklist, { loading, error }) => (
                              <div className="full-width right-align relative-block u-margin--top-medium">
                                <Throbber
                                  hidden={!loading}
                                  loadingText="Creating checklist..."
                                />
                                {error &&
                                  <p className="full-width center-align">{error.message}</p>
                                }
                                <button
                                  className="button button--small button--icon-text"
                                  disabled={loading}
                                  onClick={() => this.createChecklist(createChecklist, checklists.edges)}>
                                  <img src={roundPlus} alt="Add new section" className="button__icon" />
                                  Add new section
                                </button>
                              </div>
                            )}
                          </Mutation>
                        </Accordion>
                      ]
                    }}
                  </Query>
                }
              </Mutation>
            ) : (
              <div>
                <p className="full-width center-align">
                  No content found for this page
                </p>
                <Mutation
                  mutation={CREATE_CONTENT_PAGE}
                  refetchQueries={[{
                    query: GET_CONTENT_PAGE,
                    variables: {
                      type: 'CHECKLIST',
                      languageCode,
                      app
                    }
                  }]}>
                  {(createContentPage, { loading, error }) =>
                    <div className="relative-block">
                      {error &&
                        <p className="full-width center-align">{error.message}</p>
                      }
                      <div className="right-align">
                        <button
                          className="button button--small button--icon-text"
                          disabled={loading}
                          onClick={() => this.createContentPage(createContentPage)}>
                          <img src={roundPlus} alt="Add" className="button__icon" />
                          Add new content page
                        </button>
                      </div>
                      <Throbber
                        hidden={!loading}
                        loadingText="Creating content page..."
                      />
                    </div>
                  }
                </Mutation>
              </div>
            )
          )
        }}
      </Query>
    );
  }
}

Checklists.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
};

export default Checklists;