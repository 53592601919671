import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { getErrorMessage } from './../utils';
import { UPDATE_LAST_ACTIVITY } from './../gql';
import Throbber from './../components/Throbber';
import checkmarkBadge from './../../images/icons/checkmark-badge.svg';
import warningIcon from './../../images/icons/warning.svg';

class UpdateActivity extends PureComponent {
  constructor() {
    super();
    this.state = {
      data: null,
      error: null,
    }
  }

  componentDidMount() {
    const { client } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const userToken = urlParams.get('userToken');
    sessionStorage.setItem('user_token', userToken);

    return client.mutate({
      mutation: UPDATE_LAST_ACTIVITY,
    })
      .then(results => {
        const { data } = results;
        this.setState({ data });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    const { data, error } = this.state;

    if (data) return (
      <div className="deck deck--centered">
        <div className="center-align">
          <img className="badge" src={checkmarkBadge} alt="Update completed" />
          <p>Your account activity has now been updated.</p>
        </div>
      </div>
    );

    if (error) return (
      <div className="deck deck--centered">
        <div className="center-align">
          <img className="badge" src={warningIcon} alt="Error" />
          <p>{getErrorMessage(error.message)}</p>
        </div>
      </div>
    );

    return (
      <div className="deck deck--centered">
        <Throbber loadingText="Loading..." relative />
      </div>
    );
  }
}

UpdateActivity.propTypes = {
  client: PropTypes.object.isRequired
}

export default withApollo(UpdateActivity);