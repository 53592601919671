import React, { Component } from "react";
import { getEventRange, getEventTransfer, Editor } from "slate-react";
import { Block, Value } from "slate";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import _ from "lodash";

import Video from "./Video";
import ToolbarButton from "./ToolbarButton";
import Overlay from "./../Overlay";
import Popover from "./Popover";

import addImageIcon from "./../../../images/icons/add-image.svg";
import vimeoLogo from "./../../../images/icons/vimeo.svg";
import extLinkIcon from "./../../../images/icons/external-link.svg";
import intLinkIcon from "./../../../images/icons/internal-link.svg";
import boldIcon from "./../../../images/icons/bold-button.svg";
import italicIcon from "./../../../images/icons/italic-button.svg";
import numberedList from "./../../../images/icons/numbered-list.svg";
import bulletList from "./../../../images/icons/bullet-list.svg";
import ImageChooser from "../ImageChooser";
import LinkChooser from "../LinkChooser";
import Video23 from "./Video23";

const Image = styled("img")`
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: ${(props) => (props.selected ? "0 0 0 2px blue;" : "none")};
`;

const DEFAULT_NODE = "paragraph";

const schema = {
  document: {
    last: { type: "paragraph" },
    normalize: (editor, { code, node }) => {
      switch (code) {
        case "last_child_type_invalid": {
          const paragraph = Block.create("paragraph");
          return editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
        }
      }
    },
  },
  blocks: {
    video: {
      isVoid: true,
    },
    video23: {
      isVoid: true,
    },
    image: {
      isVoid: true,
    },
  },
};

function isImage(url) {
  return !!imageExtensions.find(url.endsWith);
}

function insertVideo(editor, src, target) {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: "video",
    data: { src },
  });
}

function insertVideo23(editor, data, target) {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: "video23",
    data: data,
  });
}

function insertImage(editor, src, target, id) {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: "image",
    data: { src, id },
  });
}

function wrapLink(editor, href) {
  editor.wrapInline({
    type: "link",
    data: { href },
  });

  editor.moveToEnd();
}

function wrapInternalLink(editor, to, contentType) {
  editor.wrapInline({
    type: "internalLink",
    data: { to, contentType },
  });

  editor.moveToEnd();
}

function unwrapLink(editor) {
  editor.unwrapInline("link");
}

function unwrapInternalLink(editor) {
  editor.unwrapInline("internalLink");
}

class WysiwygEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: Value.fromJSON(props.editorData),
      linksOverlayOpen: false,
      imagesOverlayOpen: false,
    };
    this.toggleLinksOverlay = this.toggleLinksOverlay.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state;
    const { onChangeValue } = this.props;

    if (prevState.value.document !== value.document) {
      onChangeValue(value.toJSON());
    }
  }

  toggleLinksOverlay = () => {
    const { editor } = this;
    const hasInternalLinks = this.hasInternalLinks();

    if (hasInternalLinks) {
      editor.command(unwrapInternalLink);
    } else {
      this.setState((prevState) => ({
        linksOverlayOpen: !prevState.linksOverlayOpen,
      }));
    }
  };

  toggleImagesOverlay = () => {
    this.setState((prevState) => ({
      imagesOverlayOpen: !prevState.imagesOverlayOpen,
    }));
  };

  hasLinks = () => {
    const { value } = this.state;
    return value.inlines.some((inline) => inline.type == "link");
  };

  hasInternalLinks = () => {
    const { value } = this.state;
    return value.inlines.some((inline) => inline.type == "internalLink");
  };

  hasMark = (type) => {
    const { value } = this.state;
    return value.activeMarks.some((mark) => mark.type === type);
  };

  hasBlock = (type) => {
    const { value } = this.state;
    return value.blocks.some((node) => node.type === type);
  };

  isLinkActive = (type) => {
    const { value } = this.state;
    if (value) {
      if (type === "internalLink") {
        return this.hasInternalLinks();
      }
      return this.hasLinks();
    }
  };

  isMarkActive = (type) => {
    let isActive = false;
    const { value } = this.state;
    if (value) {
      isActive = this.hasMark(type);
    }
    return isActive;
  };

  isBlockActive = (type) => {
    let isActive = false;
    const { value } = this.state;
    if (value) {
      if (["numbered-list", "bulleted-list"].includes(type)) {
        const { document, blocks } = value;
        if (blocks.size > 0) {
          const parent = document.getParent(blocks.first().key);
          isActive =
            this.hasBlock("list-item") && parent && parent.type === type;
        }
      }
    }
    return isActive;
  };

  ref = (editor) => {
    this.editor = editor;
  };

  onClickMark = (event, type) => {
    this.editor.toggleMark(type);
    event.preventDefault();
  };

  onClickBlock = (event, type) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== "bulleted-list" && type !== "numbered-list") {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock("list-item");
      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock("list-item");
      const isType = value.blocks.some((block) => {
        return !!document.getClosest(
          block.key,
          (parent) => parent.type === type
        );
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        editor
          .unwrapBlock(
            type === "bulleted-list" ? "numbered-list" : "bulleted-list"
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks("list-item").wrapBlock(type);
      }
    }
  };

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "italic":
        return <i {...attributes}>{children}</i>;
      default:
        return next();
    }
  };

  renderNode = (props, editor, next) => {
    const { app, languageCode } = this.props;
    const { attributes, node, isFocused, children } = props;

    switch (node.type) {
      case "image": {
        const src = node.data.get("src");
        return <Image src={src} selected={isFocused} {...attributes} />;
      }
      case "video": {
        const src = node.data.get("src");
        return <Video src={src} {...props} />;
      }
      case "video23": {
        const embedCode = node.data.get("embedCode");
        const id = node.data.get("id");
        return <Video23 embedCode={embedCode} id={id} {...props} />;
      }
      case "link": {
        const { data } = node;
        const href = data.get("href");
        return (
          <a {...attributes} href={href}>
            <Popover title={href}>{children}</Popover>
          </a>
        );
      }
      case "internalLink": {
        const { data } = node;
        const href = data.get("to");
        return (
          <a {...attributes} data-source={href}>
            <Popover linkId={href} app={app} languageCode={languageCode}>
              {children}
            </Popover>
          </a>
        );
      }
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      default: {
        return next();
      }
    }
  };

  addImageToEditorField = (imagePath, id) => {
    this.editor.command(insertImage, imagePath, null, id);
    setTimeout(() => this.toggleImagesOverlay(), 1);
  };

  onClickVideo = (event) => {
    event.preventDefault();
    const playerUrl = "https://player.vimeo.com/video/";
    const vimeoId = window.prompt("Enter the ID of the Vimeo clip:");
    const src = playerUrl + vimeoId;

    if (isNaN(vimeoId)) {
      return alert(
        "The ID entered is not a valid Vimeo ID - please try again."
      );
    } else if (!vimeoId) {
      return;
    }
    this.editor.command(insertVideo, src);
  };

  onClickVideo23 = async (event) => {
    event.preventDefault();
    const id = window.prompt("Enter the id of the Video 23 clip:");
    
    if (!id) {
      return;
    }
    try {
      // const result = await fetch(`https://region-hovedstaden-ekstern.23video.com/api/player/embed?url=${url}&format=json`)
      // const data = await result.json();
      
      // console.log({data});

  
      this.editor.command(insertVideo23, { id });
   
    } catch (error) {
      console.log(error)
      window.alert("Could not find video")
    }


  };

  onClickArticleLink = (linkUrl, linkTitle, contentType) => {
    const { editor } = this;
    const { value } = editor;
    const hasInternalLinks = this.hasInternalLinks();

    if (hasInternalLinks) {
      editor.command(unwrapInternalLink);
    } else if (value.selection.isExpanded) {
      const href = linkUrl;

      if (!linkUrl) {
        return;
      }
      editor.command(wrapInternalLink, href, contentType);
      setTimeout(() => this.toggleLinksOverlay(), 1);
    } else {
      const href = linkUrl;

      if (href === null) {
        return;
      }

      const text = window.prompt("Enter the text for the link:", linkTitle);

      if (text === null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapInternalLink, href, contentType);
      setTimeout(() => this.toggleLinksOverlay(), 1);
    }
  };

  validateEnteredURL = (href) => {
    if (href === null) {
      return false;
    }

    if (
      !isUrl(href) &&
      !_.startsWith(href, "mailto:") &&
      !_.startsWith(href, "tel:")
    ) {
      alert("The URL entered is not valid.");
      return false;
    }

    return true;
  };

  onClickExternalLink = (event) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const hasLinks = this.hasLinks();

    if (hasLinks) {
      editor.command(unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt("Enter the URL of the link:");

      if (!this.validateEnteredURL(href)) {
        return;
      }

      editor.command(wrapLink, href);
    } else {
      const href = window.prompt("Enter the URL of the link:");

      if (!this.validateEnteredURL(href)) {
        return;
      }

      const text = window.prompt("Enter the text for the link:");

      if (text === null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href);
    }
  };

  onKeyDown = (event, editor, next) => {
    if (!event.ctrlKey) return next();

    switch (event.key) {
      case "b": {
        event.preventDefault();
        this.editor.toggleMark("bold");
        break;
      }
      case "i": {
        event.preventDefault();
        this.editor.toggleMark("italic");
        break;
      }
      default: {
        next();
      }
    }
  };

  onDropOrPaste = (event, editor, next) => {
    const target = getEventRange(event, editor);
    if (!target && event.type === "drop") return next();

    const transfer = getEventTransfer(event);
    const { type, text, files } = transfer;

    if (type === "files") {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");
        if (mime !== "image") continue;

        reader.addEventListener("load", () => {
          editor.command(insertImage, reader.result, target);
        });

        reader.readAsDataURL(file);
      }
      return;
    }

    if (type === "text") {
      if (!isUrl(text)) return next();
      if (!isImage(text)) return next();
      editor.command(insertImage, text, target);
      return;
    }

    if (editor.value.selection.isCollapsed) return next();

    if (type != "text" && type != "html") return next();

    if (this.hasLinks()) {
      editor.command(unwrapLink);
    }

    if (this.hasInternalLinks()) {
      editor.command(unwrapInternalLink);
    }

    next();
  };

  onEditorChange = ({ value }) => {
    this.setState({
      value,
    });
  };

  onPaste = (event, editor, next) => {
    if (editor.value.selection.isCollapsed) return next();

    const transfer = getEventTransfer(event);
    const { type, text } = transfer;
    if (type != "text" && type != "html") return next();
    if (!isUrl(text)) return next();

    if (this.hasLinks()) {
      editor.command(unwrapLink);
    }

    if (this.hasInternalLinks()) {
      editor.command(unwrapInternalLink);
    }

    editor.command(wrapLink, text);
  };

  render() {
    const {
      enableArticleLinking,
      enableExternalLinking,
      enableImageUpload,
      enableVimeoEmbed,
      editorData,
      languageCode,
      app,
    } = this.props;
    const { linksOverlayOpen, imagesOverlayOpen, value } = this.state;

    return (
      <div className="wysiwyg-editor">
        <div className="wysiwyg-editor__toolbar">
          {enableArticleLinking && (
            <ToolbarButton
              type="internalLink"
              icon={intLinkIcon}
              onClick={this.toggleLinksOverlay}
              isActive={this.isLinkActive}
            />
          )}
          {enableExternalLinking && (
            <ToolbarButton
              type="link"
              icon={extLinkIcon}
              onClick={this.onClickExternalLink}
              isActive={this.isLinkActive}
            />
          )}
          {enableImageUpload && (
            <button
              className="wysiwyg-editor__tool-button"
              onClick={() => this.toggleImagesOverlay()}
              title="Add image"
            >
              <img
                className="wysiwyg-editor__tool-icon"
                src={addImageIcon}
                alt="Add image"
              />
            </button>
          )}
          {enableVimeoEmbed && (
            <button
              className="wysiwyg-editor__tool-button"
              onClick={this.onClickVideo}
              title="Add Vimeo clip"
            >
              <img
                className="wysiwyg-editor__tool-icon"
                src={vimeoLogo}
                alt="Add Vimeo clip"
              />
            </button>
          )}

          {enableVimeoEmbed && (
            <button
              className="wysiwyg-editor__tool-button"
              onClick={this.onClickVideo23}
              title="Add Video23 clip"
            >
              <div className="wysiwyg-editor__tool-icon">
                <p
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    lineHeight: "23px"
                  }}
                >
                  23
                </p>
              </div>
            </button>
          )}

          <ToolbarButton
            type="bold"
            icon={boldIcon}
            onClick={this.onClickMark}
            isActive={this.isMarkActive}
          />
          <ToolbarButton
            type="italic"
            icon={italicIcon}
            onClick={this.onClickMark}
            isActive={this.isMarkActive}
          />
          <ToolbarButton
            type="numbered-list"
            icon={numberedList}
            onClick={this.onClickBlock}
            isActive={this.isBlockActive}
          />
          <ToolbarButton
            type="bulleted-list"
            icon={bulletList}
            onClick={this.onClickBlock}
            isActive={this.isBlockActive}
          />
        </div>
        <div className="wysiwyg-editor__wrapper">
          <Editor
            placeholder="Insert content"
            ref={this.ref}
            defaultValue={Value.fromJSON(editorData)}
            value={value}
            onChange={this.onEditorChange}
            onKeyDown={this.onKeyDown}
            schema={schema}
            onDrop={this.onDropOrPaste}
            onPaste={this.onDropOrPaste}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
          />
        </div>
        {linksOverlayOpen && (
          <Overlay closeOverlay={this.toggleLinksOverlay}>
            <div className="overlay__dialog-wrapper">
              <LinkChooser
                app={app}
                languageCode={languageCode}
                onClickArticleLink={this.onClickArticleLink}
              />
            </div>
          </Overlay>
        )}
        {imagesOverlayOpen && (
          <Overlay closeOverlay={this.toggleImagesOverlay}>
            <div className="overlay__dialog-wrapper">
              <ImageChooser onClickImage={this.addImageToEditorField} />
            </div>
          </Overlay>
        )}
      </div>
    );
  }
}

WysiwygEditor.propTypes = {
  enableArticleLinking: PropTypes.bool,
  enableExternalLinking: PropTypes.bool,
  enableImageUpload: PropTypes.bool,
  enableVimeoEmbed: PropTypes.bool,
  onChangeValue: PropTypes.func,
  editorData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
};

export default WysiwygEditor;
