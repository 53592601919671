import React from 'react';
import PropTypes from 'prop-types';

const Sequenzer = ({
  onMoveUp,
  onMoveDown,
  index,
  lowestIndex,
  highestIndex,
  disable
}) => (
  <div className="sequenzer">
    <button
      title="Move up"
      className="sequenzer__button sequenzer__button--up"
      disabled={index === lowestIndex || disable}
      onClick={() => onMoveUp()}>
      <span className="sr-only">Move up</span>
    </button>
    <button
      title="Move down"
      className="sequenzer__button sequenzer__button--down"
      disabled={index === highestIndex || disable}
      onClick={() => onMoveDown()}>
      <span className="sr-only">Move down</span>
    </button>
  </div>
);

Sequenzer.propTypes = {
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  index: PropTypes.number,
  lowestIndex: PropTypes.number,
  highestIndex: PropTypes.number,
  disable: PropTypes.bool
};

export default Sequenzer;