import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  GET_ONBOARDING_STEPS,
  CREATE_ONBOARDING_STEP,
  DELETE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP
} from './../gql';

import ContentPanel from './../components/ContentPanel';
import WysiwygEditor from './../components/WysiwygEditor';
import Throbber from './../components/Throbber';
import ErrorBoundary from './../components/ErrorBoundary';

import roundPlus from './../../images/icons/round-plus-white.svg';

class OnBoarding extends Component {
  constructor() {
    super();
    this.state = {
      contentUpdate: {},
      publishIsUpToDate: null
    };
    this.submitDelay = 500;
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'ONBOARDING'
      }
    });
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_ONBOARDING_STEPS'],
      }
    });
  }

  createOnboardingStep(createOnboardingStep, steps) {
    const {
      app,
      languageCode,
      published
    } = this.props;

    const orderIndex = steps.length > 0
      ? steps[steps.length - 1].meta.displayOrder + 1
      : 0;

    createOnboardingStep({
      variables: {
        title: '',
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              }
            ]
          }
        },
        displayOrder: orderIndex,
        languageCode,
        published,
        app
      }
    });
    this.activateUnpublishedAlert();
    this.activatePublishButton();
  }

  deleteOnboardingStep(deleteOnboardingStep, id) {
    if (confirm('Are you sure you want to delete this step?')) {
      deleteOnboardingStep({
        variables: {
          id
        }
      });
      this.activateUnpublishedAlert();
      this.activatePublishButton();
    }
  }

  onInputChange(event, id, updateOnboardingStep) {
    const {
      app,
      languageCode
    } = this.props;
    const value = event.target.value;

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      updateOnboardingStep({
        variables: {
          title: value,
          id,
          app,
          published: false,
          languageCode
        }
      });
      this.activateUnpublishedAlert();
      this.activatePublishButton();
    }, this.submitDelay);
  }

  onEditorChange(value, id, updateOnboardingStep) {
    const {
      app,
      languageCode
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateOnboardingStep({
        variables: {
          content: value,
          id,
          app,
          published: false,
          languageCode
        }
      });
      this.activateUnpublishedAlert();
      this.activatePublishButton();
    }, this.submitDelay);
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activateUnpublishedAlert();
    this.activatePublishButton();
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      app,
      languageCode,
      published
    } = this.props;

    return (
      <Query
        query={GET_ONBOARDING_STEPS}
        variables={{
          languageCode,
          app,
          limit: 1000
        }}>
        {({ data, loading, error }) => {
          if (loading)
            return <p className="full-width center-align">Loading...</p>;
          if (error)
            return <p className="full-width center-align">{error.message}</p>;
          const { onboardingSteps } = data;
          
          return (
            <div>
              {onboardingSteps.edges.length > 0 ? (
                onboardingSteps.edges
                  .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                  .map(step =>
                    <div className="u-margin--bottom-large" key={step.id}>
                      <Mutation
                        mutation={DELETE_ONBOARDING_STEP}
                        refetchQueries={[{
                          query: GET_ONBOARDING_STEPS,
                          variables: {
                            languageCode: languageCode,
                            app: app,
                            limit: 1000
                          }
                        }]}
                      >
                        {(deleteOnboardingStep, { loading, error }) => (
                          <Mutation 
                            mutation={UPDATE_ONBOARDING_STEP}
                            refetchQueries={[{
                              query: GET_ONBOARDING_STEPS,
                              variables: {
                                languageCode: languageCode,
                                app: app,
                                limit: 1000
                              }
                            }]}
                          >
                            {updateOnboardingStep =>
                              <div className="relative-block">
                                {error &&
                                  <p className="full-width center-align">{error.message}</p>
                                }
                                {!step.availability.published &&
                                  this.publishNotification()
                                }
                                <ContentPanel
                                  title={step.title}
                                  id={step.id}
                                  enableOrdering
                                  displayOrder={step.meta.displayOrder}
                                  siblingPanels={onboardingSteps.edges}
                                  updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateOnboardingStep)}
                                  deletePanel={() => this.deleteOnboardingStep(deleteOnboardingStep, step.id)}
                                  onChanges={event => this.onInputChange(event, step.id, updateOnboardingStep)}
                                >
                                  <ErrorBoundary>
                                    <WysiwygEditor
                                      enableArticleLinking
                                      enableExternalLinking
                                      enableImageUpload
                                      enableVimeoEmbed
                                      languageCode={languageCode}
                                      app={app}
                                      published={published}
                                      editorData={step.content}
                                      onChangeValue={event => this.onEditorChange(event, step.id, updateOnboardingStep)}
                                    />
                                  </ErrorBoundary>
                                </ContentPanel>
                                <Throbber 
                                  hidden={!loading}
                                  loadingText="Deleting step..."
                                />
                              </div>
                            }
                          </Mutation>
                        )}
                      </Mutation>
                    </div>
                  )
              ) : (
                <p className="full-width center-align">No on-boarding steps found.</p>
              )}
              <Mutation
                mutation={CREATE_ONBOARDING_STEP}
                refetchQueries={[{
                  query: GET_ONBOARDING_STEPS,
                  variables: {
                    languageCode: languageCode,
                    app: app,
                    limit: 1000
                  }
                }]}
              >
                {(createOnboardingStep, { loading, error }) => (
                  <div className="full-width right-align relative-block u-margin--top-medium">
                    <Throbber
                      hidden={!loading}
                      loadingText="Creating step..."
                    />
                    {error &&
                      <p className="full-width center-align">{error.message}</p>
                    }
                    <button
                      className="button button--small button--icon-text"
                      disabled={loading}
                      onClick={() => this.createOnboardingStep(createOnboardingStep, onboardingSteps.edges)}>
                      <img src={roundPlus} alt="Add new step" className="button__icon" />
                      Add new step
                    </button>
                  </div>
                )}
              </Mutation>
            </div>
          )
        }}
      </Query>
    );
  }
}

OnBoarding.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
}

export default OnBoarding;