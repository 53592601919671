import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types';

const ToolbarButton = ({ type, icon, onClick, isActive }) => {
  const active = isActive(type)

  return (
    <button
      className={
        cx('wysiwyg-editor__tool-button', {
          'wysiwyg-editor__tool-button--active': active
        })
      }
      onMouseDown={event => onClick(event, type)}
    >
      <img className="wysiwyg-editor__tool-icon" src={icon} alt={`Set ${type} styling`} />

    </button >
  )
}
ToolbarButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.func
}

export default ToolbarButton

