import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import {
  GET_ENCYCLOPEDIA,
  CREATE_SUBSECTION,
  DELETE_SUBSECTION,
  UPDATE_SUBSECTION
} from './../../gql';

import Throbber from './../../components/Throbber';
import ContentPanel from './../../components/ContentPanel';
import WysiwygEditor from './../../components/WysiwygEditor';
import VisibilityToggle from './../../components/VisibilityToggle';
import Tags from './../../components/Tags';
import ErrorBoundary from './../../components/ErrorBoundary';

import roundPlus from './../../../images/icons/round-plus-white.svg';

class Subsections extends Component {
  constructor() {
    super();
    this.state = {
      subsectionUpdate: {},
      publishIsUpToDate: null
    }
    this.submitDelay = 500;
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  createSubsection(createSubsection, subsections) {
    const {
      sectionId,
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    const orderIndex = subsections.length > 0
      ? subsections[subsections.length - 1].meta.displayOrder + 1
      : 0;

    createSubsection({
      variables: {
        title: '',
        subtitle: '',
        sectionId: sectionId,
        languageCode: languageCode,
        app: app,
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              },
            ],
          },
        },
        hiddenFromDashboard: false,
        shownRandomly: true,
        displayOrder: orderIndex
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  deleteSubsection(id, deleteSubsection) {
    const {
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;
    if (confirm('Are you sure you want to delete this subsection?')) {
      deleteSubsection({
        variables: { id }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }
  }

  submitUpdate(updateSubsection) {
    const {
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;
    const { subsectionUpdate } = this.state;
    updateSubsection({
      variables: subsectionUpdate
    });
    this.setState({
      subsectionUpdate: {}
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  onIsBornChange(id, value, updateFunc) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;
    const isBorn = value ? false : true;

    updateFunc({
      variables: {
        app,
        languageCode,
        id,
        isBorn,
        published: false,
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  onChangeHandle(event, id, updateSubsection) {
    const {
      app,
      languageCode,
      published
    } = this.props;
    const name = event.target.name;
    const value = isNaN(event.target.value)
      ? event.target.value
      : Number(event.target.value);

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitUpdate(updateSubsection), this.submitDelay);

    this.setState(prevState => {
      if (prevState.subsectionUpdate && prevState.subsectionUpdate.id === id) {
        return {
          subsectionUpdate: {
            ...this.state.subsectionUpdate,
            id: id,
            [name]: value
          }
        }
      }
      return {
        subsectionUpdate: {
          id: id,
          app: app,
          published: published,
          languageCode: languageCode,
          [name]: value
        }
      }
    });
  }

  onChangeEditor(value, id, updateSubsection) {
    const {
      app,
      languageCode,
      published,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateSubsection({
        variables: {
          id: id,
          app: app,
          published: published,
          languageCode: languageCode,
          content: value
        }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }, this.submitDelay);
  }

  onVisibilityChange(event, id, updateSubsection) {
    const {
      app,
      languageCode,
      published,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;
    updateSubsection({
      variables: {
        id: id,
        app: app,
        published: published,
        languageCode: languageCode,
        hiddenFromDashboard: event === 'hiddenFromDashboard'
          ? true
          : false,
        shownRandomly: event === 'hiddenFromDashboard'
          ? false
          : true
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  onTagsChange(tags, id, updateSubsection) {
    const {
      app,
      languageCode,
      published,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    updateSubsection({
      variables: {
        id: id,
        app: app,
        published: published,
        languageCode: languageCode,
        tags: tags
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  publishNotification() {
    const { activatePublishButton } = this.props;
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const { subsections } = this.props;
    const {
      app,
      languageCode,
      published
    } = this.props;

    return (
      <div>
        {subsections
          .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
          .map(subsection =>
            <Mutation
              key={subsection.id}
              mutation={DELETE_SUBSECTION}
              refetchQueries={[{
                query: GET_ENCYCLOPEDIA,
                variables: {
                  languageCode: languageCode,
                  app: app
                }
              }]}
            >
              {(deleteSubsection, { loading, error })  => (
                <div className="relative-block">
                  {error &&
                    <p className="full-width center-align">{error.message}</p>
                  }
                  {!subsection.availability.published &&
                    this.publishNotification()
                  }
                  <Mutation
                    mutation={UPDATE_SUBSECTION}
                    refetchQueries={[
                      {
                        query: GET_ENCYCLOPEDIA,
                        variables: {
                          languageCode: languageCode,
                          app: app
                        }
                      }
                    ]}
                  >
                    {updateSubsection => 
                      <ContentPanel
                        key={subsection.id}
                        enableWeekRange
                        enableOrdering
                        id={subsection.id}
                        title={subsection.title}
                        isBorn={subsection.availability.isBorn}
                        rangeFrom={subsection.availability.fromWeek}
                        rangeTo={subsection.availability.toWeek}
                        displayOrder={subsection.meta.displayOrder}
                        siblingPanels={subsections}
                        onIsBornChange={(id, value) => this.onIsBornChange(id, value, updateSubsection)}
                        updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateSubsection)}
                        onChanges={event => this.onChangeHandle(event, subsection.id, updateSubsection)}
                        deletePanel={() => this.deleteSubsection(subsection.id, deleteSubsection)}>
                        <ErrorBoundary>
                          <WysiwygEditor
                            enableArticleLinking
                            enableExternalLinking
                            enableImageUpload
                            enableVimeoEmbed
                            languageCode={languageCode}
                            app={app}
                            published={published}
                            editorData={subsection.content}
                            onChangeValue={event => this.onChangeEditor(event, subsection.id, updateSubsection)}
                          />
                        </ErrorBoundary>
                        <div className="content-panel__footer">
                          <div className="content-panel__form">
                            <Tags
                              tags={subsection.tags}
                              onChange={event => this.onTagsChange(event, subsection.id, updateSubsection)}
                            />
                          </div>
                          <div className="content-panel__actions">
                            <VisibilityToggle
                              parentId={subsection.id}
                              onChange={event => this.onVisibilityChange(event, subsection.id, updateSubsection)}
                              options={[
                                {id: 'shownRandomly', checked: subsection.availability.shownRandomly},
                                {id: 'hiddenFromDashboard', checked: subsection.availability.hiddenFromDashboard}
                              ]}
                            />
                          </div>
                        </div>
                      </ContentPanel>
                    }
                  </Mutation>
                  <Throbber 
                    hidden={!loading}
                    loadingText="Deleting section..."
                  />
                </div>
              )}
            </Mutation>
          )
        }
        <Mutation
          mutation={CREATE_SUBSECTION}
          refetchQueries={[{
            query: GET_ENCYCLOPEDIA,
            variables: {
              languageCode: languageCode,
              app: app
            }
          }]}>
          {(createSubsection, { loading, error }) => (
            <div className="full-width right-align relative-block">
              <Throbber
                hidden={!loading}
                loadingText="Creating subsection..."
              />
              {error &&
                <p className="full-width center-align">{error.message}</p>
              }
              <div className="full-width right-align">
                <button
                  className="button button--small button--icon-text"
                  disabled={loading}
                  onClick={() => this.createSubsection(createSubsection, subsections)}>
                  <img src={roundPlus} alt="Add new subsection" className="button__icon" />
                  Add new subsection
                </button>
              </div>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

Subsections.propTypes = {
  sectionId: PropTypes.string.isRequired,
  subsections: PropTypes.array.isRequired,
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  activatePublishButton: PropTypes.func.isRequired,
  activateUnpublishedAlert: PropTypes.func.isRequired
}

export default Subsections;