import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { GET_AUTH_TOKEN } from '../gql';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';

import App from './../App';
import Login from './Login';
import ResetPassword from './ResetPassword';
import DeleteUser from './DeleteUser';
import UpdateActivity from './UpdateActivity';

class AuthGate extends Component {
  render() {
    return (
      <Query query={GET_AUTH_TOKEN}>
        {({ data, loading, error }) => {
          if (loading) return <p>Loading... </p>;
          if (error) return <p>{error.message}</p>;
          const { authToken } = data;

          if (authToken) {
            return <App />;
          }
          return (
            <BrowserRouter>
              <Switch>
                <Route path="/login" render={() =>
                  <div className="deck">
                    <Login />
                  </div>
                } />
                <Route
                  path="/delete-user"
                  component={DeleteUser}
                />
                <Route
                  path="/update-activity"
                  component={UpdateActivity}
                />
                <Route path="/reset-password" render={() =>
                  <div className="deck">
                    <ResetPassword />
                  </div>
                } />
                <Route render={() =>
                  <div className="deck deck--centered">
                    <div className="center-align">
                      <h1>404 - Page not found</h1>
                      <h2>Whatever you are looking for is not here.</h2>
                    </div>
                  </div>
                } />
              </Switch>
            </BrowserRouter>
          );
        }}
      </Query>
    );
  }
}

export default AuthGate;
