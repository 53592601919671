import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Video extends Component {

  render() {
    return (
      <div {...this.props.attributes}>
        {this.renderVideo()}
      </div>
    );
  }

  renderVideo = () => {
    const { node, isFocused, src } = this.props;
    const video = src
      ? src
      : node.data.get('video');

    const wrapperStyle = {
      position: 'relative',
      outline: isFocused ? '2px solid blue' : 'none',
    }

    const maskStyle = {
      display: isFocused ? 'none' : 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      cursor: 'cell',
      zIndex: 1,
    }

    const iframeStyle = {
      display: 'block',
    }

    return (
      <div style={wrapperStyle}>
        <div style={maskStyle} />
        <iframe
          id="ytplayer"
          type="text/html"
          width="426"
          height="240"
          src={video}
          frameBorder="0"
          style={iframeStyle}
        />
      </div>
    );
  }
}

Video.propTypes = {
  node: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  attributes: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  src: PropTypes.string
}

export default Video;
