import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';

import {
  GET_APPOINTMENTS,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE,
  CREATE_CONTENT_PAGE,
} from './../gql';

import Dropdown from './../components/Dropdown';
import Accordion from './../components/Accordion';
import Throbber from './../components/Throbber';
import ContentPanel from './../components/ContentPanel';
import WysiwygEditor from './../components/WysiwygEditor';
import VisibilityToggle from './../components/VisibilityToggle';
import ErrorBoundary from './../components/ErrorBoundary';

import bridgeIcon from './../../images/icons/bridge.svg';
import roundPlus from './../../images/icons/round-plus-white.svg';

class MyAppointments extends Component {
  constructor() {
    super();
    this.state = {
      appointmentUpdate: {},
      contenPageUpdate: {},
      publishIsUpToDate: null
    }
    this.submitDelay = 500;
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'APPOINTMENT'
      }
    });
  }
  
  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_APPOINTMENTS', 'GET_CONTENT_PAGE'],
        queryRefetchContentType: 'APPOINTMENT',
      }
    });
  }

  createContentPage(createContentPage) {
    const {
      languageCode,
      published,
      app
    } = this.props;

    createContentPage({
      variables: {
        title: '',
        type: 'APPOINTMENT',
        languageCode,
        app,
        published
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitContentUpdate(updateContentPage) {
    const { contenPageUpdate } = this.state;
    updateContentPage({
      variables: contenPageUpdate
    });
    this.setState({
      contenPageUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitAppointmentUpdate(updateAppointment) {
    const { appointmentUpdate } = this.state;
    updateAppointment({
      variables: appointmentUpdate
    });
    this.setState({
      appointmentUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onAppointmentLinkChange(event, id, updateAppointment) {
    const {
      app,
      languageCode
    } = this.props;
    const value = event.target.value;

    updateAppointment({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        category: value || null
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onTopLinkSelectionChange(event, type, setAppointmentTypeLink) {
    const {
      app,
      languageCode
    } = this.props;
    const value = event.target.value;

    setAppointmentTypeLink({
      variables: {
        app,
        languageCode,
        published: false,
        type,
        link: value
      }
    });
  }

  onPageInputChanges(event, id, updateContentPage) {
    const {
      languageCode,
      app
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    clearTimeout(this.contentTimer);
    this.contentTimer = setTimeout(() => this.submitContentUpdate(updateContentPage), this.submitDelay);

    this.setState(prevState => {
      if (prevState.contenPageUpdate && prevState.contenPageUpdate.id === id) {
        return {
          contenPageUpdate: {
            ...this.state.contenPageUpdate,
            [name]: value
          }
        }
      }
      return {
        contenPageUpdate: {
          [name]: value,
          type: 'APPOINTMENT',
          languageCode,
          published: false,
          app,
          id
        }
      }
    });
  }

  onEditorChange(value, id, updateAppointment) {
    const {
      app,
      languageCode
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateAppointment({
        variables: {
          id,
          app,
          published: false,
          languageCode,
          content: value
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }, this.submitDelay);
  }

  onVisibilityChange(event, id, updateAppointment) {
    const {
      app,
      languageCode
    } = this.props;
    updateAppointment({
      variables: {
        id,
        app,
        published: false,
        languageCode,
        hiddenFromDashboard: event === 'hiddenFromDashboard'
          ? true
          : false,
        shownRandomly: event === 'hiddenFromDashboard'
          ? false
          : true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onAppointmentInputChanges(event, id, updateAppointment) {
    const {
      app,
      languageCode
    } = this.props;
    const name = event.target.name;
    const value = isNaN(event.target.value)
      ? event.target.value
      : Number(event.target.value);

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitAppointmentUpdate(updateAppointment), this.submitDelay);

    this.setState(prevState => {
      if (prevState.appointmentUpdate && prevState.appointmentUpdate.id === id) {
        return {
          appointmentUpdate: {
            ...this.state.appointmentUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        appointmentUpdate: {
          id,
          app,
          published: false,
          languageCode,
          [name]: value
        }
      }
    });
  }

  createAppointment(createAppointment, appointments) {
    const {
      languageCode,
      app
    } = this.props;

    const orderIndex = appointments.length > 0
      ? appointments[appointments.length - 1].meta.displayOrder + 1
      : 0;

    createAppointment({
      variables: {
        title: '',
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              },
            ],
          },
        },
        languageCode,
        app,
        displayOrder: orderIndex,
        hiddenFromDashboard: false,
        shownRandomly: true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteAppointment(id, deleteAppointment) {
    if (confirm('Are you sure you want to delete this appointment?')) {
      deleteAppointment({
        variables: {
          id
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  getAppointmentTypes() {
    const { app } = this.props;

    if (app === 'MIN_BABY') {
      return ['DOCTOR', 'VACCINE', 'NURSE', 'OTHER'];
    }
    return ['DOCTOR', 'SCANNING', 'HOSPITAL', 'VACCINE', 'NURSE', 'OTHER'];
  }

  onIsBornChange(id, value, updateFunc) {
    const {
      app,
      languageCode,
    } = this.props;
    const isBorn = value ? false : true;

    updateFunc({
      variables: {
        app,
        languageCode,
        id,
        isBorn,
        published: false,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning" key="notification">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      app,
      languageCode,
      published
    } = this.props;

    return (
      <div className="u-margin--top-large" key="appointments">
        <Query
          query={GET_APPOINTMENTS}
          variables={{
            languageCode,
            app,
            limit: 1000
          }}>
          {({ data, loading, error }) => {
            if (loading)
              return <p className="full-width center-align">Loading...</p>;
            if (error)
              return <p className="full-width center-align">{error.message}</p>;
            const { appointments } = data;

            return (
              <Query
                query={GET_CONTENT_PAGE}
                variables={{
                  type: 'APPOINTMENT',
                  languageCode,
                  app
                }}
              >
                {({ data, loading, error }) => {
                  if (loading)
                    return <p className="full-width center-align">Loading...</p>;
                  if (error)
                    return <p className="full-width center-align">{error.message}</p>;
                  const { contentPage } = data;

                  return (
                    contentPage !== null ? [
                      !contentPage.availability.published &&
                      this.publishNotification(),
                      <Mutation
                        key="content-page"
                        mutation={UPDATE_CONTENT_PAGE}
                        refetchQueries={[{
                          query: GET_CONTENT_PAGE,
                          variables: {
                            type: 'APPOINTMENT',
                            languageCode,
                            app
                          }
                        }]}
                      >
                        {updateContentPage =>
                          <Accordion
                            id={contentPage.id}
                            title={contentPage.title}
                            subtitle={contentPage.subtitle}
                            twoHeaderInputs
                            headerIcon={bridgeIcon}
                            onChanges={event => this.onPageInputChanges(event, contentPage.id, updateContentPage)}>
                            {appointments.edges.length > 0 ? (
                              appointments.edges
                                .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                                .map((appointment) =>
                                  <Mutation
                                    key={appointment.id}
                                    mutation={DELETE_APPOINTMENT}
                                    refetchQueries={[{
                                      query: GET_APPOINTMENTS,
                                      variables: {
                                        languageCode,
                                        app,
                                        limit: 1000
                                      }
                                    }]}
                                  >
                                    {(deleteAppointment, { loading, error }) => (
                                      <div className="relative-block">
                                        {error &&
                                          <p className="full-width center-align">{error.message}</p>
                                        }
                                        {!appointment.availability.published &&
                                          this.publishNotification()
                                        }
                                        <Mutation mutation={UPDATE_APPOINTMENT}
                                          refetchQueries={[{
                                            query: GET_APPOINTMENTS,
                                            variables: {
                                              languageCode,
                                              app,
                                              limit: 1000
                                            }
                                          }]}
                                        >
                                          {updateAppointment => (
                                            <Accordion
                                              id={appointment.id}
                                              title={appointment.title}
                                              enableOrdering
                                              enableWeekRange
                                              rangeFrom={appointment.availability.fromWeek}
                                              rangeTo={appointment.availability.toWeek}
                                              isBorn={appointment.availability.isBorn}
                                              displayOrder={appointment.meta.displayOrder}
                                              siblingAccordions={appointments.edges}
                                              onIsBornChange={(id, value) => this.onIsBornChange(id, value, updateAppointment)}
                                              updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateAppointment)}
                                              deleteAccordion={() => this.deleteAppointment(appointment.id, deleteAppointment)}
                                              onChanges={event => this.onAppointmentInputChanges(event, appointment.id, updateAppointment)}>
                                              <ContentPanel
                                                title={appointment.subtitle}
                                                titleInputName="subtitle"
                                                onChanges={event => this.onAppointmentInputChanges(event, appointment.id, updateAppointment)}>
                                                <ErrorBoundary>
                                                  <WysiwygEditor
                                                    enableArticleLinking
                                                    enableExternalLinking
                                                    enableImageUpload
                                                    enableVimeoEmbed
                                                    languageCode={languageCode}
                                                    app={app}
                                                    published={published}
                                                    editorData={appointment.content}
                                                    onChangeValue={event => this.onEditorChange(event, appointment.id, updateAppointment)}
                                                  />
                                                </ErrorBoundary>
                                                <div className="content-panel__footer">
                                                  <div className="content-panel__form">
                                                    <Dropdown
                                                      optionStartCase
                                                      noneSelection="Link"
                                                      options={this.getAppointmentTypes()}
                                                      currentSelection={appointment.category}
                                                      selectionChange={event => this.onAppointmentLinkChange(event, appointment.id, updateAppointment)}
                                                    />
                                                  </div>
                                                  <div className="content-panel__actions">
                                                    <VisibilityToggle
                                                      parentId={appointment.id}
                                                      onChange={event => this.onVisibilityChange(event, appointment.id, updateAppointment)}
                                                      options={[
                                                        { id: 'shownRandomly', checked: appointment.availability.shownRandomly },
                                                        { id: 'hiddenFromDashboard', checked: appointment.availability.hiddenFromDashboard }
                                                      ]}
                                                    />
                                                  </div>
                                                </div>
                                              </ContentPanel>
                                            </Accordion>
                                          )}
                                        </Mutation>
                                        <Throbber
                                          hidden={!loading}
                                          loadingText="Deleting appointment..."
                                        />
                                      </div>
                                    )}
                                  </Mutation>
                                )
                            ) : (
                              <p className="full-width center-align">No appointments found.</p>
                            )}
                            <Mutation
                              mutation={CREATE_APPOINTMENT}
                              refetchQueries={[{
                                query: GET_APPOINTMENTS,
                                variables: {
                                  languageCode,
                                  app,
                                  limit: 1000
                                }
                              }]}>
                              {(createAppointment, { loading, error }) => (
                                <div className="full-width right-align relative-block u-margin--top-medium">
                                  <Throbber
                                    hidden={!loading}
                                    loadingText="Creating appointment..."
                                  />
                                  {error &&
                                    <p className="full-width center-align">{error.message}</p>
                                  }
                                  <button
                                    className="button button--small button--icon-text"
                                    disabled={loading}
                                    onClick={() => this.createAppointment(createAppointment, appointments.edges)}>
                                    <img src={roundPlus} alt="Add new notification" className="button__icon" />
                                    Add new appointment
                                  </button>
                                </div>
                              )}
                            </Mutation>
                          </Accordion>
                        }
                      </Mutation>
                    ] : (
                      <div>
                        <p className="full-width center-align">
                            No content found for this page
                        </p>
                        <Mutation
                          mutation={CREATE_CONTENT_PAGE}
                          refetchQueries={[{
                            query: GET_CONTENT_PAGE,
                            variables: {
                              type: 'APPOINTMENT',
                              languageCode,
                              app,
                              limit: 1000
                            }
                          }]}>
                          {(createContentPage, { loading, error }) =>
                            <div className="relative-block">
                              {error &&
                                  <p className="full-width center-align">{error.message}</p>
                              }
                              <div className="right-align">
                                <button
                                  className="button button--small button--icon-text"
                                  disabled={loading}
                                  onClick={() => this.createContentPage(createContentPage)}>
                                  <img src={roundPlus} alt="Add" className="button__icon" />
                                    Add new content page
                                </button>
                              </div>
                              <Throbber
                                hidden={!loading}
                                loadingText="Creating content page..."
                              />
                            </div>
                          }
                        </Mutation>
                      </div>
                    )
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </div>
    );
  }
}

MyAppointments.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
}

export default MyAppointments;