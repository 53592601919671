import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_LINKS
} from './../gql';

import Dropdown from './../components/Dropdown';
import Throbber from './../components/Throbber';

import roundPlus from './../../images/icons/round-plus-white.svg';

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      notificationUpdate: {},
      publishIsUpToDate: null
    };
    this.submitDelay = 500;
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'NOTIFICATION'
      }
    });
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_NOTIFICATIONS'],
      }
    });
  }

  submitUpdate(updateNotification) {
    const { notificationUpdate } = this.state;
    updateNotification({
      variables: notificationUpdate
    });
    this.setState({
      notificationUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onInputChange(event, id, updateNotification) {
    const {
      app,
      languageCode
    } = this.props;
    const name = event.target.name;
    const value = isNaN(event.target.value)
      ? event.target.value
      : Number(event.target.value);
    
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitUpdate(updateNotification), this.submitDelay);

    this.setState(prevState => {
      if (prevState.notificationUpdate && prevState.notificationUpdate.id === id) {
        return {
          notificationUpdate: {
            ...this.state.notificationUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        notificationUpdate: {
          app,
          languageCode,
          published: false,
          id,
          [name]: value
        }
      }
    });
  }

  onIsBornChange(id, value, updateFunc) {
    const {
      app,
      languageCode,
    } = this.props;
    const isBorn = value ? false : true;

    updateFunc({
      variables: {
        app,
        languageCode,
        id,
        isBorn,
        published: false,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onSelectionChange(event, id, updateNotification) {
    const {
      app,
      languageCode
    } = this.props;
    clearTimeout(this.selectTimer);
    this.selectTimer = setTimeout(() => this.submitUpdate(updateNotification), this.submitDelay);

    this.setState({
      notificationUpdate: {
        id,
        app,
        languageCode,
        published: false,
        link: event.target.value
      }
    });
  }

  createNotification(createNotification) {
    const {
      app,
      languageCode
    } = this.props;

    createNotification({
      variables: {
        title: '',
        languageCode,
        app,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteNotification(id, deleteNotification) {
    if (confirm('Are you sure you want to delete this notification?')) {
      deleteNotification({
        variables: { id }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      app,
      languageCode
    } = this.props;

    return (
      <div>
        <Query
          query={GET_NOTIFICATIONS}
          variables={{
            languageCode: languageCode,
            app: app,
            limit: 1000
          }}>
          {({ data, loading, error }) => {
            if (loading)
              return <p className="full-width center-align">Loading...</p>;
            if (error)
              return <p className="full-width center-align">{error.message}</p>;
            const { notifications } = data;

            return (
              notifications.edges.length > 0 ? (
                notifications.edges.map(notification =>
                  <Mutation
                    key={notification.id}
                    mutation={DELETE_NOTIFICATION}
                    refetchQueries={[{
                      query: GET_NOTIFICATIONS,
                      variables: {
                        languageCode: languageCode,
                        app: app,
                        limit: 1000
                      }
                    }]}>
                    {(deleteNotification, { loading, error }) => (
                      <div className="deck__view-wrapper">
                        {error &&
                          <p className="full-width center-align">{error.message}</p>
                        }
                        {!notification.availability.published &&
                          this.publishNotification()
                        }
                        <Mutation
                          mutation={UPDATE_NOTIFICATION}
                          refetchQueries={[{
                            query: GET_NOTIFICATIONS,
                            variables: {
                              languageCode: languageCode,
                              app: app,
                              limit: 1000
                            }
                          }]}
                        >
                          {updateNotification =>
                            <div className="form form--no-maxwidth">
                              <div className="form__group form__group--right-align">
                                <form className="radio-group radio-group--eye radio-group--block">
                                  <label className={cx('radio-group__label no-paddingtop', {
                                    'radio-group__label--selected': notification.availability.isBorn
                                  })}>
                                    <input
                                      type="checkbox"
                                      className="radio-group__input"
                                      onChange={() => this.onIsBornChange(notification.id, notification.availability.isBorn, updateNotification)}
                                    />
                                    <span className="radio-group__eye-label">
                                      Show after baby is born
                                    </span>
                                  </label>
                                </form>
                              </div>
                              <div className="form__group">
                                <div className="form__flex-col">
                                  <label htmlFor="title" className="form__label">
                                    <input
                                      type="text"
                                      name="title"
                                      placeholder="Title"
                                      onChange={event => this.onInputChange(event, notification.id, updateNotification)}
                                      defaultValue={notification.title}
                                      className="form__input-field form__input-field--small"
                                    />
                                  </label>
                                </div>
                                <div className="form__flex-col">
                                  <Query
                                    query={GET_LINKS}
                                    variables={{
                                      languageCode: languageCode,
                                      app: app,
                                      limit: 1000
                                    }}>
                                    {({ data, loading, error }) => {
                                      if (loading)
                                        return <p className="full-width center-align">Loading articles...</p>;
                                      if (error)
                                        return <p className="full-width center-align">{error.message}</p>;
                                      const { links } = data;

                                      return (
                                        <Dropdown
                                          noVMargin
                                          noneSelection="Link"
                                          links={links.edges}
                                          currentSelection={notification.link}
                                          selectionChange={event => this.onSelectionChange(event, notification.id, updateNotification)}
                                        />
                                      )
                                    }}
                                  </Query>
                                </div>
                                <div className="form__flex-col form__flex-col--space-between">
                                  <label className="form__label form__label--inline" htmlFor="showInWeek">
                                    <span className="caption">
                                      Show in week
                                    </span>
                                    <input
                                      className="form__input-field form__input-field--small form__input-field--single-entry"
                                      type="number"
                                      name="showInWeek"
                                      defaultValue={notification.showInWeek}
                                      onChange={event => this.onInputChange(event, notification.id, updateNotification)}
                                    />
                                  </label>
                                  <button
                                    className="button button--transparent button--smallest button--delete"
                                    onClick={() => this.deleteNotification(notification.id, deleteNotification)}>
                                    Delete notification
                                  </button>
                                </div>
                              </div>
                              <div className="form__group">
                                <div className="form__flex-col form__flex-col--space-top">
                                  <label htmlFor="body" className="form__label">
                                    <input
                                      type="text"
                                      name="body"
                                      placeholder="Body"
                                      onChange={event => this.onInputChange(event, notification.id, updateNotification)}
                                      defaultValue={notification.body}
                                      className="form__input-field form__input-field--small"
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          }
                        </Mutation>
                        <Throbber 
                          hidden={!loading}
                          loadingText="Deleting notification..."
                        />
                      </div>
                    )}
                  </Mutation>
                )
              ) : (
                <div className="deck__view-wrapper">
                  <p className="full-width center-align">No notifications found.</p>
                </div>
              )
            )
          }}
        </Query>
        <Mutation
          mutation={CREATE_NOTIFICATION}
          refetchQueries={[{
            query: GET_NOTIFICATIONS,
            variables: {
              languageCode: languageCode,
              app: app,
              limit: 1000
            }
          }]}>
          {(createNotification, { loading, error }) => (
            <div className="full-width right-align relative-block u-margin--top-medium">
              <Throbber
                hidden={!loading}
                loadingText="Creating notification..."
              />
              {error &&
                <p className="full-width center-align">{error.message}</p>
              }
              <button
                className="button button--small button--icon-text"
                disabled={loading}
                onClick={() => this.createNotification(createNotification)}>
                <img src={roundPlus} alt="Add new notification" className="button__icon" />
                Add new notification
              </button>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

Notifications.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
}

export default Notifications;