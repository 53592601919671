import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Sequenzer from './Sequenzer';

class ContentPanel extends PureComponent {

  switchOrder = direction => {
    const {
      id,
      displayOrder,
      siblingPanels,
      updateDisplayOrder
    } = this.props;

    siblingPanels.map((targetPanel, index) => {
      if (targetPanel.id === id) {
        const neighbourPanelNewOrder = displayOrder;
        const targetPanelNewOrder = this.getNewDisplayOrder(direction, index);
        const neighbourPanelId = this.getNeighbourPanelId(direction, index);

        updateDisplayOrder(neighbourPanelId, neighbourPanelNewOrder);
        updateDisplayOrder(targetPanel.id, targetPanelNewOrder);
      }
    });
  }

  getNeighbourPanelId = (direction, index) => {
    const { siblingPanels } = this.props;

    if (direction === 'up') {
      return siblingPanels[index - 1].id;
    }
    return siblingPanels[index + 1].id;
  }

  getNewDisplayOrder = (direction, index) => {
    const {
      siblingPanels,
      displayOrder
    } = this.props;
    const newOrderNumber = direction === 'up' 
      ? siblingPanels[index - 1].meta.displayOrder
      : siblingPanels[index + 1].meta.displayOrder;
    const orderDifference = Math.abs(displayOrder - newOrderNumber);

    if (orderDifference === 1) {
      return newOrderNumber;
    }
    return direction === 'up'
      ? displayOrder - 1
      : displayOrder + 1;
  }

  getIndex = range => {
    const { siblingPanels } = this.props;
    const sortedPanels = siblingPanels.sort((a, b) => a.meta.displayOrder - b.meta.displayOrder);

    if (range === 'highest') {
      return sortedPanels[sortedPanels.length - 1].meta.displayOrder;
    }
    return sortedPanels[0].meta.displayOrder;
  }

  render() {
    const {
      id,
      children,
      deletePanel,
      enableWeekRange,
      enableOrdering,
      title,
      rangeFrom,
      rangeTo,
      onChanges,
      titleInputName,
      displayOrder,
      onIsBornChange,
      isBorn,
    } = this.props;

    return (
      <div className="content-panel">
        <header className="content-panel__header">
          {onIsBornChange &&
            <div className="right-align">
              <form className="radio-group radio-group--eye radio-group--block">
                <label
                  className={cx('radio-group__label no-paddingtop', {
                    'radio-group__label--selected': isBorn
                  })}
                >
                  <input
                    type="checkbox"
                    className="radio-group__input"
                    onChange={() => onIsBornChange(id, isBorn)}
                  />
                  <span className="radio-group__eye-label">
                    Show after baby is born
                  </span>
                </label>
              </form>
            </div>
          }
          <div className="content-panel__header-wrapper">
            <div className="content-panel__form">
              <label className="form__label full-width" htmlFor={titleInputName ? titleInputName : 'title'}>
                <input
                  className="form__input-field form__input-field--small"
                  type="text"
                  name={titleInputName ? titleInputName : 'title'}
                  placeholder="Title"
                  defaultValue={title}
                  onChange={event => onChanges(event)}
                />
              </label>
            </div>
            <div className="content-panel__actions">
              {enableWeekRange &&
                <form className="content-panel__time-range">
                  <label className="form__label content-panel__label" htmlFor="fromWeek">
                    <span className="caption">
                      Show in week
                    </span>
                    <input
                      className="form__input-field form__input-field--small content-panel__range-input"
                      type="number"
                      name="fromWeek"
                      defaultValue={rangeFrom}
                      onChange={event => onChanges(event)}
                    />
                  </label>
                  <label className="form__label content-panel__label" htmlFor="toWeek">
                    <span className="caption">
                      to
                    </span>
                    <input
                      className="form__input-field form__input-field--small content-panel__range-input"
                      type="number"
                      name="toWeek"
                      defaultValue={rangeTo}
                      onChange={event => onChanges(event)}
                    />
                  </label>
                </form>
              }
              {deletePanel &&
                <button className="button button--transparent button--smallest button--delete" onClick={() => deletePanel()}>
                  Delete
                </button>
              }
              {enableOrdering &&
                <Sequenzer
                  onMoveDown={() => this.switchOrder('down')}
                  onMoveUp={() => this.switchOrder('up')}
                  index={displayOrder}
                  lowestIndex={this.getIndex('lowest')}
                  highestIndex={this.getIndex('highest')}
                />
              }
            </div>
          </div>
        </header>
        <div className="content-panel__body">
          {children}
        </div>
      </div>
    );
  }
}

ContentPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  deletePanel: PropTypes.func,
  enableWeekRange: PropTypes.bool,
  enableOrdering: PropTypes.bool,
  rangeFrom: PropTypes.number,
  rangeTo: PropTypes.number,
  onChanges: PropTypes.func,
  title: PropTypes.string,
  titleInputName: PropTypes.string,
  displayOrder: PropTypes.number,
  siblingPanels: PropTypes.array,
  updateDisplayOrder: PropTypes.func,
  id: PropTypes.string,
  onIsBornChange: PropTypes.func,
  isBorn: PropTypes.bool,
}

export default ContentPanel;