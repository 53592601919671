import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const RadioGroup = ({
  options,
  currentSelection,
  selectionChange,
  eyeStyle
}) => (
  <form className={cx('radio-group', {
    'radio-group--eye': eyeStyle
  })} noValidate>
    {options.map((option, index) =>
      <label
        className={cx('radio-group__label', {
          'radio-group__label--selected': option === currentSelection
        })}
        htmlFor={option}
        key={index}>
        <input
          className="radio-group__input"
          id={option}
          type="radio"
          name="radio-group"
          value={option}
          checked={option === currentSelection}
          onChange={event => selectionChange(event)}
        />
        <span className={cx({ 'radio-group__eye-label': eyeStyle })}>
          {option}
        </span>
      </label>
    )}
  </form>
);

RadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  currentSelection: PropTypes.string.isRequired,
  selectionChange: PropTypes.func.isRequired,
  eyeStyle: PropTypes.bool
}

export default RadioGroup;