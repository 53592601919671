import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { DELETE_USER, USER_ID } from './../gql';
import { getErrorMessage } from './../utils';
import Throbber from './../components/Throbber';
import checkmarkBadge from './../../images/icons/checkmark-badge.svg';
import warningIcon from './../../images/icons/warning.svg';

class DeleteUser extends PureComponent {
  constructor() {
    super();
    this.state = {
      data: null,
      error: null,
    }
  }

  componentDidMount() {
    const { client } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const userToken = urlParams.get('userToken');
    sessionStorage.setItem('user_token', userToken);

    return client.query({
      query: USER_ID,
    })
      .then(results => {
        const { data: { me } } = results;

        if (me === null) {
          return this.setState({
            error: {
              message: 'User deletion incomplete: ID not found.'
            }
          })
        }

        this.userDeletion(me.id);
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  userDeletion = id => {
    const { client } = this.props;

    return client.mutate({
      mutation: DELETE_USER,
      variables: {
        id
      }
    })
      .then(results => {
        const { data } = results;
        this.setState({ data });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    const { data, error } = this.state;

    if (data) return (
      <div className="deck deck--centered">
        <div className="center-align">
          <img className="badge" src={checkmarkBadge} alt="Deletion completed" />
          <p>Your user account has now been permanently deleted.</p>
        </div>
      </div>
    );

    if (error) return (
      <div className="deck deck--centered">
        <div className="center-align">
          <img className="badge" src={warningIcon} alt="Error" />
          <p>{getErrorMessage(error.message)}</p>
        </div>
      </div>
    );

    return (
      <div className="deck deck--centered">
        <Throbber loadingText="Loading..." relative />
      </div>
    );
  }
}

DeleteUser.propTypes = {
  client: PropTypes.object.isRequired
}

export default withApollo(DeleteUser);