import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import { createUploadLink } from 'apollo-upload-client';

import '../scss/style.scss';
import resolvers, { defaults } from './resolvers';
import AuthGate from './views/AuthGate';

const devMode = process.env.NODE_ENV !== 'production';

// custome error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const httpLink = new HttpLink({
  uri: devMode
    ? process.env.API_URL_PROD
    : process.env.API_URL_PROD,
});

const getToken = () => {
  const authToken = localStorage.getItem('auth_token');
  const userToken = sessionStorage.getItem('user_token');

  if (authToken) {
    return `${authToken}`;
  } else if (userToken) {
    return `${userToken}`
  }
  return '';
}

// adding auth token to header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-token': token,
    },
  };
});

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  resolvers,
  defaults,
});

const uploadLink = createUploadLink({
  uri: devMode
    ? process.env.API_URL_PROD
    : process.env.API_URL_PROD
});

const link = ApolloLink.from([
  errorLink,
  stateLink,
  authLink,
  uploadLink,
  httpLink,
]);

const client = new ApolloClient({
  cache,
  link,
});

render(
  <ApolloProvider client={client}>
    <AuthGate />
  </ApolloProvider>,
  document.querySelector('main')
);