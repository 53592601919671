import gql from 'graphql-tag';

const GET_ENCYCLOPEDIA = gql`
  query Encyclopedia(
    $languageCode: String!,
    $app: App!,
    $fromWeek: Int,
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean,
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    encyclopedia(
      filter: {
        languageCode: $languageCode,
        app: $app,
        fromWeek: $fromWeek,
        toWeek: $toWeek,
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
      },
      pagination: {
        orderBy: $orderBy
        cursor: $cursor
        limit: $limit
        }
    ) {
      edges {
        id,
        title,
        subtitle,
        color,
        icon {
          name
          base64
          mimetype
        }
        availability {
          published
        }
        meta {
          displayOrder
        }
        sections {
          id,
          title,
          subtitle,
          availability {
            published
          }
          meta {
            displayOrder
          }
          subsections {
            id,
            title,
            subtitle,
            content,
            tags,
            meta {
              displayOrder
            }
            availability {
              published
              fromWeek
              toWeek
              hiddenFromDashboard
              shownRandomly
              isBorn
            }
          }
        }
      }
      pageInfo {
        endCursor
        totalPages
        page
        hasPrevPage
        hasNextPage
        prevPage
        nextPage
        totalDocs
      }
    }
  }
`;

const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id)
  }
`;

const CREATE_ARTICLE = gql`
  mutation CreateArticle(
    $title: String!
    $languageCode: String!
    $app: App!
    $published: Boolean
    $displayOrder: Int
  ) {
    createArticle(
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
      title: $title
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $id: ID!
    $title: String
    $subtitle: String
    $color: String
    $displayOrder: Int
    $icon: ID
    $app: App!
    $languageCode: String!
    $published: Boolean
  ) {
    updateArticle(
      id: $id,
      title: $title,
      subtitle: $subtitle,
      color: $color,
      displayOrder: $displayOrder
      icon: $icon
      availability: {
        app: $app
        published: $published
        languageCode: $languageCode
      }
    ) {
      id
    }
  }
`;

const CREATE_SECTION = gql`
  mutation CreateSection (
    $articleId: ID!
    $title: String!
    $subtitle: String
    $languageCode: String!
    $app: App!
    $published: Boolean
    $displayOrder: Int
  ) {
    createSection(
      articleId: $articleId
      title: $title
      subtitle: $subtitle
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
    ) {
      id
    }
  }
`;

const DELETE_SECTION = gql`
  mutation DeleteSection(
    $id: ID!
  ) {
    deleteSection(
      id: $id
    )
  }
`;

const UPDATE_SECTION = gql`
  mutation UpdateSection(
    $id: ID!
    $title: String
    $subtitle: String
    $displayOrder: Int
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    updateSection(
      id: $id
      title: $title
      subtitle: $subtitle
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    ) {
      id
    }
  }
`;

const CREATE_SUBSECTION = gql`
  mutation CreateSubsection(
    $sectionId: ID!
    $title: String!
    $subtitle: String
    $languageCode: String!
    $app: App!
    $published: Boolean
    $content: JSON
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $displayOrder: Int
  ) {
    createSubsection(
      sectionId: $sectionId,
      title: $title,
      subtitle: $subtitle,
      content: $content,
      displayOrder: $displayOrder,
      availability: {
        languageCode: $languageCode,
        app: $app,
        published: $published,
        hiddenFromDashboard: $hiddenFromDashboard,
        shownRandomly: $shownRandomly
      }
    ) {
      id
    }
  }
`;

const DELETE_SUBSECTION = gql`
  mutation DeleteSubsection(
    $id: ID!
  ) {
    deleteSubsection(
      id: $id
    )
  }
`;

const UPDATE_SUBSECTION = gql`
  mutation UpdateSubsection(
    $id: ID!
    $title: String
    $subtitle: String
    $content: JSON
    $displayOrder: Int
    $languageCode: String!
    $app: App!
    $published: Boolean!
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $tags: [String!]
    $isBorn: Boolean
  ) {
    updateSubsection(
      id: $id,
      title: $title,
      subtitle: $subtitle,
      content: $content,
      displayOrder: $displayOrder,
      tags: $tags,
      availability: {
        languageCode: $languageCode,
        app: $app,
        published: $published,
        fromWeek: $fromWeek,
        toWeek: $toWeek,
        hiddenFromDashboard: $hiddenFromDashboard,
        shownRandomly: $shownRandomly
        isBorn: $isBorn
      }
    ) {
      id
    }
  }
`;

const EXPORT_ENCYCLOPEDIA = gql`
  mutation ExportEncyclopediaToCSV(
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    exportEncyclopediaToCSV(
      filter: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    )
  }
`;

export {
  GET_ENCYCLOPEDIA,
  DELETE_ARTICLE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  CREATE_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION,
  CREATE_SUBSECTION,
  DELETE_SUBSECTION,
  UPDATE_SUBSECTION,
  EXPORT_ENCYCLOPEDIA
};