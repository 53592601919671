import gql from 'graphql-tag';

const GET_VIDEO_TUTORIALS = gql`
  query VideoTutorials(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    videoTutorials(
      filter: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
      pagination: {
        orderBy: $orderBy
        cursor: $cursor
        limit: $limit
      }
    ) {
      edges {
        id
        title
        subtitle
        subsections {
          id
          title
          subtitle
          content
          videoTutorial {
            id
            title
            subtitle
          }
          availability {
            published
            fromWeek
            toWeek
            hiddenFromDashboard
            shownRandomly
            isBorn
          }
          meta {
            displayOrder
          }
        }
        availability {
          published
          fromWeek
          toWeek
          hiddenFromDashboard
          shownRandomly
          isBorn
        }
        meta {
          displayOrder
        }
      }
    }
  }
`;

const CREATE_VIDEO_TUTORIAL = gql`
  mutation CreateVideoTutorial(
    $languageCode: String!
    $app: App!
    $title: String!
    $displayOrder: Int
  ) {
    createVideoTutorial(
      title: $title
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
      }
    ) {
      id
    }
  }
`;

const DELETE_VIDEO_TUTORIAL = gql`
  mutation DeleteVideoTutorial(
    $id: ID!
  ) {
    deleteVideoTutorial(
      id: $id
    )
  }
`;

const UPDATE_VIDEO_TUTORIAL = gql`
  mutation UpdateVideoTutorial(
    $id: ID!
    $title: String
    $subtitle: String
    $languageCode: String!
    $app: App!
    $published: Boolean
    $fromWeek: Int
    $toWeek: Int
    $displayOrder: Int
    $isBorn: Boolean
  ) {
    updateVideoTutorial(
      id: $id
      title: $title
      subtitle: $subtitle
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        isBorn: $isBorn
      }
    ) {
      id
    }
  }
`;

const CREATE_VIDEO_SUBSECTION = gql`
  mutation CreateVideoSubsection(
    $title: String!
    $videoTutorialId: ID!
    $app: App!
    $languageCode: String!
    $content: JSON
    $displayOrder: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
  ) {
    createVideoSubsection(
      title: $title
      videoTutorialId: $videoTutorialId
      content: $content
      displayOrder: $displayOrder
      availability: {
        app: $app
        languageCode: $languageCode
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
      }
    ) {
      id
    }
  }
`;

const DELETE_VIDEO_SUBSECTION = gql`
  mutation DeleteVideoSubsection(
    $id: ID!
  ) {
    deleteVideoSubsection(
      id: $id
    )
  }
`;

const UPDATE_VIDEO_SUBSECTION  = gql`
  mutation UpdateVideoSubsection(
    $id: ID!
    $title: String
    $subtitle: String
    $content: JSON
    $app: App!
    $languageCode: String!
    $published: Boolean
    $displayOrder: Int
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $isBorn: Boolean
  ) {
    updateVideoSubsection(
      id: $id
      title: $title
      subtitle: $subtitle
      content: $content
      availability: {
        app: $app
        languageCode: $languageCode
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
        isBorn: $isBorn
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  } 
`;

export {
  GET_VIDEO_TUTORIALS,
  CREATE_VIDEO_TUTORIAL,
  DELETE_VIDEO_TUTORIAL,
  UPDATE_VIDEO_TUTORIAL,
  CREATE_VIDEO_SUBSECTION,
  DELETE_VIDEO_SUBSECTION,
  UPDATE_VIDEO_SUBSECTION
};