import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  GET_SETTINGS,
  GET_ONBOARDING_STEPS,
  GET_ENCYCLOPEDIA,
  GET_CHECKLISTS,
  GET_APPOINTMENTS,
  GET_NOTIFICATIONS,
  GET_STATIC_CONTENT,
  GET_VIDEO_TUTORIALS,
  GET_CONTENT_PAGE,
  PUBLISH,
  GET_DOCTOR_TIPS
} from './../gql';

import Dropdown from './../components/Dropdown';
import Throbber from './../components/Throbber';

class Sidebar extends Component {
  constructor() {
    super();
    this.onAppChange = this.onAppChange.bind(this);
  }

  onAppChange(event, client) {
    const value = event.currentTarget.value;
    localStorage.setItem('app_selected', value);
    client.writeData({
      data: {
        currentApp: value
      }
    });
  }

  publishChanges(publish, currentContentType, client) {
    const {
      predefinedAppSelection,
      languageCode,
      published
    } = this.props;

    publish({
      variables: {
        type: currentContentType,
        app: predefinedAppSelection,
        languageCode,
        published
      }
    });
    client.writeData({
      data: {
        publishDisabled: true
      }
    });
  }

  logOut() {
    localStorage.removeItem('auth_token');
    window.location = '/login';
  }

  goToView(navPath, publishDisabled, unpublishWarning, client) {
    const { history } = this.props;
  
    if (!publishDisabled && unpublishWarning) {
      if (confirm('You have not published your changes yet.\nAre you sure you want to leave this page?')) {
        client.writeData({
          data: {
            publishDisabled: true
          }
        });
        history.push(navPath);
      }
    } else if (!publishDisabled && !unpublishWarning) {
      client.writeData({
        data: {
          publishDisabled: true
        }
      });
      history.push(navPath);
    } else {
      history.push(navPath);
    }
  }

  onPublishComplete(client) {
    client.writeData({
      data: {
        queryRefetchAfterPublish: null,
        queryRefetchContentType: null,
        unpublishWarning: false
      }
    });
  }

  beforeRefetching(queries, contentType, app, languageCode) {
    let refetchQueries = [];
    if (queries === null) {
      return;
    }

    queries.map(queryName => {
      let queryObj = {
        query: this.getQuery(queryName),
        variables: {
          app,
          languageCode,
          limit: 1000,
        }
      }

      if (contentType !== null && queryName === 'GET_CONTENT_PAGE') {
        queryObj.variables.type = contentType
      }
      refetchQueries.push(queryObj)
    });
    return refetchQueries;
  }

  getQuery(query) {
    switch(query) {
    case 'GET_ONBOARDING_STEPS':
      return GET_ONBOARDING_STEPS;
    case 'GET_ENCYCLOPEDIA':
      return GET_ENCYCLOPEDIA;
    case 'GET_CHECKLISTS':
      return GET_CHECKLISTS;
    case 'GET_APPOINTMENTS':
      return GET_APPOINTMENTS;
    case 'GET_NOTIFICATIONS':
      return GET_NOTIFICATIONS;
    case 'GET_STATIC_CONTENT':
      return GET_STATIC_CONTENT;
    case 'GET_VIDEO_TUTORIALS':
      return GET_VIDEO_TUTORIALS;
    case 'GET_CONTENT_PAGE':
      return GET_CONTENT_PAGE;
    case 'GET_DOCTOR_TIPS':
      return GET_DOCTOR_TIPS;
    default:
      return {};
    }
  }

  render() {
    const {
      predefinedAppSelection,
      languageCode
    } = this.props;
    const navlinks = [
      {
        path: '/onboarding',
        title: 'OnBoarding'
      }, {
        path: '/encyclopaedia',
        title: 'Encyclopaedia'
      }, {
        path: '/checklists',
        title: 'Checklists'
      }, {
        path: '/video-tutorials',
        title: 'Video tutorials'
      }, {
        path: '/my-appointments',
        title: 'My appointments'
      }, 
      {
        path: '/doctor-tips',
        title: 'Doctor tips'
      },
      {
        path: '/notifications',
        title: 'Notifications'
      }, {
        path: '/static-pages',
        title: 'Static Pages'
      }, {
        path: '/users',
        title: 'Users'
      }
    ];

    return (
      <Query query={GET_SETTINGS}>
        {({ data, client }) => {
          const {
            currentApp,
            currentContentType,
            publishDisabled,
            unpublishWarning,
            queryRefetchAfterPublish,
            queryRefetchContentType
          } = data;
          const appSelected = currentApp
            ? currentApp
            : predefinedAppSelection;

          return [
            <div key="links">
              <Dropdown
                optionStartCase
                options={['GRAVID', 'FAR', 'MIN_BABY']}
                currentSelection={appSelected}
                selectionChange={event => this.onAppChange(event, client)}
              />
              <ul className="navigation">
                {navlinks.map((navlink, index) =>
                  <li className="navigation__item" key={index}>
                    <button
                      onClick={() => this.goToView(navlink.path, publishDisabled, unpublishWarning, client)}
                      className={cx('navigation__link', {
                        'navigation__link--active': navlink.path === window.location.pathname
                      })}>
                      {navlink.title}
                    </button>
                  </li>
                )}
              </ul>
            </div>,
            <div key="actions">
              <Mutation
                mutation={PUBLISH}
                refetchQueries={this.beforeRefetching(queryRefetchAfterPublish, queryRefetchContentType, appSelected, languageCode)}
                onCompleted={() => this.onPublishComplete(client)}
              >
                {(publish, { loading, error }) => (
                  <div className="relative-block">
                    {error &&
                      <p className="full-width center-align">{error.message}</p>
                    }
                    <button
                      disabled={loading || publishDisabled}
                      className="button button--small button--full-width"
                      onClick={() => this.publishChanges(publish, currentContentType, client)}>
                      Publish
                    </button>
                    <Throbber
                      hidden={!loading}
                      loadingText="Publishing..."
                    />
                  </div>
                )}
              </Mutation>
              <div>
                <button
                  className="button button--small button--full-width"
                  onClick={() => this.logOut()}>
                  Log out
                </button>
              </div>
            </div>
          ];
        }}
      </Query>
    );
  }
}

Sidebar.propTypes = {
  predefinedAppSelection: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(Sidebar);