import gql from 'graphql-tag';

const GET_APPOINTMENTS = gql`
  query Appointments(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $limit: Int
  ) {
    appointments(
      filter: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
      pagination: {
        limit: $limit
      }
    ) {
      edges {
        id
        title
        subtitle
        headline
        content
        category
        link {
          type
          name
          link
        }
        availability {
          published
          fromWeek
          toWeek
          hiddenFromDashboard
          shownRandomly
          isBorn
        }
        meta {
          displayOrder
        }
      }
    }
  }
`;

const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment(
    $title: String!
    $subtitle: String
    $content: JSON
    $type: AppointmentType
    $languageCode: String!
    $app: App!
    $published: Boolean
    $displayOrder: Int
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
  ) {
    createAppointment(
      title: $title
      subtitle: $subtitle
      content: $content
      type: $type
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment(
    $id: ID!
    $title: String
    $subtitle: String
    $content: JSON
    $type: AppointmentType
    $category: AppointmentType
    $languageCode: String!
    $app: App!
    $published: Boolean
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $displayOrder: Int
    $isBorn: Boolean
  ) {
    updateAppointment(
      id: $id
      title: $title
      subtitle: $subtitle
      content: $content
      type: $type
      category: $category
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
        isBorn: $isBorn
      }
      displayOrder: $displayOrder
    ) {
      id
      category
    }
  }
`;

const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment(
    $id: ID!
  ) {
    deleteAppointment(
      id: $id
    )
  }
`;

const GET_APPOINTMENTS_LINK = gql`
  query AppointmentLinks(
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    appointmentLinks(
      filter: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
    ) {
      type
      name
      link
    }
  }
`;

const SET_APPOINTMENT_TYPE_LINK = gql`
  mutation SetAppointmentTypeLink(
    $type: AppointmentType!
    $link: ID!
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    setAppointmentTypeLink(
      type: $type
      link: $link
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
    ) {
      type
      name
      link
    }
  }
`;

export {
  GET_APPOINTMENTS,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS_LINK,
  SET_APPOINTMENT_TYPE_LINK
};