import gql from 'graphql-tag';

const GET_NOTIFICATIONS = gql`
  query Notifications(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $limit: Int
  ) {
    notifications(
      filter: {
        languageCode: $languageCode
        app: $app
        published: $published
      }
      pagination: {
        limit: $limit
      }
    ) {
      edges {
        id,
        title,
        body,
        link,
        showInWeek
        availability {
          published
          isBorn
        }
      }
    }
  }
`;

const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $title: String!
    $languageCode: String!
    $app: App!
    $published: Boolean
  ) {
    createNotification(
      title: $title,
      availability: {
        languageCode: $languageCode,
        app: $app,
        published: $published
      }
    ) {
      id
    }
  }
`;

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification(
    $id: ID!
  ) {
    deleteNotification(
      id: $id
    )
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: ID!
    $title: String
    $body: String
    $link: ID
    $showInWeek: Int
    $published: Boolean
    $languageCode: String!
    $app: App!
    $isBorn: Boolean
  ) {
    updateNotification(
      id: $id,
      title: $title,
      body: $body,
      link: $link
      showInWeek: $showInWeek
      availability: {
        app: $app
        languageCode: $languageCode
        published: $published
        isBorn: $isBorn
      }
    ) {
      id
    }
  }
`;

export {
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION
};