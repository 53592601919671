import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import closeIcon from './../../images/icons/close-white.svg';

class Overlay extends Component {
  constructor() {
    super();
    this.overlayRoot = document.querySelector('.overlay-spawnpoint');
    this.childRoot = document.createElement('div');
    this.escapeOverlay = this.escapeOverlay.bind(this);
  }

  componentDidMount() {
    this.overlayRoot.appendChild(this.childRoot);
    document.body.classList.add('locked');
    document.addEventListener('keydown', this.escapeOverlay, false);
  }

  componentWillUnmount() {
    this.overlayRoot.removeChild(this.childRoot);
    document.body.classList.remove('locked');
    document.removeEventListener('keydown', this.escapeOverlay, false);
  }

  escapeOverlay(event) {
    const { closeOverlay } = this.props;

    if (event.keyCode === 27) { // Esc button keycode
      closeOverlay();
    }
  }

  render() {
    const {
      children,
      closeOverlay
    } = this.props;

    return (
      ReactDOM.createPortal(
        <div className="overlay animation__fadein">
          <div className="overlay__container">
            {children}
            {closeOverlay &&
              <button className="button button--only-icon overlay__close-btn" onClick={() => closeOverlay()}>
                <span className="sr-only">Luk</span>
                <img src={closeIcon} alt="Close" className="button__icon" />
              </button>
            }
          </div>
        </div>,
        this.overlayRoot
      )
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  closeOverlay: PropTypes.func.isRequired,
}

export default Overlay;