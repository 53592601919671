import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  GET_ENCYCLOPEDIA,
  DELETE_ARTICLE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  EXPORT_ENCYCLOPEDIA
} from './../../gql';

import Accordion from './../../components/Accordion';
import PagePagination from './../../components/PagePagination';
import Throbber from './../../components/Throbber';
import Sections from './Sections';

import roundPlus from './../../../images/icons/round-plus-white.svg';
import bookmarkIcon from './../../../images/icons/bookmark-blue.svg';

class Encyclopaedia extends Component {
  constructor() {
    super();
    this.state = {
      articleUpdate: {},
      publishIsUpToDate: null
    };
    this.articles = [];
    this.activatePublishButton = this.activatePublishButton.bind(this);
    this.activateUnpublishedAlert = this.activateUnpublishedAlert.bind(this);
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'ARTICLE'
      }
    });
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  deleteArticle(id, deleteArticle) {
    if (confirm('Are you sure you want to delete this article?')) {
      deleteArticle({
        variables: { id }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  createArticle(createArticle, articles) {
    const {
      app,
      languageCode
    } = this.props;

    const orderIndex = articles.length > 0
      ? articles[articles.length - 1].meta.displayOrder + 1
      : 0;

    createArticle({
      variables: {
        title: '',
        subtitle: '',
        languageCode: languageCode,
        app: app,
        displayOrder: orderIndex
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_ENCYCLOPEDIA'],
      }
    });
  }

  submitUpdate(updateArticle) {
    const { articleUpdate } = this.state;
    updateArticle({
      variables: articleUpdate
    });
    this.setState({
      articleUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onChangeHandle(event, updateArticle, id) {
    const {
      app,
      languageCode,
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.submitUpdate(updateArticle), 500);

    this.setState(prevState => {
      if (prevState.articleUpdate && prevState.articleUpdate.id === id) {
        return {
          articleUpdate: {
            ...this.state.articleUpdate,
            id: id,
            [name]: value
          }
        };
      }
      return {
        articleUpdate: {
          languageCode,
          app,
          published: false,
          id,
          [name]: value
        }
      };
    });
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  downloadEncyclopediaFile(url) {
    // download file from url 
    const pathname = new URL(url).pathname;
    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);

    // eslint-disable-next-line no-console
    console.log({url})

    const link = document.createElement('a');
    link.href = url;
    link.download = filename; 
    link.click();
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const { app, languageCode, published } = this.props;

    return (
      <div>
        <div className='right-align u-margin--bottom-large'>
          <Mutation
            update={(cache, { data }) => {
              this.downloadEncyclopediaFile(data.exportEncyclopediaToCSV)                
            }}
            mutation={EXPORT_ENCYCLOPEDIA}
            variables={{
              languageCode: languageCode,
              app: app,
              published: published
            }}
          >
            {(exportEncyclopediaToCSV, { loading }) => (
              <div className='relative-block right-align'>
                <button
                  onClick={() => exportEncyclopediaToCSV()}
                  className='button button--link'
                >
                  Download entire encyclopedia
                </button>
                <Throbber
                  hidden={!loading}
                  loadingText='Downloading...'
                  alignRight
                />
              </div>
            )}
          </Mutation>
        </div>
        <Query
          query={GET_ENCYCLOPEDIA}
          variables={{
            languageCode: languageCode,
            app: app,
            limit: 1000,
          }}
        >
          {({ data, loading, error, fetchMore }) => {
            if (loading)
              return <p className='full-width center-align'>Loading...</p>;
            if (error)
              return <p className='full-width center-align'>{error.message}</p>;
            const { encyclopedia } = data;
            this.articles = encyclopedia.edges;
            return (
              <div>
                {encyclopedia.edges.length > 0 ? (
                  <div>
                    {encyclopedia.edges
                      .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                      .map(article => (
                        <Mutation
                          key={article.id}
                          mutation={DELETE_ARTICLE}
                          refetchQueries={[
                            {
                              query: GET_ENCYCLOPEDIA,
                              variables: {
                                languageCode: languageCode,
                                app: app,
                                limit: 1000,
                              }
                            }
                          ]}
                        >
                          {(deleteArticle, { loading, error }) => (
                            <div className='relative-block'>
                              {error && (
                                <p className='full-width center-align'>
                                  {error.message}
                                </p>
                              )}
                              {!article.availability.published &&
                                this.publishNotification()
                              }
                              <Mutation mutation={UPDATE_ARTICLE}>
                                {updateArticle => (
                                  <Accordion
                                    showColorPicker
                                    twoHeaderInputs
                                    enableOrdering
                                    enableChangeIcon
                                    id={article.id}
                                    title={article.title}
                                    subtitle={article.subtitle}
                                    titleColor={article.color}
                                    headerIcon={article.icon || bookmarkIcon}
                                    displayOrder={article.meta.displayOrder}
                                    siblingAccordions={encyclopedia.edges}
                                    updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateArticle)}
                                    onChanges={event =>
                                      this.onChangeHandle(
                                        event,
                                        updateArticle,
                                        article.id
                                      )
                                    }
                                    deleteAccordion={() =>
                                      this.deleteArticle(article.id, deleteArticle)
                                    }
                                  >
                                    <Sections
                                      articleId={article.id}
                                      sections={article.sections}
                                      languageCode={languageCode}
                                      published={published}
                                      app={app}
                                      activatePublishButton={
                                        this.activatePublishButton
                                      }
                                      activateUnpublishedAlert={
                                        this.activateUnpublishedAlert
                                      }
                                    />
                                  </Accordion>
                                )}
                              </Mutation>
                              <Throbber
                                hidden={!loading}
                                loadingText='Deleting article...'
                              />
                            </div>
                          )}
                        </Mutation>
                      ))}
                  </div>
                ) : (
                  <p className='full-width center-align'>No articles found.</p>
                )}
                <PagePagination
                  pageInfo={encyclopedia.pageInfo}
                  onPageClick={endCursor =>
                    fetchMore({
                      query: GET_ENCYCLOPEDIA,
                      variables: {
                        languageCode: languageCode,
                        app: app,
                        limit: 1000,
                        cursor: endCursor
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        const previous = previousResult.encyclopedia;
                        const newArticles = fetchMoreResult.encyclopedia.edges;

                        const newCursor =
                          fetchMoreResult.encyclopedia.pageInfo.endCursor;

                        // this is the updated data, and it must keep the same structure as the original data
                        return {
                          cursor: newCursor,
                          encyclopedia: {
                            edges: [...newArticles],
                            __typename: previous.__typename,
                            pageInfo: {
                              endCursor: newCursor,
                              ...fetchMoreResult.encyclopedia.pageInfo,
                              __typename: previous.pageInfo.__typename
                            }
                          }
                        };
                      }
                    })
                  }
                />
              </div>
            )
          }}
        </Query>
        <Mutation
          mutation={CREATE_ARTICLE}
          refetchQueries={[
            {
              query: GET_ENCYCLOPEDIA,
              variables: {
                languageCode: languageCode,
                app: app,
                limit: 1000,
              }
            }
          ]}
        >
          {(createArticle, { loading, error }) => (
            <div className='full-width right-align relative-block'>
              <Throbber hidden={!loading} loadingText='Creating article...' />
              {error && (
                <p className='full-width center-align'>{error.message}</p>
              )}
              <button
                className='button button--small button--icon-text'
                disabled={loading}
                onClick={() =>
                  this.createArticle(createArticle, this.articles)
                }
              >
                <img
                  src={roundPlus}
                  alt='Add new article'
                  className='button__icon'
                />
                Add new article
              </button>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

Encyclopaedia.propTypes = {
  app: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  languageCode: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
};

export default Encyclopaedia;
