import gql from 'graphql-tag';

const GET_LINKS = gql`
  query Links(
    $languageCode: String!
    $app: App!
    $limit: Int
    $cursor: String
  ) {
    links(
      pagination: { limit: $limit, cursor: $cursor }
      filter: { languageCode: $languageCode, app: $app }
    ) {
      edges {
        contentID
        contentType
        contentTitle
        contentTypeNew
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export { GET_LINKS };
