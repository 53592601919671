import gql from 'graphql-tag';

const GET_SUPPORTED_LANGUAGES = gql`
  query AvailableLanguages {
    availableLanguages
  }
`;

const GET_SINGLE_LANGUAGE = gql`
  query Language(
    $code: String!
  ) {
    language(
      code: $code
    ) {
      id
      name
    }
  }
`;

const CREATE_LANGUAGE = gql`
  mutation CreateLanguage(
    $name: String!
    $code: String!
  ) {
    createLanguage(
      name: $name
      code: $code
    ) {
      id
      name
    }
  }
`;

const DELETE_LANGUAGE = gql`
  mutation DeleteLanguage(
    $id: ID!
  ) {
    deleteLanguage(
      id: $id
    )
  }
`;

const DUPLICATE_LANGUAGE = gql`
  mutation DuplicateLanguage(
    $from: ID!
    $to: ID!
  ) {
    duplicateLanguage(
      from: $from
      to: $to
    )
  }
`;

export {
  GET_SUPPORTED_LANGUAGES,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  GET_SINGLE_LANGUAGE,
  DUPLICATE_LANGUAGE
};
