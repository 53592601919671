import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import closeIcon from './../../images/icons/close-blue.svg';

const ENTER_KEY = 13;
const COMMA_KEY = 188;

class Tags extends PureComponent {
  constructor() {
    super();
    this.state = {
      tags: [],
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    const { tags } = this.props;
    this.setState({
      tags: tags
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    const { tags } = this.state;

    if (prevProps.tags !== tags && prevState.tags !== tags) {
      onChange(tags);
    }
  }

  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();
    tag = tag.replace(/,/g, '');

    if (!tag) {
      return;
    }

    this.setState({
      tags: [...tags, tag],
      value: ''
    });
  }

  removeTag(index) {
    const { tags } = this.state;
    this.setState({
      tags: [
        ...tags.slice(0, index),
        ...tags.slice(index + 1)
      ]
    });
  }

  render() {
    const { tags, value } = this.state;
    return (
      <div className="tags">
        <div className="tags__wrapper">
          <ul className="tags__list">
            {tags.map((tag, i) => (
              <li key={tag + i} className="tags__item">
                {tag}
                <button className="tags__remove-item" title="Remove" onClick={() => this.removeTag(i)}>
                  <img src={closeIcon} alt="Remove" />
                </button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            placeholder="Insert tag"
            value={value}
            onChange={this.handleChange}
            className="tags__input"
            onKeyUp={this.handleKeyUp}
          />
        </div>
      </div>
    );
  }
}

Tags.propTypes = {
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired
}

export default Tags;