import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'; 
import { Mutation } from 'react-apollo';
import {
  GET_CHECKLISTS,
  CREATE_SUBCHECKLIST,
  DELETE_SUBCHECKLIST,
  UPDATE_SUBCHECKLIST,
} from './../../gql';
import Accordion from './../../components/Accordion';
import WysiwygEditor from './../../components/WysiwygEditor';
import ErrorBoundary from './../../components/ErrorBoundary';
import ChecklistEditor from './../../components/ChecklistEditor';
import Throbber from './../../components/Throbber';
import roundPlus from './../../../images/icons/round-plus-white.svg';

class Subchecklist extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subchecklistUpdate: {},
      publishIsUpToDate: null
    }
    this.submitDelay = 500;
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  publishNotification() {
    const { activatePublishButton } = this.props;
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  deleteSubChecklist(id, deleteSubChecklist) {
    const {
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;

    if (confirm('Are you sure you want to delete this checklist?')) {
      deleteSubChecklist({
        variables: {
          id
        }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }
  }

  onChecklistChange(value) {
    const bulletItems = [];

    const filterChecklistEditorValues = value => {
      const { nodes, type } = value;
  
      if (type === 'bulleted-list') {
        nodes.map(item => {
          const {
            type: itemType,
            nodes: itemNodes,
          } = item;
  
          if (itemType === 'list-item') {
            itemNodes.map(node => {
              const { leaves } = node;
              leaves.map(leaf => bulletItems.push(leaf.text));
            });
          }
        })
      }
  
      if (nodes) {
        nodes.map(node => filterChecklistEditorValues(node));
      }
      return bulletItems;
    }
    return filterChecklistEditorValues(value.document);
  }

  updateDisplayOrder(id, order, updateSubChecklist) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;

    updateSubChecklist({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  updateListItem(value, id, updateSubChecklist) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;

    clearTimeout(this.listItemTimer);
    this.listItemTimer = setTimeout(() => {
      const items = this.onChecklistChange(value);
      updateSubChecklist({
        variables: {
          items,
          id,
          app,
          languageCode,
          published: false
        }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }, this.submitDelay);
  }

  createSubChecklist(createSubChecklist, subChecklists, checklistId) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;

    const orderIndex = subChecklists.length > 0
      ? subChecklists[subChecklists.length - 1].meta.displayOrder + 1
      : 0;

    createSubChecklist({
      variables: {
        checklist: checklistId,
        items: [],
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              },
            ],
          },
        },
        languageCode: languageCode,
        app: app,
        displayOrder: orderIndex,
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  onEditorChange(value, id, updateSubChecklist) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert,
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateSubChecklist({
        variables: {
          id,
          app,
          languageCode,
          content: value,
          published: false
        }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }, this.submitDelay);
  }

  render() {
    const {
      checklistId,
      subChecklists,
      languageCode,
      published,
      app,
    } = this.props;

    return (
      <div>
        <div className="deck deck--no-flex">
          {subChecklists.length > 0 ? (
            subChecklists
              .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
              .map(subChecklist =>
                <Mutation
                  key={subChecklist.id}
                  mutation={DELETE_SUBCHECKLIST}
                  refetchQueries={[{
                    query: GET_CHECKLISTS,
                    variables: {
                      languageCode: languageCode,
                      app: app,
                      limit: 1000
                    }
                  }]}
                >
                  {(deleteSubChecklist, { loading, error })  => (
                    <div className="relative-block">
                      {error &&
                        <p className="full-width center-align">{error.message}</p>
                      }
                      {!subChecklist.availability.published &&
                        this.publishNotification()
                      }
                      <Mutation mutation={UPDATE_SUBCHECKLIST}
                        refetchQueries={[{
                          query: GET_CHECKLISTS,
                          variables: {
                            languageCode,
                            app,
                            limit: 1000
                          }
                        }]}
                      >
                        {updateSubChecklist => (
                          <Accordion
                            id={subChecklist.id}
                            displayOrder={subChecklist.meta.displayOrder}
                            siblingAccordions={subChecklists}
                            updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateSubChecklist)}
                            enableOrdering
                            deleteAccordion={() => this.deleteSubChecklist(subChecklist.id, deleteSubChecklist)}>
                            <div>
                              <ErrorBoundary>
                                <WysiwygEditor
                                  enableArticleLinking
                                  enableExternalLinking
                                  enableImageUpload
                                  enableVimeoEmbed
                                  languageCode={languageCode}
                                  app={app}
                                  published={published}
                                  editorData={subChecklist.content}
                                  onChangeValue={event => this.onEditorChange(event, subChecklist.id, updateSubChecklist)}
                                />
                              </ErrorBoundary>
                              <p className="uppercase">
                                <i>Checklist</i>
                              </p>
                              <ErrorBoundary>
                                <ChecklistEditor
                                  editorData={subChecklist.items}
                                  onChangeValue={event => this.updateListItem(event, subChecklist.id, updateSubChecklist)}
                                />
                              </ErrorBoundary>
                            </div>
                          </Accordion>
                        )}
                      </Mutation>
                      <Throbber
                        hidden={!loading}
                        loadingText="Deleting subchecklist..."
                      />
                    </div>
                  )}
                </Mutation>
              )
          ) : (
            <div className="center-align">
              <p>No checklists found.</p>
            </div>
          )}
        </div>
        <Mutation
          mutation={CREATE_SUBCHECKLIST}
          refetchQueries={[{
            query: GET_CHECKLISTS,
            variables: {
              languageCode,
              app,
              limit: 1000,
            }
          }]}>
          {(createSubChecklist, { loading, error }) => (
            <div className="full-width right-align relative-block u-margin--top-medium">
              <Throbber
                hidden={!loading}
                loadingText="Creating subchecklist..."
              />
              {error &&
                <p className="full-width center-align">{error.message}</p>
              }
              <button
                className="button button--small button--icon-text"
                disabled={loading}
                onClick={() => this.createSubChecklist(createSubChecklist, subChecklists, checklistId)}>
                <img src={roundPlus} alt="Add new checklist" className="button__icon" />
                Add new checklist
              </button>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

Subchecklist.propTypes = {
  checklistId: PropTypes.string.isRequired,
  subChecklists: PropTypes.array.isRequired,
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool,
  app: PropTypes.string.isRequired,
  activatePublishButton: PropTypes.func.isRequired,
  activateUnpublishedAlert: PropTypes.func.isRequired,
};

export default Subchecklist;