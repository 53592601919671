import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Pagination extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false
    }
  }

  componentDidUpdate(prevProps) {
    const { disableLoadMore } = this.props;

    if ((!prevProps.disableLoadMore && disableLoadMore) || (prevProps.disableLoadMore && disableLoadMore)) {
      clearTimeout(this.timer);
      this.setState({
        disabled: true
      });
    }

    if ((prevProps.disableLoadMore && !disableLoadMore) || (!prevProps.disableLoadMore && !disableLoadMore)) {
      this.timer = setTimeout(() => {
        this.setState({
          disabled: false
        });
      }, 1000);
    }
  }

  render() {
    const { disabled } = this.state;
    const {
      endCursor,
      onLoadMore,
    } = this.props;

    return (
      <div className="pagination">
        <button
          className="button button__smallest"
          disabled={disabled}
          onClick={() => onLoadMore(endCursor)}
        >
          Load more
        </button>
      </div>
    );
  }
}

Pagination.propTypes = {
  disableLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  endCursor: PropTypes.string
};

export default Pagination;
