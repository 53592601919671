import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import {
  GET_ENCYCLOPEDIA,
  CREATE_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION
} from './../../gql';

import Accordion from './../../components/Accordion';
import Throbber from './../../components/Throbber';
import Subsections from './Subsections';

import roundPlus from './../../../images/icons/round-plus-white.svg';

class Sections extends Component {
  constructor() {
    super();
    this.state = {
      sectionUpdate: {},
      publishIsUpToDate: null
    }
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  createSection(createSection, sections) {
    const {
      articleId,
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    const orderIndex = sections.length > 0
      ? sections[sections.length - 1].meta.displayOrder + 1
      : 0;

    createSection({
      variables: {
        title: '',
        subtitle: '',
        articleId: articleId,
        languageCode: languageCode,
        app: app,
        displayOrder: orderIndex
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  submitUpdate(updateSection) {
    const {
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;
    const { sectionUpdate } = this.state;
    updateSection({
      variables: sectionUpdate
    });
    this.setState({
      sectionUpdate: {}
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  onChangeHandle(event, id, updateSection) {
    const {
      app,
      languageCode,
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.submitUpdate(updateSection), 500);

    this.setState(prevState => {
      if (prevState.sectionUpdate && prevState.sectionUpdate.id === id) {
        return {
          sectionUpdate: {
            ...this.state.sectionUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        sectionUpdate: {
          app,
          languageCode,
          published: false,
          id,
          [name]: value
        }
      }
    });
  }

  deleteSection(id, deleteSection) {
    const {
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;
    if (confirm('Are you sure you want to delete this section?')) {
      deleteSection({
        variables: { id }
      });
      activatePublishButton();
      activateUnpublishedAlert();
    }
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    activatePublishButton();
    activateUnpublishedAlert();
  }

  publishNotification() {
    const { activatePublishButton } = this.props;
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      sections,
      app,
      languageCode,
      published,
      activatePublishButton,
      activateUnpublishedAlert
    } = this.props;

    return (
      <div>
        {sections
          .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
          .map(section =>
            <Mutation
              key={section.id}
              mutation={DELETE_SECTION}
              refetchQueries={[{
                query: GET_ENCYCLOPEDIA,
                variables: {
                  languageCode: languageCode,
                  app: app
                }
              }]}
            >
              {(deleteSection, { loading, error })  => (
                <div className="relative-block">
                  {error &&
                    <p className="full-width center-align">{error.message}</p>
                  }
                  {!section.availability.published &&
                    this.publishNotification()
                  }
                  <Mutation
                    mutation={UPDATE_SECTION}
                    refetchQueries={[
                      {
                        query: GET_ENCYCLOPEDIA,
                        variables: {
                          languageCode: languageCode,
                          app: app
                        }
                      }
                    ]}
                  >
                    {updateSection => 
                      <Accordion
                        id={section.id}
                        title={section.title}
                        subtitle={section.subtitle}
                        twoHeaderInputs
                        enableOrdering
                        displayOrder={section.meta.displayOrder}
                        siblingAccordions={sections}
                        updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateSection)}
                        deleteAccordion={() => this.deleteSection(section.id, deleteSection)}
                        onChanges={event => this.onChangeHandle(event, section.id, updateSection)}>
                        <Subsections
                          sectionId={section.id}
                          subsections={section.subsections}
                          languageCode={languageCode}
                          published={published}
                          app={app}
                          activatePublishButton={activatePublishButton}
                          activateUnpublishedAlert={activateUnpublishedAlert}
                        />
                      </Accordion>
                    }
                  </Mutation>
                  <Throbber 
                    hidden={!loading}
                    loadingText="Deleting section..."
                  />
                </div>
              )}
            </Mutation>
          )}
        <Mutation
          mutation={CREATE_SECTION}
          refetchQueries={[{
            query: GET_ENCYCLOPEDIA,
            variables: {
              languageCode: languageCode,
              app: app
            }
          }]}>
          {(createSection, { loading, error }) => (
            <div className="full-width right-align relative-block">
              <Throbber
                hidden={!loading}
                loadingText="Creating section..."
              />
              {error &&
                <p className="full-width center-align">{error.message}</p>
              }
              <button
                className="button button--small button--icon-text"
                disabled={loading}
                onClick={() => this.createSection(createSection, sections)}>
                <img src={roundPlus} alt="Add new section" className="button__icon" />
                Add new section
              </button>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  activatePublishButton: PropTypes.func.isRequired,
  activateUnpublishedAlert: PropTypes.func.isRequired
}

export default Sections;