import gql from 'graphql-tag';

const GET_IMAGES = gql`
  query Images($limit: Int, $cursor: String) {
    images(pagination: { limit: $limit, cursor: $cursor }) {
      edges {
        id
        name
        path
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const SINGLE_UPLOAD = gql`
  mutation SingleUpload($image: Upload!) {
    singleUpload(image: $image) {
      id
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation DeleteImage($id: ID!) {
    deleteImage(id: $id)
  }
`;

export { GET_IMAGES, SINGLE_UPLOAD, DELETE_IMAGE };
