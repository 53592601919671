import gql from 'graphql-tag';

const GET_USERS = gql`
  query Users (
    $emailSearch: String
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    users(
      emailSearch: $emailSearch,
      pagination: {
        orderBy: $orderBy,
        cursor: $cursor,
        limit: $limit
      }
    ) {
      edges {
        id
        email
        role
        emailVerified,
        lastActivity,
        createdAt
        babies {
          id
          name
          journal {
            id
          }
        }
      }
      pageInfo {
				endCursor
				totalPages
				page
				hasPrevPage
				hasNextPage
				prevPage
				nextPage
				totalDocs
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser(
    $id: ID!
  ) {
    deleteUser(
      id: $id
    )
  }
`;

const DELETE_BABY = gql`
  mutation DeleteBaby(
    $id: ID!
  ) {
    deleteBaby(
      id: $id
    )
  }
`;

const EXPORT_JOURNAL_TO_PDF = gql` 
  mutation ExportJournalToPDF(
    $app: App!
    $languageCode: String!
    $id: ID
    $babyId: ID
  ) {
    exportJournalToPDF(
      id: $id
      babyId: $babyId
      appCode: $app
      languageCode: $languageCode
    )
  }
`;

const UPDATE_LAST_ACTIVITY = gql`
  mutation UpdateLastActivity {
    updateLastActivity
  }
`;

const USER_ID = gql`
  query Me {
    me {
      id
    }
  }
`;

export {
  GET_USERS,
  DELETE_USER,
  DELETE_BABY,
  EXPORT_JOURNAL_TO_PDF,
  UPDATE_LAST_ACTIVITY,
  USER_ID,
};
