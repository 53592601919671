import gql from 'graphql-tag';

const GET_SETTINGS = gql`
  {
    currentLanguageCode @client
    currentApp @client
    currentContentType @client
    publishDisabled @client
    queryRefetchAfterPublish @client
    queryRefetchContentType @client
    unpublishWarning @client
  }
`;

const PUBLISH = gql`
  mutation Publish(
    $type: ContentType!
    $languageCode: String!
    $published: Boolean
    $app: App!
  ) {
    publish(
      type: $type
      filter: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
    )
  }
`;

const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword(
    $passwordResetToken: String!
    $newPassword: String!
  ) {
    resetUserPassword(
      passwordResetToken: $passwordResetToken
      newPassword: $newPassword
    )
  }
`;

export {
  GET_SETTINGS,
  PUBLISH,
  RESET_USER_PASSWORD
};