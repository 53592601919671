import _ from 'lodash';

const getErrorMessage = (error) => {
  let msgs = 'An error occured.';

  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    msgs = error;
  }

  const { graphQLErrors } = error;

  if (error.message && !graphQLErrors) {
    return error.message;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.map((e) => (msgs = e.message));
  }

  return _.trim(msgs, 'GraphQL error:');
};

export {
  getErrorMessage
}