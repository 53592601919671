import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';

import {
  CREATE_LANGUAGE,
  GET_SUPPORTED_LANGUAGES,
  GET_SINGLE_LANGUAGE,
  DUPLICATE_LANGUAGE
} from './../gql';

import Throbber from './../components/Throbber';

class AddLanguage extends PureComponent {

  createLanguage(createLanguage) {
    const nativeName = prompt('Enter the native name of the language. For example: "Français".');
    if (!nativeName) return;

    const codeName = prompt('Enter a shorthand ID for the language.\n\nFor example if the native name of the language is "Français", the shorthand ID would be "FR".');
    if (!codeName) return;

    createLanguage({
      variables: {
        name: nativeName,
        code: codeName
      }
    });
  }

  render() {
    return (
      <Mutation
        mutation={CREATE_LANGUAGE}
        refetchQueries={[{
          query: GET_SUPPORTED_LANGUAGES,
        }]}>
        {(createLanguage, { data, loading, error }) => {
          const newLanguage = data
            ? data.createLanguage
            : data;

          return [
            <button
              key="cta-button"
              disabled={loading}
              onClick={() => this.createLanguage(createLanguage)}
              className="button button--link no-vmargin">
              +ADD
            </button>,
            <div key="duplicate">
              {newLanguage &&
                <Query
                  query={GET_SINGLE_LANGUAGE}
                  variables={{
                    code: 'EN',
                  }}>
                  {({ data, loading, error }) => {
                    if (loading)
                      return <p>Loading...</p>;
                    if (error)
                      return <p>{error.message}</p>;
                    const { language } = data;

                    return (
                      <Mutation
                        mutation={DUPLICATE_LANGUAGE}
                        refetchQueries={[{
                          query: GET_SUPPORTED_LANGUAGES,
                        }]}>
                        {(duplicateLanguage, {loading, error}) => {

                          return [
                            <button
                              key="button"
                              onClick={() => duplicateLanguage({
                                variables: {
                                  from: language.id,
                                  to: newLanguage.id
                                }
                              })}
                              className="button button--link no-vmargin">
                              Copy content from English to {newLanguage.name}?
                            </button>,
                            <Throbber
                              key="throbber"
                              hidden={!loading}
                              loadingText="Copying content..."
                            />,
                            error &&
                              <p key="error">{error.message}</p>
                          ];
                        }}
                      </Mutation>
                    );
                  }}
                </Query>
              }
            </div>,
            <div key="loading">
              {loading &&
                <div className="u-loader__rotate u-loader__rotate--blue">
                  <span className="sr-only">
                    Creating language...
                  </span>
                </div>
              }
            </div>,
            <div key="error">
              {error &&
                <p className="full-width center-align">{error.message}</p>
              }
            </div>
          ]
        }}
      </Mutation>
    );
  }
}

export default AddLanguage;