import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  GET_STATIC_CONTENT,
  UPDATE_STATIC_CONTENT,
  CREATE_STATIC_CONTENT,
  DELETE_STATIC_CONTENT
} from './../gql';

import ContentPanel from '../components/ContentPanel';
import WysiwygEditor from '../components/WysiwygEditor';
import Throbber from './../components/Throbber';
import ErrorBoundary from './../components/ErrorBoundary';

import roundPlus from './../../images/icons/round-plus-white.svg';

class StaticPages extends Component {
  constructor() {
    super();
    this.state = {
      contentUpdate: {},
      publishIsUpToDate: null
    };
    this.submitDelay = 500;
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'STATIC_PAGE'
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_STATIC_CONTENT'],
      }
    });
  }

  submitUpdate(updateStaticContent) {
    const { contentUpdate } = this.state;
    updateStaticContent({
      variables: contentUpdate
    });
    this.setState({
      contentUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onChangeHandle(event, id, updateStaticContent) {
    const {
      app,
      languageCode
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitUpdate(updateStaticContent), this.submitDelay);

    this.setState(prevState => {
      if (prevState.contentUpdate && prevState.contentUpdate.id === id) {
        return {
          contentUpdate: {
            ...this.state.contentUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        contentUpdate: {
          id,
          app,
          published: false,
          languageCode,
          [name]: value
        }
      }
    });
  }

  onChangeEditor(value, id, updateStaticContent) {
    const {
      app,
      languageCode,
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateStaticContent({
        variables: {
          id,
          app,
          published: false,
          languageCode,
          content: value
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }, this.submitDelay);
  }

  onPrivacyPolicyChange(id, isPrivacyPolicy, onPrivacyPolicyChange) {
    const {
      app,
      languageCode
    } = this.props;

    onPrivacyPolicyChange({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        isPrivacyPolicy: isPrivacyPolicy ? false : true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  createStaticContent(createStaticContent, staticPages) {
    const {
      app,
      languageCode,
    } = this.props;

    const orderIndex = staticPages.length > 0
      ? staticPages[staticPages.length - 1].meta.displayOrder + 1
      : 0;

    createStaticContent({
      variables: {
        title: '',
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              }
            ]
          }
        },
        published: false,
        languageCode,
        app,
        displayOrder: orderIndex
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteContentPage(deleteStaticContent, id) {
    if (confirm('Are you sure you want to delete this page content?')) {
      deleteStaticContent({
        variables: {
          id
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      languageCode,
      app,
      published
    } = this.props;

    return (
      <div>
        <Query
          query={GET_STATIC_CONTENT}
          variables={{
            languageCode: languageCode,
            app: app,
            limit: 1000
          }}>
          {({ data, loading, error }) => {
            if (loading)
              return <p className="full-width center-align">Loading...</p>;
            if (error)
              return <p className="full-width center-align">{error.message}</p>;
            const { staticContent } = data;

            return [
              staticContent.edges.length > 0 ? (
                staticContent.edges
                  .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                  .map(page =>
                    <div className="u-margin--bottom-large" key={page.id}>
                      <Mutation
                        mutation={DELETE_STATIC_CONTENT}
                        refetchQueries={[{
                          query: GET_STATIC_CONTENT,
                          variables: {
                            languageCode: languageCode,
                            app: app,
                            limit: 1000
                          }
                        }]}
                      >
                        {(deleteStaticContent, { loading, error }) => (
                          <Mutation
                            mutation={UPDATE_STATIC_CONTENT}
                            refetchQueries={[{
                              query: GET_STATIC_CONTENT,
                              variables: {
                                languageCode: languageCode,
                                app: app,
                                limit: 1000
                              }
                            }]}
                          >
                            {updateStaticContent => (
                              <div className="relative-block">
                                {error &&
                                  <p className="full-width center-align">{error.message}</p>
                                }
                                {!page.availability.published &&
                                  this.publishNotification()
                                }
                                <ContentPanel
                                  key="content-panel"
                                  id={page.id}
                                  title={page.title}
                                  enableOrdering
                                  displayOrder={page.meta.displayOrder}
                                  siblingPanels={staticContent.edges}
                                  updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateStaticContent)}
                                  deletePanel={() => this.deleteContentPage(deleteStaticContent, page.id)}
                                  onChanges={event => this.onChangeHandle(event, page.id, updateStaticContent)}>
                                  <ErrorBoundary>
                                    <WysiwygEditor
                                      enableArticleLinking
                                      enableExternalLinking
                                      enableImageUpload
                                      enableVimeoEmbed
                                      languageCode={languageCode}
                                      app={app}
                                      published={published}
                                      editorData={page.content}
                                      onChangeValue={event => this.onChangeEditor(event, page.id, updateStaticContent)}
                                    />
                                  </ErrorBoundary>
                                  <div className="content-panel__footer">
                                    <form className="radio-group radio-group--eye">
                                      <label className={cx('radio-group__label', {
                                        'radio-group__label--selected': page.isPrivacyPolicy
                                      })}>
                                        <input
                                          type="checkbox"
                                          className="radio-group__input"
                                          onChange={() => this.onPrivacyPolicyChange(page.id, page.isPrivacyPolicy, updateStaticContent)}
                                        />
                                        <span className="radio-group__eye-label">
                                          Is this content part of the Privacy Policy page?
                                        </span>
                                      </label>
                                    </form>
                                  </div>
                                </ContentPanel>
                                <Throbber 
                                  hidden={!loading}
                                  loadingText="Deleting page content..."
                                />
                              </div>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    </div>
                  )
              ) : (
                <p className="full-width center-align">No content found.</p>
              ),
              <Mutation
                key="create-step"
                mutation={CREATE_STATIC_CONTENT}
                refetchQueries={[{
                  query: GET_STATIC_CONTENT,
                  variables: {
                    languageCode: languageCode,
                    app: app,
                    limit: 1000
                  }
                }]}
              >
                {(createStaticContent, { loading, error }) => (
                  <div className="full-width right-align relative-block u-margin--top-medium">
                    <Throbber
                      hidden={!loading}
                      loadingText="Creating page content..."
                    />
                    {error &&
                      <p className="full-width center-align">{error.message}</p>
                    }
                    <button
                      className="button button--small button--icon-text"
                      disabled={loading}
                      onClick={() => this.createStaticContent(createStaticContent, staticContent.edges)}>
                      <img src={roundPlus} alt="Add new page content" className="button__icon" />
                      Add new page content
                    </button>
                  </div>
                )}
              </Mutation>
            ]
          }}
        </Query>
      </div>
    );
  }
}

StaticPages.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
}

export default StaticPages;