import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { getErrorMessage } from './../utils';

import {
  RESET_USER_PASSWORD
} from './../gql';

class ResetPassword extends Component {
  constructor(){
    super();
    this.state = {
      password: null,
    };
  }

  handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value
    this.setState({
      [id]: value,
    });
  };

  render() {
    const {
      password
    } = this.state;

    const urlParams = new URLSearchParams(window.location.search);
    const passwordResetToken = urlParams.get('passwordResetToken');

    return (
      <Mutation
        variables={{
          passwordResetToken, 
          newPassword: password }}
        mutation={RESET_USER_PASSWORD}>
        {(resetUserPassword, { loading, error, data }) => {

          if(data){
            return (
              <div className="form">
                <h2>Password has been set</h2>
              </div>
            )
          }

          return (
            <form
              className="form"
              onSubmit={e => {
                e.preventDefault();
                resetUserPassword();
              }}
              noValidate>
              <h2 className="form__title">
                Reset your user password here
              </h2>
              <label className="form__label center-align" htmlFor="password">
                <span className="sr-only">
                  New password
                </span>
                <input
                  className="form__input-field"
                  type="password"
                  id="password"
                  placeholder="New Password"
                  onChange={event => this.handleChange(event)}
                  required
                />
              </label>
              <input
                className="form__submit button full-width"
                type="submit"
                value="Submit"
                disabled={loading}
              />
              {loading &&
                <div className="u-loader__rotate u-loader__rotate--blue">
                  <span className="sr-only">
                    Loading
                  </span>
                </div>
              }
              {error &&
                <p className="center-align">
                  {getErrorMessage(error.message)}
                </p>
              }
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default ResetPassword;