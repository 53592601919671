import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { withApollo } from 'react-apollo';
import { GET_LINKS } from './../../gql';

class Popover extends PureComponent {
  constructor() {
    super();
    this.state = {
      isHidden: true,
      linkTitle: ''
    }
  }

  getLinks = async () => {
    const {
      client,
      app,
      languageCode,
    } = this.props;

    const { data } = await client.query({
      query: GET_LINKS,
      variables: {
        app,
        languageCode,
        limit: 1000
      },
    });
    return data;
  }

  toggleVisibility = () => {
    const { linkId } = this.props;

    this.setState(prevState => ({
      isHidden: !prevState.isHidden
    }));

    if (linkId) {
      const data = this.getLinks();
      data.then(result => {
        const { links } = result;
        const link = links.edges.filter(link => link.contentID === linkId);
        this.setState({
          linkTitle: `${link[0].contentTitle} (${_.lowerCase(link[0].contentTypeNew)})`
        })
      });
    }
  }

  render() {
    const {
      title,
      children
    } = this.props;
    const {
      isHidden,
      linkTitle
    } = this.state;
    const popoverTitle = linkTitle || 'loading...';

    return (
      <div className="popover">
        <div className={cx('popover__wrapper', {
          'popover__wrapper--hidden': isHidden
        })}>
          <p className="popover__title">
            {title
              ? title
              : popoverTitle
            }
          </p>
        </div>
        <div onClick={() => this.toggleVisibility()}>
          {children}
        </div>
      </div>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string,
  linkId: PropTypes.string,
  client: PropTypes.object,
  app: PropTypes.string,
  languageCode: PropTypes.string,
}

export default withApollo(Popover);