import React, { Component } from 'react'
import { getEventRange, getEventTransfer, Editor } from 'slate-react'
import { Block, Value } from 'slate'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import PropTypes from 'prop-types'
import ToolbarButton from './../components/WysiwygEditor/ToolbarButton';
import bulletList from './../../images/icons/bullet-list.svg'

const DEFAULT_NODE = 'paragraph'

const schema = {
  document: {
    last: { type: 'paragraph' },
    normalize: (editor, { code, node }) => {
      switch (code) {
      case 'last_child_type_invalid': {
        const paragraph = Block.create('paragraph')
        return editor.insertNodeByKey(node.key, node.nodes.size, paragraph)
      }
      }
    }
  }
}

function isImage(url) {
  return !!imageExtensions.find(url.endsWith)
}

function insertImage(editor, src, target, id) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src, id }
  })
}

function wrapLink(editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href }
  })

  editor.moveToEnd()
}

function unwrapLink(editor) {
  editor.unwrapInline('link')
}

function unwrapInternalLink(editor) {
  editor.unwrapInline('internalLink')
}

class WysiwygEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.getChecklistValues()
    }
  }

  getChecklistValues = () => {
    const { editorData } = this.props;
    if (editorData.length > 0) {
      return Value.fromJSON({
        document: {
          nodes: [
            {
              'object': 'block',
              'type': 'bulleted-list',
              'nodes': this.props.editorData.map(listItem => {
                return {
                  'object': 'block',
                  'type': 'list-item',
                  'nodes': [
                    {
                      'object': 'text',
                      'leaves': [
                        {
                          'object': 'leaf',
                          'text': listItem,
                        }
                      ]
                    }
                  ]
                }
              })
            },
          ],
        },
      })
    }

    return Value.fromJSON({
      document: {
        nodes: [
          {
            object: 'block',
            type: 'paragraph',
          },
        ],
      },
    })
  } 

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state;
    const { onChangeValue } = this.props;

    if (prevState.value.document !== value.document) {
      onChangeValue(value.toJSON())
    }
  }

  hasLinks = () => {
    const { value } = this.state
    return value.inlines.some(inline => inline.type == 'link')
  }

  hasInternalLinks = () => {
    const { value } = this.state
    return value.inlines.some(inline => inline.type == 'internalLink')
  }

  hasMark = type => {
    const { value } = this.state
    return value.activeMarks.some(mark => mark.type === type)
  }

  hasBlock = type => {
    const { value } = this.state
    return value.blocks.some(node => node.type === type)
  }

  isMarkActive = type => {
    let isActive = false
    const { value } = this.state
    if (value) {
      isActive = this.hasMark(type)
    }
    return isActive
  }

  isBlockActive = type => {
    let isActive = false
    const { value } = this.state
    if (value) {
      if (['numbered-list', 'bulleted-list'].includes(type)) {
        const { document, blocks } = value
        if (blocks.size > 0) {
          const parent = document.getParent(blocks.first().key)
          isActive =
            this.hasBlock('list-item') && parent && parent.type === type
        }
      }
    }
    return isActive
  }

  ref = editor => {
    this.editor = editor
  }

  onClickMark = (event, type) => {
    this.editor.toggleMark(type)
    event.preventDefault()
  }

  onClickBlock = (event, type) => {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const { document } = value

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list-item')
      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
          )
          .wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props

    switch (mark.type) {
    case 'bold':
      return <strong {...attributes}>{children}</strong>
    case 'italic':
      return <i {...attributes}>{children}</i>
    default:
      return next()
    }
  }

  renderNode = (props, editor, next) => {
    const { attributes, node, children } = props

    switch (node.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    default:
      return next()
    }
  }

  onKeyDown = (event, editor, next) => {
    if (!event.ctrlKey) return next()

    switch (event.key) {
    case 'b': {
      event.preventDefault()
      this.editor.toggleMark('bold')
      break
    }
    case 'i': {
      event.preventDefault()
      this.editor.toggleMark('italic')
      break
    }
    default: {
      next()
    }
    }
  }

  onDropOrPaste = (event, editor, next) => {
    const target = getEventRange(event, editor)
    if (!target && event.type === 'drop') return next()

    const transfer = getEventTransfer(event)
    const { type, text, files } = transfer

    if (type === 'files') {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')
        if (mime !== 'image') continue

        reader.addEventListener('load', () => {
          editor.command(insertImage, reader.result, target)
        })

        reader.readAsDataURL(file)
      }
      return
    }

    if (type === 'text') {
      if (!isUrl(text)) return next()
      if (!isImage(text)) return next()
      editor.command(insertImage, text, target)
      return
    }

    if (editor.value.selection.isCollapsed) return next()

    if (type != 'text' && type != 'html') return next()

    if (this.hasLinks()) {
      editor.command(unwrapLink)
    }

    if (this.hasInternalLinks()) {
      editor.command(unwrapInternalLink)
    }

    next()
  }

  onEditorChange = ({ value }) => {
    this.setState({
      value
    });
  }

  onPaste = (event, editor, next) => {
    if (editor.value.selection.isCollapsed) return next()

    const transfer = getEventTransfer(event)
    const { type, text } = transfer
    if (type != 'text' && type != 'html') return next()
    if (!isUrl(text)) return next()

    if (this.hasLinks()) {
      editor.command(unwrapLink)
    }

    if (this.hasInternalLinks()) {
      editor.command(unwrapInternalLink)
    }

    editor.command(wrapLink, text)
  }

  render() {
    const { value } = this.state

    return (
      <div className='wysiwyg-editor wysiwyg-editor--checklist'>
        <div className='wysiwyg-editor__toolbar'>
          <ToolbarButton
            type='bulleted-list'
            icon={bulletList}
            onClick={this.onClickBlock}
            isActive={this.isBlockActive}
          />
        </div>
        <div className='wysiwyg-editor__wrapper'>
          <Editor
            placeholder='Insert checklist items'
            ref={this.ref}
            value={value}
            defaultValue={value}
            onChange={this.onEditorChange}
            onKeyDown={this.onKeyDown}
            schema={schema}
            onDrop={this.onDropOrPaste}
            onPaste={this.onDropOrPaste}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
          />
        </div>
      </div>
    )
  }
}

WysiwygEditor.propTypes = {
  onChangeValue: PropTypes.func,
  editorData: PropTypes.any.isRequired,
}

export default WysiwygEditor
