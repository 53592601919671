export default {
  Mutation: {},
};

const defaults = {
  authToken: localStorage.getItem('auth_token'),
  currentLanguageCode: localStorage.getItem('language_code_selected'),
  currentApp: localStorage.getItem('app_selected'),
  currentContentType: null,
  publishDisabled: true,
  queryRefetchAfterPublish: null,
  queryRefetchContentType: null,
  unpublishWarning: false
};

const resolvers = {}

export { defaults, resolvers };