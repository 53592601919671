import gql from 'graphql-tag';

const GET_DOCTOR_TIPS = gql`
  query DoctorTips(
    $languageCode: String!
    $app: App!
    $published: Boolean
    $orderBy: OrderBy
    $cursor: String
    $limit: Int
  ) {
    doctorTips(
      filter: {
        languageCode: $languageCode
        published: $published
        app: $app
      }
      pagination: {
        orderBy: $orderBy
        cursor: $cursor
        limit: $limit
      }
    ) {
      edges {
        id
        title
        subtitle
        subsections {
          id
          title
          subtitle
          content
          doctorTip {
            id
            title
            subtitle
          }
          availability {
            published
            fromWeek
            toWeek
            hiddenFromDashboard
            shownRandomly
            isBorn
          }
          meta {
            displayOrder
          }
        }
        availability {
          published
          fromWeek
          toWeek
          hiddenFromDashboard
          shownRandomly
          isBorn
        }
        meta {
          displayOrder
        }
      }
    }
  }
`;

const CREATE_DOCTOR_TIP = gql`
  mutation CreateDoctorTip(
    $languageCode: String!
    $app: App!
    $title: String!
    $displayOrder: Int
  ) {
    createDoctorTip(
      title: $title
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
      }
    ) {
      id
    }
  }
`;

const DELETE_DOCTOR_TIP = gql`
  mutation DeleteDoctorTip(
    $id: ID!
  ) {
    deleteDoctorTip(
      id: $id
    )
  }
`;

const UPDATE_DOCTOR_TIP = gql`
  mutation UpdateDoctorTip(
    $id: ID!
    $title: String
    $subtitle: String
    $languageCode: String!
    $app: App!
    $published: Boolean
    $fromWeek: Int
    $toWeek: Int
    $displayOrder: Int
    $isBorn: Boolean
  ) {
    updateDoctorTip(
      id: $id
      title: $title
      subtitle: $subtitle
      displayOrder: $displayOrder
      availability: {
        languageCode: $languageCode
        app: $app
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        isBorn: $isBorn
      }
    ) {
      id
    }
  }
`;

const CREATE_DOCTOR_TIP_SUBSECTION = gql`
  mutation CreateDoctorTipSubsection(
    $title: String!
    $doctorTipId: ID!
    $app: App!
    $languageCode: String!
    $content: JSON
    $displayOrder: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
  ) {
    createDoctorTipSubsection(
      title: $title
      doctorTipId: $doctorTipId
      content: $content
      displayOrder: $displayOrder
      availability: {
        app: $app
        languageCode: $languageCode
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
      }
    ) {
      id
    }
  }
`;

const DELETE_DOCTOR_TIP_SUBSECTION = gql`
  mutation DeleteDoctorTipSubsection(
    $id: ID!
  ) {
    deleteDoctorTipSubsection(
      id: $id
    )
  }
`;

const UPDATE_DOCTOR_TIP_SUBSECTION  = gql`
  mutation UpdateDoctorTipSubsection(
    $id: ID!
    $title: String
    $subtitle: String
    $content: JSON
    $app: App!
    $languageCode: String!
    $published: Boolean
    $displayOrder: Int
    $fromWeek: Int
    $toWeek: Int
    $hiddenFromDashboard: Boolean
    $shownRandomly: Boolean
    $isBorn: Boolean
  ) {
    updateDoctorTipSubsection(
      id: $id
      title: $title
      subtitle: $subtitle
      content: $content
      availability: {
        app: $app
        languageCode: $languageCode
        published: $published
        fromWeek: $fromWeek
        toWeek: $toWeek
        hiddenFromDashboard: $hiddenFromDashboard
        shownRandomly: $shownRandomly
        isBorn: $isBorn
      }
      displayOrder: $displayOrder
    ) {
      id
    }
  } 
`;

export {
  GET_DOCTOR_TIPS,
  CREATE_DOCTOR_TIP,
  DELETE_DOCTOR_TIP,
  UPDATE_DOCTOR_TIP,
  CREATE_DOCTOR_TIP_SUBSECTION,
  DELETE_DOCTOR_TIP_SUBSECTION,
  UPDATE_DOCTOR_TIP_SUBSECTION
};