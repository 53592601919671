import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Sequenzer from './Sequenzer';

import arrowDown from './../../images/icons/arrow-down-white.svg';
import arrowUp from './../../images/icons/arrow-up-white.svg';
import ImageChooser from './ImageChooser';
import Overlay from './Overlay';

const parseIcon = (icon) => {
  if (typeof icon === 'string') return icon;
  return `data:${icon.mimetype};base64, ${icon.base64}`;
}

class Accordion extends PureComponent {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      imagesOverlayOpen: false
    };
  }

  toggleAccordion() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  toggleImagesOverlay = () => {
    this.setState(prevState => ({
      imagesOverlayOpen: !prevState.imagesOverlayOpen,
    }));
  }


  addIcon = (imagePath, id) => {
    const { onChanges } = this.props;
    const iconEvent = {
      target: {
        name: 'icon',
        value: id
      },
      preventDefault: () => { }
    };
    onChanges(iconEvent);
    setTimeout(() => this.toggleImagesOverlay(), 1);
  }

  switchOrder = direction => {
    const {
      id,
      displayOrder,
      siblingAccordions,
      updateDisplayOrder
    } = this.props;

    siblingAccordions.map((targetAccordion, index) => {
      if (targetAccordion.id === id) {
        const neighbourAccordionNewOrder = displayOrder;
        const targetAccordionNewOrder = this.getNewDisplayOrder(direction, index);
        const neighbourAccordionId = this.getNeighbourAccordionId(direction, index);

        updateDisplayOrder(neighbourAccordionId, neighbourAccordionNewOrder);
        updateDisplayOrder(targetAccordion.id, targetAccordionNewOrder);
      }
    });
  }

  getNeighbourAccordionId = (direction, index) => {
    const { siblingAccordions } = this.props;

    if (direction === 'up') {
      return siblingAccordions[index - 1].id;
    }
    return siblingAccordions[index + 1].id;
  }

  getNewDisplayOrder = (direction, index) => {
    const {
      siblingAccordions,
      displayOrder
    } = this.props;
    const newOrderNumber = direction === 'up'
      ? siblingAccordions[index - 1].meta.displayOrder
      : siblingAccordions[index + 1].meta.displayOrder;
    const orderDifference = Math.abs(displayOrder - newOrderNumber);

    if (orderDifference === 1) {
      return newOrderNumber;
    }
    return direction === 'up'
      ? displayOrder - 1
      : displayOrder + 1;
  }

  getIndex = range => {
    const { siblingAccordions } = this.props;
    const sortedAccordions = siblingAccordions.sort((a, b) => a.meta.displayOrder - b.meta.displayOrder);

    if (range === 'highest') {
      return sortedAccordions[sortedAccordions.length - 1].meta.displayOrder;
    }
    return sortedAccordions[0].meta.displayOrder;
  }

  render() {
    const { isOpen, imagesOverlayOpen } = this.state;
    const {
      children,
      secondLevel,
      headerIcon,
      deleteAccordion,
      twoHeaderInputs,
      showColorPicker,
      title,
      subtitle,
      titleColor,
      id,
      onChanges,
      enableOrdering,
      enableWeekRange,
      rangeFrom,
      rangeTo,
      enableChangeIcon,
      displayOrder,
      onIsBornChange,
      isBorn,
    } = this.props;
    const toggleArrow = isOpen
      ? arrowUp
      : arrowDown;
    const toggleTitle = isOpen
      ? 'Collapse'
      : 'Expand';

    return (
      <section className={cx('accordion', {
        'accordion--second-level': secondLevel
      })}>
        <header className="accordion__header">
          {onIsBornChange &&
            <div className="right-align">
              <form className="radio-group radio-group--eye radio-group--block">
                <label className={cx('radio-group__label no-paddingtop', {
                  'radio-group__label--selected': isBorn
                })}>
                  <input
                    type="checkbox"
                    className="radio-group__input"
                    onChange={() => onIsBornChange(id, isBorn)}
                  />
                  <span className="radio-group__eye-label">
                    Show after baby is born
                  </span>
                </label>
              </form>
            </div>
          }
          <div className="accordion__header-wrapper">
            <div className="accordion__input-wrapper">
              {headerIcon &&
                <div
                  onClick={enableChangeIcon && this.toggleImagesOverlay}
                  className={cx('accordion__icon-wrapper', {
                    'accordion__icon-wrapper--input': enableChangeIcon
                  })}>
                  <img src={parseIcon(headerIcon)} alt="Bookmark" className="accordion__icon" />
                </div>
              }
              <div className="full-width">
                <label htmlFor="title" className="form__label">
                  <input
                    type="text"
                    name="title"
                    placeholder="Title level 1"
                    onChange={event => onChanges(event)}
                    defaultValue={title}
                    className={cx('form__input-field', 'form__input-field--small', {
                      'accordion__sibling-input': twoHeaderInputs
                    })}
                  />
                </label>
                {twoHeaderInputs &&
                  <label htmlFor="subtitle" className="form__label">
                    <input
                      type="text"
                      name="subtitle"
                      placeholder="Title level 2"
                      onChange={event => onChanges(event)}
                      defaultValue={subtitle}
                      className={cx('form__input-field', 'form__input-field--small', {
                        'accordion__sibling-input accordion__sibling-input--indent': twoHeaderInputs
                      })}
                    />
                  </label>
                }
                {showColorPicker &&
                  <div className={cx('accordion__color-picker-wrapper', {
                    'accordion__sibling-input accordion__sibling-input--indent-twice': showColorPicker
                  })}>

                    <label htmlFor="color" className="form__label">
                      <input
                        type="text"
                        name="color"
                        placeholder="Title color"
                        onChange={event => onChanges(event)}
                        defaultValue={titleColor}
                        className='form__input-field form__input-field--small'
                      />
                    </label>
                    <div className="accordion__color-picker__example" style={{ backgroundColor: titleColor }}></div>
                  </div>
                }
              </div>
            </div>
            <div className="accordion__actions">
              {enableWeekRange &&
                <form className="accordion__time-range">
                  <label className="form__label accordion__label" htmlFor="fromWeek">
                    <span className="caption">
                      Show in week
                    </span>
                    <input
                      className="form__input-field form__input-field--small accordion__range-input"
                      type="number"
                      name="fromWeek"
                      defaultValue={rangeFrom}
                      onChange={event => onChanges(event)}
                    />
                  </label>
                  <label className="form__label accordion__label" htmlFor="toWeek">
                    <span className="caption">
                      to
                    </span>
                    <input
                      className="form__input-field form__input-field--small accordion__range-input"
                      type="number"
                      name="toWeek"
                      defaultValue={rangeTo}
                      onChange={event => onChanges(event)}
                    />
                  </label>
                </form>
              }
              {deleteAccordion &&
                <button className="button button--transparent button--smallest button--delete" onClick={() => deleteAccordion(id)}>
                  Delete
                </button>
              }
              <button className="button button--round-icon" onClick={() => this.toggleAccordion()}>
                <img src={toggleArrow} alt={toggleTitle} className="button__icon-small" title={toggleTitle} />
              </button>
              {enableOrdering &&
                <Sequenzer
                  onMoveDown={() => this.switchOrder('down')}
                  onMoveUp={() => this.switchOrder('up')}
                  index={displayOrder}
                  lowestIndex={this.getIndex('lowest')}
                  highestIndex={this.getIndex('highest')}
                />
              }
            </div>
          </div>
        </header>
        <div className={cx('accordion__body', {
          'accordion__body--collapsed': !isOpen
        })}>
          {children}
        </div>
        {imagesOverlayOpen &&
          <Overlay closeOverlay={this.toggleImagesOverlay}>
            <div className="overlay__dialog-wrapper">
              <ImageChooser
                onClickImage={this.addIcon}
              />
            </div>
          </Overlay>
        }
      </section>
    );
  }
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  headerIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  secondLevel: PropTypes.bool,
  deleteAccordion: PropTypes.func,
  twoHeaderInputs: PropTypes.bool,
  showColorPicker: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChanges: PropTypes.func,
  enableOrdering: PropTypes.bool,
  displayOrder: PropTypes.number,
  siblingAccordions: PropTypes.array,
  updateDisplayOrder: PropTypes.func,
  enableWeekRange: PropTypes.bool,
  rangeFrom: PropTypes.number,
  rangeTo: PropTypes.number,
  enableChangeIcon: PropTypes.bool,
  onIsBornChange: PropTypes.func,
  isBorn: PropTypes.bool,
}

export default Accordion;