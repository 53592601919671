import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  GET_VIDEO_TUTORIALS,
  DELETE_VIDEO_TUTORIAL,
  UPDATE_VIDEO_TUTORIAL,
  CREATE_VIDEO_TUTORIAL,
  CREATE_VIDEO_SUBSECTION,
  DELETE_VIDEO_SUBSECTION,
  UPDATE_VIDEO_SUBSECTION,
  GET_CONTENT_PAGE,
  UPDATE_CONTENT_PAGE,
  CREATE_CONTENT_PAGE
} from './../gql';

import Accordion from './../components/Accordion';
import Throbber from './../components/Throbber';
import ContentPanel from './../components/ContentPanel';
import WysiwygEditor from './../components/WysiwygEditor';
import VisibilityToggle from './../components/VisibilityToggle';
import ErrorBoundary from './../components/ErrorBoundary';

import bridgeIcon from './../../images/icons/bridge.svg';
import roundPlus from './../../images/icons/round-plus-white.svg';

class VideoTutorials extends Component {
  constructor() {
    super();
    this.state = {
      tutorialUpdate: {},
      contenPageUpdate: {},
      publishIsUpToDate: null
    }
    this.submitDelay = 500;
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: {
        currentContentType: 'VIDEO_TUTORIAL'
      }
    });
  }

  componentWillUnmount() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: false,
      }
    });
  }

  activateUnpublishedAlert() {
    const { client } = this.props;
    client.writeData({
      data: {
        unpublishWarning: true,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { published } = this.props;

    if (!published && prevProps.published) {
      this.setState({
        publishIsUpToDate: false
      });
    }

    if (published && !prevProps.published) {
      this.setState({
        publishIsUpToDate: true
      });
    }
  }

  initialContentData = data => {
    if (data === null) {
      return {
        document: {
          nodes: [
            {
              object: 'block',
              type: 'paragraph',
            }
          ]
        }
      }
    }
    return data;
  }

  activatePublishButton() {
    const { client } = this.props;
    client.writeData({
      data: {
        publishDisabled: false,
        queryRefetchAfterPublish: ['GET_VIDEO_TUTORIALS', 'GET_CONTENT_PAGE'],
        queryRefetchContentType: 'VIDEO_TUTORIAL',
      }
    });
  }

  createContentPage(createContentPage) {
    const {
      languageCode,
      published,
      app
    } = this.props;

    createContentPage({
      variables: {
        title: '',
        type: 'VIDEO_TUTORIAL',
        languageCode,
        app,
        published
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitContentUpdate(updateContentPage) {
    const { contenPageUpdate } = this.state;
    updateContentPage({
      variables: contenPageUpdate
    });
    this.setState({
      contenPageUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  submitTutorialUpdate(updateFunc) {
    const { tutorialUpdate } = this.state;
    updateFunc({
      variables: tutorialUpdate
    });
    this.setState({
      tutorialUpdate: {}
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onPageInputChanges(event, id, updateContentPage) {
    const {
      languageCode,
      app
    } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    clearTimeout(this.contentTimer);
    this.contentTimer = setTimeout(() => this.submitContentUpdate(updateContentPage), this.submitDelay);

    this.setState(prevState => {
      if (prevState.contenPageUpdate && prevState.contenPageUpdate.id === id) {
        return {
          contenPageUpdate: {
            ...this.state.contenPageUpdate,
            [name]: value
          }
        }
      }
      return {
        contenPageUpdate: {
          [name]: value,
          type: 'VIDEO_TUTORIAL',
          languageCode,
          published: false,
          app,
          id
        }
      }
    });
  }

  onEditorChange(value, id, updateVideoTutorial) {
    const {
      app,
      languageCode
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateVideoTutorial({
        variables: {
          id,
          app,
          published: false,
          languageCode,
          content: value
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }, this.submitDelay);
  }

  onInputChanges(event, id, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;
    const name = event.target.name;
    const value = isNaN(event.target.value)
      ? event.target.value
      : Number(event.target.value);

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => this.submitTutorialUpdate(updateFunc), this.submitDelay);

    this.setState(prevState => {
      if (prevState.tutorialUpdate && prevState.tutorialUpdate.id === id) {
        return {
          tutorialUpdate: {
            ...this.state.tutorialUpdate,
            id,
            [name]: value
          }
        }
      }
      return {
        tutorialUpdate: {
          id,
          app,
          published: false,
          languageCode,
          [name]: value
        }
      }
    });
  }

  createVideoTutorial(createVideoTutorial, videoTutorials) {
    const {
      languageCode,
      app
    } = this.props;

    const orderIndex = videoTutorials.length > 0
      ? videoTutorials[videoTutorials.length - 1].meta.displayOrder + 1
      : 0;

    createVideoTutorial({
      variables: {
        title: '',
        languageCode: languageCode,
        app: app,
        displayOrder: orderIndex
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteVideoTutorial(id, deleteVideoTutorial) {
    if (confirm('Are you sure you want to delete this video tutorial?')) {
      deleteVideoTutorial({
        variables: {
          id
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  createVideoSubsection(createVideoSubsection, subsections, videoTutorialId) {
    const {
      languageCode,
      app
    } = this.props;

    const orderIndex = subsections.length > 0
      ? subsections[subsections.length - 1].meta.displayOrder + 1
      : 0;

    createVideoSubsection({
      variables: {
        languageCode: languageCode,
        app: app,
        content: {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
              },
            ],
          },
        },
        title: '',
        videoTutorialId: videoTutorialId,
        displayOrder: orderIndex,
        hiddenFromDashboard: false,
        shownRandomly: true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  deleteVideoSubsection(deleteVideoSubsection, id) {
    if (confirm('Are you sure you want to delete this video subsection?')) {
      deleteVideoSubsection({
        variables: {
          id
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }
  }

  onChangeEditor(value, id, updateVideoSubsection) {
    const {
      app,
      languageCode
    } = this.props;

    clearTimeout(this.editorTimer);
    this.editorTimer = setTimeout(() => {
      updateVideoSubsection({
        variables: {
          id,
          app,
          languageCode,
          published: false,
          content: value
        }
      });
      this.activatePublishButton();
      this.activateUnpublishedAlert();
    }, this.submitDelay);
  }

  onVisibilityChange(event, id, updateVideoSubsection) {
    const {
      app,
      languageCode
    } = this.props;
    updateVideoSubsection({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        hiddenFromDashboard: event === 'hiddenFromDashboard'
          ? true
          : false,
        shownRandomly: event === 'hiddenFromDashboard'
          ? false
          : true
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  updateDisplayOrder(id, order, updateFunc) {
    const {
      app,
      languageCode
    } = this.props;

    updateFunc({
      variables: {
        id,
        app,
        languageCode,
        published: false,
        displayOrder: order
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  onIsBornChange(id, value, updateFunc) {
    const {
      app,
      languageCode,
    } = this.props;
    const isBorn = value ? false : true;

    updateFunc({
      variables: {
        app,
        languageCode,
        id,
        isBorn,
        published: false,
      }
    });
    this.activatePublishButton();
    this.activateUnpublishedAlert();
  }

  publishNotification() {
    const { publishIsUpToDate } = this.state;

    if (publishIsUpToDate === null) {
      this.activatePublishButton();
    } else if (publishIsUpToDate) {
      return;
    }

    return (
      <div className="notification notification--warning" key="notification">
        <p className="notification__description">
          The content below has not been published yet.
        </p>
      </div>
    );
  }

  render() {
    const {
      app,
      languageCode,
      published
    } = this.props;

    return (
      <Query
        query={GET_VIDEO_TUTORIALS}
        variables={{
          languageCode: languageCode,
          app: app,
          limit: 1000
        }}>
        {({ data, loading, error }) => {
          if (loading)
            return <p className="full-width center-align">Loading...</p>;
          if (error)
            return <p className="full-width center-align">{error.message}</p>;
          const { videoTutorials } = data;

          return (
            <Query
              query={GET_CONTENT_PAGE}
              variables={{
                type: 'VIDEO_TUTORIAL',
                languageCode,
                app
              }}>
              {({ data, loading, error }) => {
                if (loading)
                  return <p className="full-width center-align">Loading...</p>;
                if (error)
                  return <p className="full-width center-align">{error.message}</p>;
                const { contentPage } = data;
                
                return (
                  contentPage !== null ? (
                    <Mutation
                      mutation={UPDATE_CONTENT_PAGE}
                      refetchQueries={[{
                        query: GET_CONTENT_PAGE,
                        variables: {
                          type: 'VIDEO_TUTORIAL',
                          languageCode,
                          app
                        }
                      }]}
                    >
                      {updateContentPage => [
                        !contentPage.availability.published &&
                          this.publishNotification(),
                        <Accordion
                          key="accordion"
                          id={contentPage.id}
                          title={contentPage.title}
                          subtitle={contentPage.subtitle}
                          twoHeaderInputs
                          headerIcon={bridgeIcon}
                          onChanges={event => this.onPageInputChanges(event, contentPage.id, updateContentPage)}>
                          {videoTutorials.edges.length > 0 ? (
                            videoTutorials.edges
                              .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                              .map(videoTutorial =>
                                <Mutation
                                  key={videoTutorial.id}
                                  mutation={DELETE_VIDEO_TUTORIAL}
                                  refetchQueries={[{
                                    query: GET_VIDEO_TUTORIALS,
                                    variables: {
                                      languageCode: languageCode,
                                      app: app,
                                      limit: 1000
                                    }
                                  }]}
                                >
                                  {(deleteVideoTutorial, { loading, error }) => (
                                    <div className="relative-block">
                                      {error &&
                                        <p className="full-width center-align">{error.message}</p>
                                      }
                                      <Mutation
                                        mutation={UPDATE_VIDEO_TUTORIAL}
                                        refetchQueries={[{
                                          query: GET_VIDEO_TUTORIALS,
                                          variables: {
                                            languageCode: languageCode,
                                            app: app,
                                            limit: 1000
                                          }
                                        }]}
                                      >
                                        {updateVideoTutorial => [
                                          !videoTutorial.availability.published &&
                                            this.publishNotification(),
                                          <Accordion
                                            key="accordion"
                                            id={videoTutorial.id}
                                            title={videoTutorial.title}
                                            subtitle={videoTutorial.subtitle}
                                            twoHeaderInputs
                                            enableOrdering
                                            enableWeekRange
                                            isBorn={videoTutorial.availability.isBorn}
                                            rangeFrom={videoTutorial.availability.fromWeek}
                                            rangeTo={videoTutorial.availability.toWeek}
                                            displayOrder={videoTutorial.meta.displayOrder}
                                            siblingAccordions={videoTutorials.edges}
                                            onIsBornChange={(id, value) => this.onIsBornChange(id, value, updateVideoTutorial)}
                                            updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateVideoTutorial)}
                                            deleteAccordion={() => this.deleteVideoTutorial(videoTutorial.id, deleteVideoTutorial)}
                                            onChanges={event => this.onInputChanges(event, videoTutorial.id, updateVideoTutorial)}>
                                            {videoTutorial.subsections
                                              .sort((a, b) => a.meta.displayOrder - b.meta.displayOrder)
                                              .map(subsection =>
                                                <Mutation
                                                  key={subsection.id}
                                                  mutation={DELETE_VIDEO_SUBSECTION}
                                                  refetchQueries={[{
                                                    query: GET_VIDEO_TUTORIALS,
                                                    variables: {
                                                      languageCode: languageCode,
                                                      app: app,
                                                      limit: 1000
                                                    }
                                                  }]}
                                                >
                                                  {(deleteVideoSubsection, { loading, error })  => (
                                                    <div className="relative-block">
                                                      {error &&
                                                        <p className="full-width center-align">{error.message}</p>
                                                      }
                                                      <Mutation
                                                        mutation={UPDATE_VIDEO_SUBSECTION}
                                                        refetchQueries={[{
                                                          query: GET_VIDEO_TUTORIALS,
                                                          variables: {
                                                            languageCode: languageCode,
                                                            app: app,
                                                            limit: 1000
                                                          }
                                                        }]}
                                                      >
                                                        {updateVideoSubsection => [
                                                          !subsection.availability.published &&
                                                            this.publishNotification(),
                                                          <ContentPanel
                                                            key="subsection"
                                                            enableWeekRange
                                                            enableOrdering
                                                            id={subsection.id}
                                                            title={subsection.title}
                                                            rangeFrom={subsection.availability.fromWeek}
                                                            rangeTo={subsection.availability.toWeek}
                                                            isBorn={subsection.availability.isBorn}
                                                            displayOrder={subsection.meta.displayOrder}
                                                            siblingPanels={videoTutorial.subsections}
                                                            onIsBornChange={(id, value) => this.onIsBornChange(id, value, updateVideoSubsection)}
                                                            updateDisplayOrder={(id, order) => this.updateDisplayOrder(id, order, updateVideoSubsection)}
                                                            deletePanel={() => this.deleteVideoSubsection(deleteVideoSubsection, subsection.id)}
                                                            onChanges={event => this.onInputChanges(event, subsection.id, updateVideoSubsection)}>
                                                            <ErrorBoundary>
                                                              <WysiwygEditor
                                                                enableArticleLinking
                                                                enableExternalLinking
                                                                enableImageUpload
                                                                enableVimeoEmbed
                                                                languageCode={languageCode}
                                                                app={app}
                                                                published={published}
                                                                editorData={this.initialContentData(subsection.content)}
                                                                onChangeValue={event => this.onEditorChange(event, subsection.id, updateVideoSubsection)}
                                                              />
                                                            </ErrorBoundary>
                                                            <div className="content-panel__footer">
                                                              <div className="content-panel__actions">
                                                                <VisibilityToggle
                                                                  parentId={subsection.id}
                                                                  onChange={event => this.onVisibilityChange(event, subsection.id, updateVideoSubsection)}
                                                                  options={[
                                                                    {id: 'shownRandomly', checked: subsection.availability.shownRandomly},
                                                                    {id: 'hiddenFromDashboard', checked: subsection.availability.hiddenFromDashboard}
                                                                  ]}
                                                                />
                                                              </div>
                                                            </div>
                                                          </ContentPanel>
                                                        ]}
                                                      </Mutation>
                                                      <Throbber
                                                        hidden={!loading}
                                                        loadingText="Deleting video subsection..."
                                                      />
                                                    </div>
                                                  )}
                                                </Mutation>
                                              )}
                                            <Mutation
                                              mutation={CREATE_VIDEO_SUBSECTION}
                                              refetchQueries={[{
                                                query: GET_VIDEO_TUTORIALS,
                                                variables: {
                                                  languageCode: languageCode,
                                                  app: app,
                                                  limit: 1000
                                                }
                                              }]}>
                                              {(createVideoSubsection, { loading, error }) => (
                                                <div className="full-width right-align relative-block u-margin--top-medium">
                                                  <Throbber
                                                    hidden={!loading}
                                                    loadingText="Creating subsection..."
                                                  />
                                                  {error &&
                                                    <p className="full-width center-align">{error.message}</p>
                                                  }
                                                  <button
                                                    className="button button--small button--icon-text"
                                                    disabled={loading}
                                                    onClick={() => this.createVideoSubsection(createVideoSubsection, videoTutorial.subsections, videoTutorial.id)}>
                                                    <img src={roundPlus} alt="Add new subsection" className="button__icon" />
                                                    Add new subsection
                                                  </button>
                                                </div>
                                              )}
                                            </Mutation>
                                          </Accordion>
                                        ]}
                                      </Mutation>
                                      <Throbber
                                        hidden={!loading}
                                        loadingText="Deleting tutorial..."
                                      />
                                    </div>
                                  )}
                                </Mutation>
                              )
                          ) : (
                            <p className="full-width center-align">No video tutorials found.</p>
                          )}
                          <Mutation
                            mutation={CREATE_VIDEO_TUTORIAL}
                            refetchQueries={[{
                              query: GET_VIDEO_TUTORIALS,
                              variables: {
                                languageCode: languageCode,
                                app: app,
                                limit: 1000
                              }
                            }]}>
                            {(createVideoTutorial, { loading, error }) => (
                              <div className="full-width right-align relative-block u-margin--top-medium">
                                <Throbber
                                  hidden={!loading}
                                  loadingText="Creating tutorial..."
                                />
                                {error &&
                                  <p className="full-width center-align">{error.message}</p>
                                }
                                <button
                                  className="button button--small button--icon-text"
                                  disabled={loading}
                                  onClick={() => this.createVideoTutorial(createVideoTutorial, videoTutorials.edges)}>
                                  <img src={roundPlus} alt="Add new tutorial" className="button__icon" />
                                  Add new tutorial
                                </button>
                              </div>
                            )}
                          </Mutation>
                        </Accordion>
                      ]}
                    </Mutation>
                  ) : (
                    <div>
                      <p className="full-width center-align">
                        No content found for this page
                      </p>
                      <Mutation
                        mutation={CREATE_CONTENT_PAGE}
                        refetchQueries={[{
                          query: GET_CONTENT_PAGE,
                          variables: {
                            type: 'VIDEO_TUTORIAL',
                            languageCode,
                            app,
                            limit: 1000
                          }
                        }]}>
                        {(createContentPage, { loading, error }) =>
                          <div className="relative-block">
                            {error &&
                              <p className="full-width center-align">{error.message}</p>
                            }
                            <div className="right-align">
                              <button
                                className="button button--small button--icon-text"
                                disabled={loading}
                                onClick={() => this.createContentPage(createContentPage)}>
                                <img src={roundPlus} alt="Add" className="button__icon" />
                                Add new content page
                              </button>
                            </div>
                            <Throbber
                              hidden={!loading}
                              loadingText="Creating content page..."
                            />
                          </div>
                        }
                      </Mutation>
                    </div>
                  )
                )  
              }}
            </Query>
          )
        }}
      </Query>
    );
  }
}

VideoTutorials.propTypes = {
  languageCode: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  app: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
};

export default VideoTutorials;