import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

const Dropdown = ({
  options,
  currentSelection,
  selectionChange,
  noVMargin,
  noneSelection,
  links,
  optionStartCase
}) => (
  <select
    className={cx('dropdown', {
      'no-vmargin': noVMargin
    })}
    defaultValue={currentSelection}
    onChange={event => selectionChange(event)}>
    {noneSelection &&
      <option value="">
        {noneSelection}
      </option>
    }
    {links ? (
      links
        .sort((a, b) => {
          if (a.contentTitle < b.contentTitle) return -1;
          if (a.contentTitle > b.contentTitle) return 1;
          return 0;
        })
        .map(link => {
          const trimOption = _.lowerCase(link.contentTitle);
          const startCaseOption = _.startCase(trimOption);
          return (
            <option value={link.contentID} key={link.contentID}>
              {optionStartCase
                ? startCaseOption
                : link.contentTitle
              }
            </option>
          );
        })
    ) : (
      options.map((option, index) => {
        const trimOption = _.lowerCase(option);
        let startCaseOption = _.startCase(trimOption);

        // Hospital rename
        if(trimOption === 'hospital'){
          startCaseOption = 'Jordemoder'
        }

        return (
          <option value={option} key={index}>
            {optionStartCase
              ? startCaseOption
              : option
            }
          </option>
        );
      })
    )}
  </select>
);

Dropdown.propTypes = {
  links: PropTypes.array,
  options: PropTypes.array,
  currentSelection: PropTypes.string,
  selectionChange: PropTypes.func.isRequired,
  noVMargin: PropTypes.bool,
  noneSelection: PropTypes.string,
  optionStartCase: PropTypes.bool
}

export default Dropdown;