import React, { Component } from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';
import { Query } from 'react-apollo';

import {
  GET_SUPPORTED_LANGUAGES,
  GET_SETTINGS
} from './gql';

import RadioGroup from './components/RadioGroup';
import Sidebar from './views/Sidebar';
import OnBoarding from './views/OnBoarding';
import Encyclopaedia from './views/Encyclopaedia/';
import Checklists from './views/Checklists';
import VideoTutorials from './views/VideoTutorials';
import MyAppointments from './views/MyAppointments';
import Notifications from './views/Notifications';
import StaticPages from './views/StaticPages';
import Users from './views/Users';
import AddLanguage from './views/AddLanguage';
import DoctorTips from './views/DoctorTips';

class App extends Component {
  constructor() {
    super();
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  onLanguageChange(event, client) {
    const value = event.currentTarget.value;
    localStorage.setItem('language_code_selected', value);
    client.writeData({
      data: {
        currentLanguageCode: value
      }
    });
  }

  render() {

    return (
      <BrowserRouter>
        <Query query={GET_SUPPORTED_LANGUAGES}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading... </p>;
            if (error) return <p>{error.message}</p>;
            const { availableLanguages } = data;

            return (
              <Query query={GET_SETTINGS}>
                {({ data, client }) => {
                  const {
                    currentLanguageCode,
                    currentApp,
                    publishDisabled
                  } = data;
                  const language = currentLanguageCode
                    ? currentLanguageCode
                    : availableLanguages[0];
                  const app = currentApp
                    ? currentApp
                    : 'GRAVID';

                  return (
                    <div className="deck">
                      <div className="deck__sidebar">
                        <Sidebar
                          predefinedAppSelection={app}
                          languageCode={language}
                          published={false}
                        />
                      </div>
                      <div className="deck__dashboard">
                        <div className="relative-block">
                          <div className="deck__flex">
                            <RadioGroup
                              options={availableLanguages}
                              currentSelection={language}
                              selectionChange={event => this.onLanguageChange(event, client)}
                            />
                            <AddLanguage />
                          </div>
                        </div>
                        <Switch>
                          <Route path="/onboarding" render={() =>
                            <div className="deck__view-wrapper">
                              <OnBoarding
                                app={app}
                                languageCode={language}
                                published={publishDisabled}
                                client={client}
                              />
                            </div>
                          } />
                          <Route path="/encyclopaedia" render={() =>
                            <Encyclopaedia
                              app={app}
                              languageCode={language}
                              published={publishDisabled}
                              client={client}
                            />
                          } />
                          <Route path="/users" render={() =>
                            <Users
                              app={app}
                              languageCode={language}
                            />
                          } />
                          <Route path="/checklists" render={() =>
                            <div className="u-margin--top-large">
                              <Checklists
                                app={app}
                                languageCode={language}
                                published={publishDisabled}
                                client={client}
                              />
                            </div>
                          } />
                          <Route path="/video-tutorials" render={() =>
                            <div className="u-margin--top-large">
                              <VideoTutorials
                                app={app}
                                languageCode={language}
                                published={publishDisabled}
                                client={client}
                              />
                            </div>
                          } />

                          <Route path="/doctor-tips" render={() =>
                            <div className="u-margin--top-large">
                              <DoctorTips
                                app={app}
                                languageCode={language}
                                published={publishDisabled}
                                client={client}
                              />
                            </div>
                          } />

                          <Route path="/my-appointments" render={() =>
                            <MyAppointments
                              app={app}
                              languageCode={language}
                              published={publishDisabled}
                              client={client}
                            />
                          } />
                          <Route path="/notifications" render={() =>
                            <Notifications
                              app={app}
                              languageCode={language}
                              published={publishDisabled}
                              client={client}
                            />
                          } />
                          <Route path="/static-pages" render={() =>
                            <div className="deck__view-wrapper">
                              <StaticPages
                                app={app}
                                languageCode={language}
                                published={publishDisabled}
                                client={client}
                              />
                            </div>
                          } />
                        </Switch>
                      </div>
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </BrowserRouter>
    );
  }
}

export default App;